import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  DataUserFile: any;
  Date: any;
  ListCourseObject: any;
  Object: any;
  ObjectDeleteUser: any;
  ObjectGetListLessonForUser: any;
  ObjectUpdateUser: any;
  ObjectgetListWebinarForUser: any;
  RenderedObject: any;
  RenderedObjectHotNews: any;
  RenderedObjectPointaverage: any;
  ToggleLikeData: any;
  bpchar: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  registerWebinarOutputObject: any;
  reviewLessonObject: any;
  reviewMagazineObject: any;
  sendEmailForAdminOutputObject: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CreateDocumentInput = {
  document_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CreateDocumentOutput = {
  __typename?: 'CreateDocumentOutput';
  created_at?: Maybe<Scalars['String']>;
  document_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type CreateLessonInput = {
  avg_point?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  index?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  name_file?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  static_video_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  thumbnail_url?: InputMaybe<Scalars['String']>;
  video_url?: InputMaybe<Scalars['String']>;
  views?: InputMaybe<Scalars['Int']>;
};

export type CreateLessonOutput = {
  __typename?: 'CreateLessonOutput';
  avg_point?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_file?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  static_video_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

export type CreateNewsInput = {
  content?: InputMaybe<Scalars['Any']>;
  date?: InputMaybe<Scalars['Date']>;
  excerpt?: InputMaybe<Scalars['Any']>;
  isHot: Scalars['Boolean'];
  link: Scalars['String'];
  status: Scalars['String'];
  thumbnail: Scalars['String'];
  title: Scalars['String'];
  views: Scalars['Int'];
};

export type CreateNewsOutput = {
  __typename?: 'CreateNewsOutput';
  content?: Maybe<Scalars['Any']>;
  create_at?: Maybe<Scalars['Date']>;
  date?: Maybe<Scalars['Date']>;
  excerpt?: Maybe<Scalars['Any']>;
  id?: Maybe<Scalars['Int']>;
  isHot?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['Date']>;
  views?: Maybe<Scalars['Int']>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type NewsFeed = {
  __typename?: 'NewsFeed';
  content?: Maybe<Scalars['RenderedObject']>;
  date: Scalars['String'];
  excerpt?: Maybe<Scalars['RenderedObject']>;
  id: Scalars['String'];
  link: Scalars['String'];
  status: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['RenderedObject']>;
};

export type OtpOutput = {
  __typename?: 'OTPOutput';
  message: Scalars['String'];
  valid: Scalars['String'];
};

export type OnboardOutput = {
  __typename?: 'OnboardOutput';
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type OutDeleteUserFile = {
  __typename?: 'OutDeleteUserFile';
  data?: Maybe<Scalars['DataUserFile']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type Output = {
  __typename?: 'Output';
  text: Scalars['String'];
};

export type ProfileSignedUrlOutput = {
  __typename?: 'ProfileSignedURLOutput';
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  url: Scalars['String'];
};

export type RegistrationOutput = {
  __typename?: 'RegistrationOutput';
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SampleOutput = {
  __typename?: 'SampleOutput';
  text: Scalars['String'];
};

export type SearchInput = {
  key: Scalars['String'];
  lessonCategory?: InputMaybe<Scalars['String']>;
  lessonClassify?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchType: Scalars['String'];
};

export type SearchOutput = {
  __typename?: 'SearchOutput';
  data?: Maybe<Scalars['ListCourseObject']>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  _nlike?: InputMaybe<Scalars['String']>;
  _nsimilar?: InputMaybe<Scalars['String']>;
  _similar?: InputMaybe<Scalars['String']>;
};

export type ToggleLikeOutput = {
  __typename?: 'ToggleLikeOutput';
  data?: Maybe<Scalars['ToggleLikeData']>;
  message?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UserInfo = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UserVerification = {
  email: Scalars['String'];
};

export type UserVerificationOutput = {
  __typename?: 'UserVerificationOutput';
  success: Scalars['Boolean'];
};

export type VerifyOtpInput = {
  otp: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type VerifyOtpOutput = {
  __typename?: 'VerifyOTPOutput';
  result: Scalars['String'];
};

export type AddReviewWebnarOutput = {
  __typename?: 'addReviewWebnarOutput';
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type AddTokenInput = {
  token?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type AddTokenOutput = {
  __typename?: 'addTokenOutput';
  error?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

/** columns and relationships of "advertisement" */
export type Advertisement = {
  __typename?: 'advertisement';
  ads_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  index?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "link_advertisement" */
  link_advertisement?: Maybe<Scalars['String']>;
  name_file?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['String']>;
  ratio?: Maybe<Scalars['Int']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "advertisement" */
export type Advertisement_Aggregate = {
  __typename?: 'advertisement_aggregate';
  aggregate?: Maybe<Advertisement_Aggregate_Fields>;
  nodes: Array<Advertisement>;
};

/** aggregate fields of "advertisement" */
export type Advertisement_Aggregate_Fields = {
  __typename?: 'advertisement_aggregate_fields';
  avg?: Maybe<Advertisement_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Advertisement_Max_Fields>;
  min?: Maybe<Advertisement_Min_Fields>;
  stddev?: Maybe<Advertisement_Stddev_Fields>;
  stddev_pop?: Maybe<Advertisement_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Advertisement_Stddev_Samp_Fields>;
  sum?: Maybe<Advertisement_Sum_Fields>;
  var_pop?: Maybe<Advertisement_Var_Pop_Fields>;
  var_samp?: Maybe<Advertisement_Var_Samp_Fields>;
  variance?: Maybe<Advertisement_Variance_Fields>;
};


/** aggregate fields of "advertisement" */
export type Advertisement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Advertisement_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "advertisement" */
export type Advertisement_Aggregate_Order_By = {
  avg?: InputMaybe<Advertisement_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Advertisement_Max_Order_By>;
  min?: InputMaybe<Advertisement_Min_Order_By>;
  stddev?: InputMaybe<Advertisement_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Advertisement_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Advertisement_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Advertisement_Sum_Order_By>;
  var_pop?: InputMaybe<Advertisement_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Advertisement_Var_Samp_Order_By>;
  variance?: InputMaybe<Advertisement_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Advertisement_Avg_Fields = {
  __typename?: 'advertisement_avg_fields';
  id?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "advertisement" */
export type Advertisement_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "advertisement". All fields are combined with a logical 'AND'. */
export type Advertisement_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Advertisement_Bool_Exp>>>;
  _not?: InputMaybe<Advertisement_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Advertisement_Bool_Exp>>>;
  ads_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<Boolean_Comparison_Exp>;
  name_file?: InputMaybe<String_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  percent?: InputMaybe<Int_Comparison_Exp>;
  position?: InputMaybe<String_Comparison_Exp>;
  ratio?: InputMaybe<Int_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Advertisement_Max_Fields = {
  __typename?: 'advertisement_max_fields';
  ads_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name_file?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['String']>;
  ratio?: Maybe<Scalars['Int']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "advertisement" */
export type Advertisement_Max_Order_By = {
  ads_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Advertisement_Min_Fields = {
  __typename?: 'advertisement_min_fields';
  ads_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name_file?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['String']>;
  ratio?: Maybe<Scalars['Int']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "advertisement" */
export type Advertisement_Min_Order_By = {
  ads_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "advertisement" */
export type Advertisement_Order_By = {
  ads_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "advertisement" */
export type Advertisement_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "advertisement" */
export enum Advertisement_Select_Column {
  /** column name */
  AdsUrl = 'ads_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  NameFile = 'name_file',
  /** column name */
  Path = 'path',
  /** column name */
  Percent = 'percent',
  /** column name */
  Position = 'position',
  /** column name */
  Ratio = 'ratio',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Advertisement_Stddev_Fields = {
  __typename?: 'advertisement_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "advertisement" */
export type Advertisement_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Advertisement_Stddev_Pop_Fields = {
  __typename?: 'advertisement_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "advertisement" */
export type Advertisement_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Advertisement_Stddev_Samp_Fields = {
  __typename?: 'advertisement_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "advertisement" */
export type Advertisement_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Advertisement_Sum_Fields = {
  __typename?: 'advertisement_sum_fields';
  id?: Maybe<Scalars['Int']>;
  percent?: Maybe<Scalars['Int']>;
  ratio?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "advertisement" */
export type Advertisement_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Advertisement_Var_Pop_Fields = {
  __typename?: 'advertisement_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "advertisement" */
export type Advertisement_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Advertisement_Var_Samp_Fields = {
  __typename?: 'advertisement_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "advertisement" */
export type Advertisement_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Advertisement_Variance_Fields = {
  __typename?: 'advertisement_variance_fields';
  id?: Maybe<Scalars['Float']>;
  percent?: Maybe<Scalars['Float']>;
  ratio?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "advertisement" */
export type Advertisement_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  percent?: InputMaybe<Order_By>;
  ratio?: InputMaybe<Order_By>;
};

/** columns and relationships of "author" */
export type Author = {
  __typename?: 'author';
  age?: Maybe<Scalars['Int']>;
  biography: Scalars['String'];
  /** An array relationship */
  courses: Array<Course>;
  /** An aggregated array relationship */
  courses_aggregate: Course_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  profile_picture_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "author" */
export type AuthorCoursesArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** columns and relationships of "author" */
export type AuthorCourses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "author". All fields are combined with a logical 'AND'. */
export type Author_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Author_Bool_Exp>>>;
  _not?: InputMaybe<Author_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Author_Bool_Exp>>>;
  age?: InputMaybe<Int_Comparison_Exp>;
  biography?: InputMaybe<String_Comparison_Exp>;
  courses?: InputMaybe<Course_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profile_picture_url?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** ordering options when selecting data from "author" */
export type Author_Order_By = {
  age?: InputMaybe<Order_By>;
  biography?: InputMaybe<Order_By>;
  courses_aggregate?: InputMaybe<Course_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile_picture_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "author" */
export type Author_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "author" */
export enum Author_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  Biography = 'biography',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilePictureUrl = 'profile_picture_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "blog" */
export type Blog = {
  __typename?: 'blog';
  /** An object relationship */
  blog_category?: Maybe<Blog_Category>;
  canonical?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  create_at?: Maybe<Scalars['timestamptz']>;
  date: Scalars['timestamptz'];
  excerpt?: Maybe<Scalars['String']>;
  fake_views?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isHot?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  noindex?: Maybe<Scalars['Boolean']>;
  normalized_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "blog" */
export type Blog_Aggregate = {
  __typename?: 'blog_aggregate';
  aggregate?: Maybe<Blog_Aggregate_Fields>;
  nodes: Array<Blog>;
};

/** aggregate fields of "blog" */
export type Blog_Aggregate_Fields = {
  __typename?: 'blog_aggregate_fields';
  avg?: Maybe<Blog_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Blog_Max_Fields>;
  min?: Maybe<Blog_Min_Fields>;
  stddev?: Maybe<Blog_Stddev_Fields>;
  stddev_pop?: Maybe<Blog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blog_Stddev_Samp_Fields>;
  sum?: Maybe<Blog_Sum_Fields>;
  var_pop?: Maybe<Blog_Var_Pop_Fields>;
  var_samp?: Maybe<Blog_Var_Samp_Fields>;
  variance?: Maybe<Blog_Variance_Fields>;
};


/** aggregate fields of "blog" */
export type Blog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blog" */
export type Blog_Aggregate_Order_By = {
  avg?: InputMaybe<Blog_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Blog_Max_Order_By>;
  min?: InputMaybe<Blog_Min_Order_By>;
  stddev?: InputMaybe<Blog_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Blog_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Blog_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Blog_Sum_Order_By>;
  var_pop?: InputMaybe<Blog_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Blog_Var_Samp_Order_By>;
  variance?: InputMaybe<Blog_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Blog_Avg_Fields = {
  __typename?: 'blog_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "blog" */
export type Blog_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "blog". All fields are combined with a logical 'AND'. */
export type Blog_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Blog_Bool_Exp>>>;
  _not?: InputMaybe<Blog_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Blog_Bool_Exp>>>;
  blog_category?: InputMaybe<Blog_Category_Bool_Exp>;
  canonical?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  create_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  excerpt?: InputMaybe<String_Comparison_Exp>;
  fake_views?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isHot?: InputMaybe<Boolean_Comparison_Exp>;
  is_public?: InputMaybe<Boolean_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  meta_description?: InputMaybe<String_Comparison_Exp>;
  noindex?: InputMaybe<Boolean_Comparison_Exp>;
  normalized_name?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  update_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  views?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "blog_category" */
export type Blog_Category = {
  __typename?: 'blog_category';
  /** An array relationship */
  blogs: Array<Blog>;
  /** An aggregated array relationship */
  blogs_aggregate: Blog_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  meta_description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
};


/** columns and relationships of "blog_category" */
export type Blog_CategoryBlogsArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** columns and relationships of "blog_category" */
export type Blog_CategoryBlogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};

/** aggregated selection of "blog_category" */
export type Blog_Category_Aggregate = {
  __typename?: 'blog_category_aggregate';
  aggregate?: Maybe<Blog_Category_Aggregate_Fields>;
  nodes: Array<Blog_Category>;
};

/** aggregate fields of "blog_category" */
export type Blog_Category_Aggregate_Fields = {
  __typename?: 'blog_category_aggregate_fields';
  avg?: Maybe<Blog_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Blog_Category_Max_Fields>;
  min?: Maybe<Blog_Category_Min_Fields>;
  stddev?: Maybe<Blog_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Blog_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blog_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Blog_Category_Sum_Fields>;
  var_pop?: Maybe<Blog_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Blog_Category_Var_Samp_Fields>;
  variance?: Maybe<Blog_Category_Variance_Fields>;
};


/** aggregate fields of "blog_category" */
export type Blog_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blog_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blog_category" */
export type Blog_Category_Aggregate_Order_By = {
  avg?: InputMaybe<Blog_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Blog_Category_Max_Order_By>;
  min?: InputMaybe<Blog_Category_Min_Order_By>;
  stddev?: InputMaybe<Blog_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Blog_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Blog_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Blog_Category_Sum_Order_By>;
  var_pop?: InputMaybe<Blog_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Blog_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Blog_Category_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Blog_Category_Avg_Fields = {
  __typename?: 'blog_category_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "blog_category" */
export type Blog_Category_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "blog_category". All fields are combined with a logical 'AND'. */
export type Blog_Category_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Blog_Category_Bool_Exp>>>;
  _not?: InputMaybe<Blog_Category_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Blog_Category_Bool_Exp>>>;
  blogs?: InputMaybe<Blog_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  meta_description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Blog_Category_Max_Fields = {
  __typename?: 'blog_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "blog_category" */
export type Blog_Category_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Blog_Category_Min_Fields = {
  __typename?: 'blog_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "blog_category" */
export type Blog_Category_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "blog_category" */
export type Blog_Category_Order_By = {
  blogs_aggregate?: InputMaybe<Blog_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "blog_category" */
export type Blog_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "blog_category" */
export enum Blog_Category_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate stddev on columns */
export type Blog_Category_Stddev_Fields = {
  __typename?: 'blog_category_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "blog_category" */
export type Blog_Category_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Blog_Category_Stddev_Pop_Fields = {
  __typename?: 'blog_category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "blog_category" */
export type Blog_Category_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Blog_Category_Stddev_Samp_Fields = {
  __typename?: 'blog_category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "blog_category" */
export type Blog_Category_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Blog_Category_Sum_Fields = {
  __typename?: 'blog_category_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "blog_category" */
export type Blog_Category_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Blog_Category_Var_Pop_Fields = {
  __typename?: 'blog_category_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "blog_category" */
export type Blog_Category_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Blog_Category_Var_Samp_Fields = {
  __typename?: 'blog_category_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "blog_category" */
export type Blog_Category_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Blog_Category_Variance_Fields = {
  __typename?: 'blog_category_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "blog_category" */
export type Blog_Category_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "blog" */
export enum Blog_Constraint {
  /** unique or primary key constraint */
  BlogPkey = 'blog_pkey'
}

/** input type for incrementing integer column in table "blog" */
export type Blog_Inc_Input = {
  views?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Blog_Max_Fields = {
  __typename?: 'blog_max_fields';
  canonical?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  create_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  excerpt?: Maybe<Scalars['String']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "blog" */
export type Blog_Max_Order_By = {
  canonical?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  create_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  excerpt?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Blog_Min_Fields = {
  __typename?: 'blog_min_fields';
  canonical?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  create_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  excerpt?: Maybe<Scalars['String']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "blog" */
export type Blog_Min_Order_By = {
  canonical?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  create_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  excerpt?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "blog" */
export type Blog_Mutation_Response = {
  __typename?: 'blog_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Blog>;
};

/** on conflict condition type for table "blog" */
export type Blog_On_Conflict = {
  constraint: Blog_Constraint;
  update_columns: Array<Blog_Update_Column>;
  where?: InputMaybe<Blog_Bool_Exp>;
};

/** ordering options when selecting data from "blog" */
export type Blog_Order_By = {
  blog_category?: InputMaybe<Blog_Category_Order_By>;
  canonical?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  create_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  excerpt?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isHot?: InputMaybe<Order_By>;
  is_public?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  noindex?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "blog" */
export type Blog_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "blog" */
export enum Blog_Select_Column {
  /** column name */
  Canonical = 'canonical',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreateAt = 'create_at',
  /** column name */
  Date = 'date',
  /** column name */
  Excerpt = 'excerpt',
  /** column name */
  FakeViews = 'fake_views',
  /** column name */
  Id = 'id',
  /** column name */
  IsHot = 'isHot',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Link = 'link',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  Noindex = 'noindex',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  UpdateAt = 'update_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views'
}

/** input type for updating data in table "blog" */
export type Blog_Set_Input = {
  views?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Blog_Stddev_Fields = {
  __typename?: 'blog_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "blog" */
export type Blog_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Blog_Stddev_Pop_Fields = {
  __typename?: 'blog_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "blog" */
export type Blog_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Blog_Stddev_Samp_Fields = {
  __typename?: 'blog_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "blog" */
export type Blog_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Blog_Sum_Fields = {
  __typename?: 'blog_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "blog" */
export type Blog_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** update columns of table "blog" */
export enum Blog_Update_Column {
  /** column name */
  Views = 'views'
}

/** aggregate var_pop on columns */
export type Blog_Var_Pop_Fields = {
  __typename?: 'blog_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "blog" */
export type Blog_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Blog_Var_Samp_Fields = {
  __typename?: 'blog_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "blog" */
export type Blog_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Blog_Variance_Fields = {
  __typename?: 'blog_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "blog" */
export type Blog_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** expression to compare columns of type bpchar. All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
};

export type CancelAccountOutput = {
  __typename?: 'cancelAccountOutput';
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ChangePasswordOutput = {
  __typename?: 'changePasswordOutput';
  result?: Maybe<Scalars['String']>;
};

/** columns and relationships of "comment_exchange" */
export type Comment_Exchange = {
  __typename?: 'comment_exchange';
  comment_type: Comment_Type_Enum;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  exchange: Exchange;
  exchange_id: Scalars['uuid'];
  hide?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  number_of_changes?: Maybe<Scalars['Int']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  profileByUpdatedBy?: Maybe<Profile>;
  published_at?: Maybe<Scalars['timestamptz']>;
  review_url?: Maybe<Scalars['String']>;
  scores: Scalars['float8'];
  /** An object relationship */
  seeder?: Maybe<Seeder>;
  seeder_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_email_web?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_name_web?: Maybe<Scalars['String']>;
};

/** aggregated selection of "comment_exchange" */
export type Comment_Exchange_Aggregate = {
  __typename?: 'comment_exchange_aggregate';
  aggregate?: Maybe<Comment_Exchange_Aggregate_Fields>;
  nodes: Array<Comment_Exchange>;
};

/** aggregate fields of "comment_exchange" */
export type Comment_Exchange_Aggregate_Fields = {
  __typename?: 'comment_exchange_aggregate_fields';
  avg?: Maybe<Comment_Exchange_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Comment_Exchange_Max_Fields>;
  min?: Maybe<Comment_Exchange_Min_Fields>;
  stddev?: Maybe<Comment_Exchange_Stddev_Fields>;
  stddev_pop?: Maybe<Comment_Exchange_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comment_Exchange_Stddev_Samp_Fields>;
  sum?: Maybe<Comment_Exchange_Sum_Fields>;
  var_pop?: Maybe<Comment_Exchange_Var_Pop_Fields>;
  var_samp?: Maybe<Comment_Exchange_Var_Samp_Fields>;
  variance?: Maybe<Comment_Exchange_Variance_Fields>;
};


/** aggregate fields of "comment_exchange" */
export type Comment_Exchange_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "comment_exchange" */
export type Comment_Exchange_Aggregate_Order_By = {
  avg?: InputMaybe<Comment_Exchange_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Exchange_Max_Order_By>;
  min?: InputMaybe<Comment_Exchange_Min_Order_By>;
  stddev?: InputMaybe<Comment_Exchange_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Comment_Exchange_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Comment_Exchange_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Comment_Exchange_Sum_Order_By>;
  var_pop?: InputMaybe<Comment_Exchange_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Comment_Exchange_Var_Samp_Order_By>;
  variance?: InputMaybe<Comment_Exchange_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "comment_exchange" */
export type Comment_Exchange_Arr_Rel_Insert_Input = {
  data: Array<Comment_Exchange_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Exchange_On_Conflict>;
};

/** aggregate avg on columns */
export type Comment_Exchange_Avg_Fields = {
  __typename?: 'comment_exchange_avg_fields';
  number_of_changes?: Maybe<Scalars['Float']>;
  scores?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "comment_exchange" */
export type Comment_Exchange_Avg_Order_By = {
  number_of_changes?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "comment_exchange". All fields are combined with a logical 'AND'. */
export type Comment_Exchange_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Comment_Exchange_Bool_Exp>>>;
  _not?: InputMaybe<Comment_Exchange_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Comment_Exchange_Bool_Exp>>>;
  comment_type?: InputMaybe<Comment_Type_Enum_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exchange?: InputMaybe<Exchange_Bool_Exp>;
  exchange_id?: InputMaybe<Uuid_Comparison_Exp>;
  hide?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  number_of_changes?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profileByUpdatedBy?: InputMaybe<Profile_Bool_Exp>;
  published_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  review_url?: InputMaybe<String_Comparison_Exp>;
  scores?: InputMaybe<Float8_Comparison_Exp>;
  seeder?: InputMaybe<Seeder_Bool_Exp>;
  seeder_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_email_web?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  user_name_web?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_exchange" */
export enum Comment_Exchange_Constraint {
  /** unique or primary key constraint */
  CommentExchangePkey = 'comment_exchange_pkey',
  /** unique or primary key constraint */
  CommentExchangeUserIdExchangeIdKey = 'comment_exchange_user_id_exchange_id_key'
}

/** input type for incrementing integer column in table "comment_exchange" */
export type Comment_Exchange_Inc_Input = {
  scores?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "comment_exchange" */
export type Comment_Exchange_Insert_Input = {
  comment_type?: InputMaybe<Comment_Type_Enum>;
  content?: InputMaybe<Scalars['String']>;
  exchange_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  scores?: InputMaybe<Scalars['float8']>;
  user_email_web?: InputMaybe<Scalars['String']>;
  user_name_web?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Comment_Exchange_Max_Fields = {
  __typename?: 'comment_exchange_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exchange_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_changes?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  review_url?: Maybe<Scalars['String']>;
  scores?: Maybe<Scalars['float8']>;
  seeder_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_email_web?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_name_web?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "comment_exchange" */
export type Comment_Exchange_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exchange_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_changes?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  review_url?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_email_web?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name_web?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_Exchange_Min_Fields = {
  __typename?: 'comment_exchange_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exchange_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  number_of_changes?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  review_url?: Maybe<Scalars['String']>;
  scores?: Maybe<Scalars['float8']>;
  seeder_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_email_web?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_name_web?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "comment_exchange" */
export type Comment_Exchange_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exchange_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_changes?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  review_url?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_email_web?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name_web?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment_exchange" */
export type Comment_Exchange_Mutation_Response = {
  __typename?: 'comment_exchange_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Comment_Exchange>;
};

/** input type for inserting object relation for remote table "comment_exchange" */
export type Comment_Exchange_Obj_Rel_Insert_Input = {
  data: Comment_Exchange_Insert_Input;
  on_conflict?: InputMaybe<Comment_Exchange_On_Conflict>;
};

/** on conflict condition type for table "comment_exchange" */
export type Comment_Exchange_On_Conflict = {
  constraint: Comment_Exchange_Constraint;
  update_columns: Array<Comment_Exchange_Update_Column>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};

/** ordering options when selecting data from "comment_exchange" */
export type Comment_Exchange_Order_By = {
  comment_type?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exchange?: InputMaybe<Exchange_Order_By>;
  exchange_id?: InputMaybe<Order_By>;
  hide?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_changes?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profileByUpdatedBy?: InputMaybe<Profile_Order_By>;
  published_at?: InputMaybe<Order_By>;
  review_url?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
  seeder?: InputMaybe<Seeder_Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_email_web?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  user_name_web?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "comment_exchange" */
export type Comment_Exchange_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "comment_exchange" */
export enum Comment_Exchange_Select_Column {
  /** column name */
  CommentType = 'comment_type',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExchangeId = 'exchange_id',
  /** column name */
  Hide = 'hide',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfChanges = 'number_of_changes',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  ReviewUrl = 'review_url',
  /** column name */
  Scores = 'scores',
  /** column name */
  SeederId = 'seeder_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserEmailWeb = 'user_email_web',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserNameWeb = 'user_name_web'
}

/** input type for updating data in table "comment_exchange" */
export type Comment_Exchange_Set_Input = {
  content?: InputMaybe<Scalars['String']>;
  scores?: InputMaybe<Scalars['float8']>;
  user_name_web?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Comment_Exchange_Stddev_Fields = {
  __typename?: 'comment_exchange_stddev_fields';
  number_of_changes?: Maybe<Scalars['Float']>;
  scores?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "comment_exchange" */
export type Comment_Exchange_Stddev_Order_By = {
  number_of_changes?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Comment_Exchange_Stddev_Pop_Fields = {
  __typename?: 'comment_exchange_stddev_pop_fields';
  number_of_changes?: Maybe<Scalars['Float']>;
  scores?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "comment_exchange" */
export type Comment_Exchange_Stddev_Pop_Order_By = {
  number_of_changes?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Comment_Exchange_Stddev_Samp_Fields = {
  __typename?: 'comment_exchange_stddev_samp_fields';
  number_of_changes?: Maybe<Scalars['Float']>;
  scores?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "comment_exchange" */
export type Comment_Exchange_Stddev_Samp_Order_By = {
  number_of_changes?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Comment_Exchange_Sum_Fields = {
  __typename?: 'comment_exchange_sum_fields';
  number_of_changes?: Maybe<Scalars['Int']>;
  scores?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "comment_exchange" */
export type Comment_Exchange_Sum_Order_By = {
  number_of_changes?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
};

/** update columns of table "comment_exchange" */
export enum Comment_Exchange_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  Scores = 'scores',
  /** column name */
  UserNameWeb = 'user_name_web'
}

/** aggregate var_pop on columns */
export type Comment_Exchange_Var_Pop_Fields = {
  __typename?: 'comment_exchange_var_pop_fields';
  number_of_changes?: Maybe<Scalars['Float']>;
  scores?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "comment_exchange" */
export type Comment_Exchange_Var_Pop_Order_By = {
  number_of_changes?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Comment_Exchange_Var_Samp_Fields = {
  __typename?: 'comment_exchange_var_samp_fields';
  number_of_changes?: Maybe<Scalars['Float']>;
  scores?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "comment_exchange" */
export type Comment_Exchange_Var_Samp_Order_By = {
  number_of_changes?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Comment_Exchange_Variance_Fields = {
  __typename?: 'comment_exchange_variance_fields';
  number_of_changes?: Maybe<Scalars['Float']>;
  scores?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "comment_exchange" */
export type Comment_Exchange_Variance_Order_By = {
  number_of_changes?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
};

/** columns and relationships of "comment_type" */
export type Comment_Type = {
  __typename?: 'comment_type';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "comment_type". All fields are combined with a logical 'AND'. */
export type Comment_Type_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Comment_Type_Bool_Exp>>>;
  _not?: InputMaybe<Comment_Type_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Comment_Type_Bool_Exp>>>;
  name?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Comment_Type_Enum {
  /** expert comment */
  Expert = 'expert',
  /** user comment */
  User = 'user'
}

/** expression to compare columns of type comment_type_enum. All fields are combined with logical 'AND'. */
export type Comment_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Comment_Type_Enum>;
  _in?: InputMaybe<Array<Comment_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Comment_Type_Enum>;
  _nin?: InputMaybe<Array<Comment_Type_Enum>>;
};

/** ordering options when selecting data from "comment_type" */
export type Comment_Type_Order_By = {
  name?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "comment_type" */
export type Comment_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "comment_type" */
export enum Comment_Type_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for inserting array relation for remote table "contact_form" */
export type Contact_Form_Arr_Rel_Insert_Input = {
  data: Array<Contact_Form_Insert_Input>;
};

/** input type for inserting data into table "contact_form" */
export type Contact_Form_Insert_Input = {
  content?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "contact_form" */
export type Contact_Form_Mutation_Response = {
  __typename?: 'contact_form_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
};

/** input type for inserting object relation for remote table "contact_form" */
export type Contact_Form_Obj_Rel_Insert_Input = {
  data: Contact_Form_Insert_Input;
};

export type CountViewsNewsOutput = {
  __typename?: 'countViewsNewsOutput';
  message?: Maybe<Scalars['String']>;
};

/** columns and relationships of "course" */
export type Course = {
  __typename?: 'course';
  /** An object relationship */
  author?: Maybe<Author>;
  author_id?: Maybe<Scalars['Int']>;
  avg_rating: Scalars['float8'];
  /** An object relationship */
  category?: Maybe<Course_Category>;
  content?: Maybe<Scalars['String']>;
  course_category?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  course_course_categories: Array<Course_Course_Category>;
  /** An aggregated array relationship */
  course_course_categories_aggregate: Course_Course_Category_Aggregate;
  /** An array relationship */
  course_goal_maps: Array<Course_Goal_Map>;
  /** An aggregated array relationship */
  course_goal_maps_aggregate: Course_Goal_Map_Aggregate;
  /** An array relationship */
  course_ratings: Array<Course_Rating>;
  /** An aggregated array relationship */
  course_ratings_aggregate: Course_Rating_Aggregate;
  /** An array relationship */
  courses_lesson: Array<Lesson_Course>;
  /** An aggregated array relationship */
  courses_lesson_aggregate: Lesson_Course_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  document_url?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  index: Scalars['Int'];
  is_feature?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  learning_path?: Maybe<Learning_Path>;
  learning_path_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  learning_processes: Array<Learning_Process>;
  /** An aggregated array relationship */
  learning_processes_aggregate: Learning_Process_Aggregate;
  /** An array relationship */
  lessons: Array<Lesson>;
  /** An aggregated array relationship */
  lessons_aggregate: Lesson_Aggregate;
  /** beginner, intermediate, advanced */
  level?: Maybe<Scalars['String']>;
  /** An object relationship */
  levelCourseByLevelCourse?: Maybe<Level_Course>;
  level_course?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  profileByUpdatedBy?: Maybe<Profile>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "course" */
export type CourseCourse_Course_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Course_Category_Order_By>>;
  where?: InputMaybe<Course_Course_Category_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseCourse_Course_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Course_Category_Order_By>>;
  where?: InputMaybe<Course_Course_Category_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseCourse_Goal_MapsArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Map_Order_By>>;
  where?: InputMaybe<Course_Goal_Map_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseCourse_Goal_Maps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Map_Order_By>>;
  where?: InputMaybe<Course_Goal_Map_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseCourse_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Course_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Rating_Order_By>>;
  where?: InputMaybe<Course_Rating_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseCourse_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Rating_Order_By>>;
  where?: InputMaybe<Course_Rating_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseCourses_LessonArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_Order_By>>;
  where?: InputMaybe<Lesson_Course_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseCourses_Lesson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_Order_By>>;
  where?: InputMaybe<Lesson_Course_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseLearning_ProcessesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Process_Order_By>>;
  where?: InputMaybe<Learning_Process_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseLearning_Processes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Process_Order_By>>;
  where?: InputMaybe<Learning_Process_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseLessonsArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** columns and relationships of "course" */
export type CourseLessons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};

/** aggregated selection of "course" */
export type Course_Aggregate = {
  __typename?: 'course_aggregate';
  aggregate?: Maybe<Course_Aggregate_Fields>;
  nodes: Array<Course>;
};

/** aggregate fields of "course" */
export type Course_Aggregate_Fields = {
  __typename?: 'course_aggregate_fields';
  avg?: Maybe<Course_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Course_Max_Fields>;
  min?: Maybe<Course_Min_Fields>;
  stddev?: Maybe<Course_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Sum_Fields>;
  var_pop?: Maybe<Course_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Var_Samp_Fields>;
  variance?: Maybe<Course_Variance_Fields>;
};


/** aggregate fields of "course" */
export type Course_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Course_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course" */
export type Course_Aggregate_Order_By = {
  avg?: InputMaybe<Course_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Course_Max_Order_By>;
  min?: InputMaybe<Course_Min_Order_By>;
  stddev?: InputMaybe<Course_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Course_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Course_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Course_Sum_Order_By>;
  var_pop?: InputMaybe<Course_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Course_Var_Samp_Order_By>;
  variance?: InputMaybe<Course_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Course_Avg_Fields = {
  __typename?: 'course_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  avg_rating?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course" */
export type Course_Avg_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course". All fields are combined with a logical 'AND'. */
export type Course_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Course_Bool_Exp>>>;
  _not?: InputMaybe<Course_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Course_Bool_Exp>>>;
  author?: InputMaybe<Author_Bool_Exp>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  avg_rating?: InputMaybe<Float8_Comparison_Exp>;
  category?: InputMaybe<Course_Category_Bool_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  course_category?: InputMaybe<Uuid_Comparison_Exp>;
  course_course_categories?: InputMaybe<Course_Course_Category_Bool_Exp>;
  course_goal_maps?: InputMaybe<Course_Goal_Map_Bool_Exp>;
  course_ratings?: InputMaybe<Course_Rating_Bool_Exp>;
  courses_lesson?: InputMaybe<Lesson_Course_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  document_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  is_feature?: InputMaybe<Boolean_Comparison_Exp>;
  learning_path?: InputMaybe<Learning_Path_Bool_Exp>;
  learning_path_id?: InputMaybe<Uuid_Comparison_Exp>;
  learning_processes?: InputMaybe<Learning_Process_Bool_Exp>;
  lessons?: InputMaybe<Lesson_Bool_Exp>;
  level?: InputMaybe<String_Comparison_Exp>;
  levelCourseByLevelCourse?: InputMaybe<Level_Course_Bool_Exp>;
  level_course?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profileByUpdatedBy?: InputMaybe<Profile_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<String_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "course_category" */
export type Course_Category = {
  __typename?: 'course_category';
  /** An array relationship */
  course_course_categories: Array<Course_Course_Category>;
  /** An aggregated array relationship */
  course_course_categories_aggregate: Course_Course_Category_Aggregate;
  /** An array relationship */
  courses: Array<Course>;
  /** An aggregated array relationship */
  courses_aggregate: Course_Aggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  playlist_order?: Maybe<Scalars['Int']>;
  /** An array relationship */
  series_course_categories: Array<Series_Course_Category>;
  series_order?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "course_category" */
export type Course_CategoryCourse_Course_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Course_Category_Order_By>>;
  where?: InputMaybe<Course_Course_Category_Bool_Exp>;
};


/** columns and relationships of "course_category" */
export type Course_CategoryCourse_Course_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Course_Category_Order_By>>;
  where?: InputMaybe<Course_Course_Category_Bool_Exp>;
};


/** columns and relationships of "course_category" */
export type Course_CategoryCoursesArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** columns and relationships of "course_category" */
export type Course_CategoryCourses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** columns and relationships of "course_category" */
export type Course_CategorySeries_Course_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Series_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Course_Category_Order_By>>;
  where?: InputMaybe<Series_Course_Category_Bool_Exp>;
};

/** aggregated selection of "course_category" */
export type Course_Category_Aggregate = {
  __typename?: 'course_category_aggregate';
  aggregate?: Maybe<Course_Category_Aggregate_Fields>;
  nodes: Array<Course_Category>;
};

/** aggregate fields of "course_category" */
export type Course_Category_Aggregate_Fields = {
  __typename?: 'course_category_aggregate_fields';
  avg?: Maybe<Course_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Course_Category_Max_Fields>;
  min?: Maybe<Course_Category_Min_Fields>;
  stddev?: Maybe<Course_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Category_Sum_Fields>;
  var_pop?: Maybe<Course_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Category_Var_Samp_Fields>;
  variance?: Maybe<Course_Category_Variance_Fields>;
};


/** aggregate fields of "course_category" */
export type Course_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Course_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_category" */
export type Course_Category_Aggregate_Order_By = {
  avg?: InputMaybe<Course_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Course_Category_Max_Order_By>;
  min?: InputMaybe<Course_Category_Min_Order_By>;
  stddev?: InputMaybe<Course_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Course_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Course_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Course_Category_Sum_Order_By>;
  var_pop?: InputMaybe<Course_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Course_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Course_Category_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Course_Category_Avg_Fields = {
  __typename?: 'course_category_avg_fields';
  playlist_order?: Maybe<Scalars['Float']>;
  series_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_category" */
export type Course_Category_Avg_Order_By = {
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_category". All fields are combined with a logical 'AND'. */
export type Course_Category_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Course_Category_Bool_Exp>>>;
  _not?: InputMaybe<Course_Category_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Course_Category_Bool_Exp>>>;
  course_course_categories?: InputMaybe<Course_Course_Category_Bool_Exp>;
  courses?: InputMaybe<Course_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  playlist_order?: InputMaybe<Int_Comparison_Exp>;
  series_course_categories?: InputMaybe<Series_Course_Category_Bool_Exp>;
  series_order?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Course_Category_Max_Fields = {
  __typename?: 'course_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  playlist_order?: Maybe<Scalars['Int']>;
  series_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "course_category" */
export type Course_Category_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Category_Min_Fields = {
  __typename?: 'course_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  playlist_order?: Maybe<Scalars['Int']>;
  series_order?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "course_category" */
export type Course_Category_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "course_category" */
export type Course_Category_Order_By = {
  course_course_categories_aggregate?: InputMaybe<Course_Course_Category_Aggregate_Order_By>;
  courses_aggregate?: InputMaybe<Course_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "course_category" */
export type Course_Category_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "course_category" */
export enum Course_Category_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PlaylistOrder = 'playlist_order',
  /** column name */
  SeriesOrder = 'series_order',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Course_Category_Stddev_Fields = {
  __typename?: 'course_category_stddev_fields';
  playlist_order?: Maybe<Scalars['Float']>;
  series_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_category" */
export type Course_Category_Stddev_Order_By = {
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Category_Stddev_Pop_Fields = {
  __typename?: 'course_category_stddev_pop_fields';
  playlist_order?: Maybe<Scalars['Float']>;
  series_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_category" */
export type Course_Category_Stddev_Pop_Order_By = {
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Category_Stddev_Samp_Fields = {
  __typename?: 'course_category_stddev_samp_fields';
  playlist_order?: Maybe<Scalars['Float']>;
  series_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_category" */
export type Course_Category_Stddev_Samp_Order_By = {
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Category_Sum_Fields = {
  __typename?: 'course_category_sum_fields';
  playlist_order?: Maybe<Scalars['Int']>;
  series_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_category" */
export type Course_Category_Sum_Order_By = {
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Course_Category_Var_Pop_Fields = {
  __typename?: 'course_category_var_pop_fields';
  playlist_order?: Maybe<Scalars['Float']>;
  series_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_category" */
export type Course_Category_Var_Pop_Order_By = {
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Category_Var_Samp_Fields = {
  __typename?: 'course_category_var_samp_fields';
  playlist_order?: Maybe<Scalars['Float']>;
  series_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_category" */
export type Course_Category_Var_Samp_Order_By = {
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Category_Variance_Fields = {
  __typename?: 'course_category_variance_fields';
  playlist_order?: Maybe<Scalars['Float']>;
  series_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_category" */
export type Course_Category_Variance_Order_By = {
  playlist_order?: InputMaybe<Order_By>;
  series_order?: InputMaybe<Order_By>;
};

/** columns and relationships of "course_course_category" */
export type Course_Course_Category = {
  __typename?: 'course_course_category';
  /** An object relationship */
  course: Course;
  /** An object relationship */
  course_category: Course_Category;
  course_category_id: Scalars['uuid'];
  course_id: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "course_course_category" */
export type Course_Course_Category_Aggregate = {
  __typename?: 'course_course_category_aggregate';
  aggregate?: Maybe<Course_Course_Category_Aggregate_Fields>;
  nodes: Array<Course_Course_Category>;
};

/** aggregate fields of "course_course_category" */
export type Course_Course_Category_Aggregate_Fields = {
  __typename?: 'course_course_category_aggregate_fields';
  avg?: Maybe<Course_Course_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Course_Course_Category_Max_Fields>;
  min?: Maybe<Course_Course_Category_Min_Fields>;
  stddev?: Maybe<Course_Course_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Course_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Course_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Course_Category_Sum_Fields>;
  var_pop?: Maybe<Course_Course_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Course_Category_Var_Samp_Fields>;
  variance?: Maybe<Course_Course_Category_Variance_Fields>;
};


/** aggregate fields of "course_course_category" */
export type Course_Course_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_course_category" */
export type Course_Course_Category_Aggregate_Order_By = {
  avg?: InputMaybe<Course_Course_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Course_Course_Category_Max_Order_By>;
  min?: InputMaybe<Course_Course_Category_Min_Order_By>;
  stddev?: InputMaybe<Course_Course_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Course_Course_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Course_Course_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Course_Course_Category_Sum_Order_By>;
  var_pop?: InputMaybe<Course_Course_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Course_Course_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Course_Course_Category_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Course_Course_Category_Avg_Fields = {
  __typename?: 'course_course_category_avg_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_course_category" */
export type Course_Course_Category_Avg_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_course_category". All fields are combined with a logical 'AND'. */
export type Course_Course_Category_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Course_Course_Category_Bool_Exp>>>;
  _not?: InputMaybe<Course_Course_Category_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Course_Course_Category_Bool_Exp>>>;
  course?: InputMaybe<Course_Bool_Exp>;
  course_category?: InputMaybe<Course_Category_Bool_Exp>;
  course_category_id?: InputMaybe<Uuid_Comparison_Exp>;
  course_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Course_Course_Category_Max_Fields = {
  __typename?: 'course_course_category_max_fields';
  course_category_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "course_course_category" */
export type Course_Course_Category_Max_Order_By = {
  course_category_id?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Course_Category_Min_Fields = {
  __typename?: 'course_course_category_min_fields';
  course_category_id?: Maybe<Scalars['uuid']>;
  course_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "course_course_category" */
export type Course_Course_Category_Min_Order_By = {
  course_category_id?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "course_course_category" */
export type Course_Course_Category_Order_By = {
  course?: InputMaybe<Course_Order_By>;
  course_category?: InputMaybe<Course_Category_Order_By>;
  course_category_id?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "course_course_category" */
export type Course_Course_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "course_course_category" */
export enum Course_Course_Category_Select_Column {
  /** column name */
  CourseCategoryId = 'course_category_id',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type Course_Course_Category_Stddev_Fields = {
  __typename?: 'course_course_category_stddev_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_course_category" */
export type Course_Course_Category_Stddev_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Course_Category_Stddev_Pop_Fields = {
  __typename?: 'course_course_category_stddev_pop_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_course_category" */
export type Course_Course_Category_Stddev_Pop_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Course_Category_Stddev_Samp_Fields = {
  __typename?: 'course_course_category_stddev_samp_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_course_category" */
export type Course_Course_Category_Stddev_Samp_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Course_Category_Sum_Fields = {
  __typename?: 'course_course_category_sum_fields';
  course_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_course_category" */
export type Course_Course_Category_Sum_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Course_Course_Category_Var_Pop_Fields = {
  __typename?: 'course_course_category_var_pop_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_course_category" */
export type Course_Course_Category_Var_Pop_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Course_Category_Var_Samp_Fields = {
  __typename?: 'course_course_category_var_samp_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_course_category" */
export type Course_Course_Category_Var_Samp_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Course_Category_Variance_Fields = {
  __typename?: 'course_course_category_variance_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_course_category" */
export type Course_Course_Category_Variance_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "course_goal" */
export type Course_Goal = {
  __typename?: 'course_goal';
  /** An array relationship */
  course_goal_maps: Array<Course_Goal_Map>;
  /** An aggregated array relationship */
  course_goal_maps_aggregate: Course_Goal_Map_Aggregate;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "course_goal" */
export type Course_GoalCourse_Goal_MapsArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Map_Order_By>>;
  where?: InputMaybe<Course_Goal_Map_Bool_Exp>;
};


/** columns and relationships of "course_goal" */
export type Course_GoalCourse_Goal_Maps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Map_Order_By>>;
  where?: InputMaybe<Course_Goal_Map_Bool_Exp>;
};

/** aggregated selection of "course_goal" */
export type Course_Goal_Aggregate = {
  __typename?: 'course_goal_aggregate';
  aggregate?: Maybe<Course_Goal_Aggregate_Fields>;
  nodes: Array<Course_Goal>;
};

/** aggregate fields of "course_goal" */
export type Course_Goal_Aggregate_Fields = {
  __typename?: 'course_goal_aggregate_fields';
  avg?: Maybe<Course_Goal_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Course_Goal_Max_Fields>;
  min?: Maybe<Course_Goal_Min_Fields>;
  stddev?: Maybe<Course_Goal_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Goal_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Goal_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Goal_Sum_Fields>;
  var_pop?: Maybe<Course_Goal_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Goal_Var_Samp_Fields>;
  variance?: Maybe<Course_Goal_Variance_Fields>;
};


/** aggregate fields of "course_goal" */
export type Course_Goal_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Course_Goal_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_goal" */
export type Course_Goal_Aggregate_Order_By = {
  avg?: InputMaybe<Course_Goal_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Course_Goal_Max_Order_By>;
  min?: InputMaybe<Course_Goal_Min_Order_By>;
  stddev?: InputMaybe<Course_Goal_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Course_Goal_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Course_Goal_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Course_Goal_Sum_Order_By>;
  var_pop?: InputMaybe<Course_Goal_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Course_Goal_Var_Samp_Order_By>;
  variance?: InputMaybe<Course_Goal_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Course_Goal_Avg_Fields = {
  __typename?: 'course_goal_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_goal" */
export type Course_Goal_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_goal". All fields are combined with a logical 'AND'. */
export type Course_Goal_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Course_Goal_Bool_Exp>>>;
  _not?: InputMaybe<Course_Goal_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Course_Goal_Bool_Exp>>>;
  course_goal_maps?: InputMaybe<Course_Goal_Map_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "course_goal_map" */
export type Course_Goal_Map = {
  __typename?: 'course_goal_map';
  /** An object relationship */
  course: Course;
  /** An object relationship */
  course_goal: Course_Goal;
  course_id: Scalars['Int'];
  goal_id: Scalars['Int'];
  id: Scalars['Int'];
};

/** aggregated selection of "course_goal_map" */
export type Course_Goal_Map_Aggregate = {
  __typename?: 'course_goal_map_aggregate';
  aggregate?: Maybe<Course_Goal_Map_Aggregate_Fields>;
  nodes: Array<Course_Goal_Map>;
};

/** aggregate fields of "course_goal_map" */
export type Course_Goal_Map_Aggregate_Fields = {
  __typename?: 'course_goal_map_aggregate_fields';
  avg?: Maybe<Course_Goal_Map_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Course_Goal_Map_Max_Fields>;
  min?: Maybe<Course_Goal_Map_Min_Fields>;
  stddev?: Maybe<Course_Goal_Map_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Goal_Map_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Goal_Map_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Goal_Map_Sum_Fields>;
  var_pop?: Maybe<Course_Goal_Map_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Goal_Map_Var_Samp_Fields>;
  variance?: Maybe<Course_Goal_Map_Variance_Fields>;
};


/** aggregate fields of "course_goal_map" */
export type Course_Goal_Map_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_goal_map" */
export type Course_Goal_Map_Aggregate_Order_By = {
  avg?: InputMaybe<Course_Goal_Map_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Course_Goal_Map_Max_Order_By>;
  min?: InputMaybe<Course_Goal_Map_Min_Order_By>;
  stddev?: InputMaybe<Course_Goal_Map_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Course_Goal_Map_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Course_Goal_Map_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Course_Goal_Map_Sum_Order_By>;
  var_pop?: InputMaybe<Course_Goal_Map_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Course_Goal_Map_Var_Samp_Order_By>;
  variance?: InputMaybe<Course_Goal_Map_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Course_Goal_Map_Avg_Fields = {
  __typename?: 'course_goal_map_avg_fields';
  course_id?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_goal_map" */
export type Course_Goal_Map_Avg_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_goal_map". All fields are combined with a logical 'AND'. */
export type Course_Goal_Map_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Course_Goal_Map_Bool_Exp>>>;
  _not?: InputMaybe<Course_Goal_Map_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Course_Goal_Map_Bool_Exp>>>;
  course?: InputMaybe<Course_Bool_Exp>;
  course_goal?: InputMaybe<Course_Goal_Bool_Exp>;
  course_id?: InputMaybe<Int_Comparison_Exp>;
  goal_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Course_Goal_Map_Max_Fields = {
  __typename?: 'course_goal_map_max_fields';
  course_id?: Maybe<Scalars['Int']>;
  goal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "course_goal_map" */
export type Course_Goal_Map_Max_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Goal_Map_Min_Fields = {
  __typename?: 'course_goal_map_min_fields';
  course_id?: Maybe<Scalars['Int']>;
  goal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "course_goal_map" */
export type Course_Goal_Map_Min_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "course_goal_map" */
export type Course_Goal_Map_Order_By = {
  course?: InputMaybe<Course_Order_By>;
  course_goal?: InputMaybe<Course_Goal_Order_By>;
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "course_goal_map" */
export type Course_Goal_Map_Pk_Columns_Input = {
  course_id: Scalars['Int'];
  goal_id: Scalars['Int'];
};

/** select columns of table "course_goal_map" */
export enum Course_Goal_Map_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  GoalId = 'goal_id',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type Course_Goal_Map_Stddev_Fields = {
  __typename?: 'course_goal_map_stddev_fields';
  course_id?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_goal_map" */
export type Course_Goal_Map_Stddev_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Goal_Map_Stddev_Pop_Fields = {
  __typename?: 'course_goal_map_stddev_pop_fields';
  course_id?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_goal_map" */
export type Course_Goal_Map_Stddev_Pop_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Goal_Map_Stddev_Samp_Fields = {
  __typename?: 'course_goal_map_stddev_samp_fields';
  course_id?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_goal_map" */
export type Course_Goal_Map_Stddev_Samp_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Goal_Map_Sum_Fields = {
  __typename?: 'course_goal_map_sum_fields';
  course_id?: Maybe<Scalars['Int']>;
  goal_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_goal_map" */
export type Course_Goal_Map_Sum_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Course_Goal_Map_Var_Pop_Fields = {
  __typename?: 'course_goal_map_var_pop_fields';
  course_id?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_goal_map" */
export type Course_Goal_Map_Var_Pop_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Goal_Map_Var_Samp_Fields = {
  __typename?: 'course_goal_map_var_samp_fields';
  course_id?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_goal_map" */
export type Course_Goal_Map_Var_Samp_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Goal_Map_Variance_Fields = {
  __typename?: 'course_goal_map_variance_fields';
  course_id?: Maybe<Scalars['Float']>;
  goal_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_goal_map" */
export type Course_Goal_Map_Variance_Order_By = {
  course_id?: InputMaybe<Order_By>;
  goal_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Course_Goal_Max_Fields = {
  __typename?: 'course_goal_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "course_goal" */
export type Course_Goal_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Goal_Min_Fields = {
  __typename?: 'course_goal_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "course_goal" */
export type Course_Goal_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "course_goal" */
export type Course_Goal_Order_By = {
  course_goal_maps_aggregate?: InputMaybe<Course_Goal_Map_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "course_goal" */
export type Course_Goal_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "course_goal" */
export enum Course_Goal_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Course_Goal_Stddev_Fields = {
  __typename?: 'course_goal_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_goal" */
export type Course_Goal_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Goal_Stddev_Pop_Fields = {
  __typename?: 'course_goal_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_goal" */
export type Course_Goal_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Goal_Stddev_Samp_Fields = {
  __typename?: 'course_goal_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_goal" */
export type Course_Goal_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Goal_Sum_Fields = {
  __typename?: 'course_goal_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_goal" */
export type Course_Goal_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Course_Goal_Var_Pop_Fields = {
  __typename?: 'course_goal_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_goal" */
export type Course_Goal_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Goal_Var_Samp_Fields = {
  __typename?: 'course_goal_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_goal" */
export type Course_Goal_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Goal_Variance_Fields = {
  __typename?: 'course_goal_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_goal" */
export type Course_Goal_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "course_lesson_view" */
export type Course_Lesson_View = {
  __typename?: 'course_lesson_view';
  avg_point?: Maybe<Scalars['float8']>;
  course_id?: Maybe<Scalars['Int']>;
  courseid?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  is_highlight?: Maybe<Scalars['Boolean']>;
  is_highlight_on_category?: Maybe<Scalars['Boolean']>;
  lesson_category_id?: Maybe<Scalars['Int']>;
  lesson_classify_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_file?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['numeric']>;
  static_video_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "course_lesson_view" */
export type Course_Lesson_View_Aggregate = {
  __typename?: 'course_lesson_view_aggregate';
  aggregate?: Maybe<Course_Lesson_View_Aggregate_Fields>;
  nodes: Array<Course_Lesson_View>;
};

/** aggregate fields of "course_lesson_view" */
export type Course_Lesson_View_Aggregate_Fields = {
  __typename?: 'course_lesson_view_aggregate_fields';
  avg?: Maybe<Course_Lesson_View_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Course_Lesson_View_Max_Fields>;
  min?: Maybe<Course_Lesson_View_Min_Fields>;
  stddev?: Maybe<Course_Lesson_View_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Lesson_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Lesson_View_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Lesson_View_Sum_Fields>;
  var_pop?: Maybe<Course_Lesson_View_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Lesson_View_Var_Samp_Fields>;
  variance?: Maybe<Course_Lesson_View_Variance_Fields>;
};


/** aggregate fields of "course_lesson_view" */
export type Course_Lesson_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Course_Lesson_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_lesson_view" */
export type Course_Lesson_View_Aggregate_Order_By = {
  avg?: InputMaybe<Course_Lesson_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Course_Lesson_View_Max_Order_By>;
  min?: InputMaybe<Course_Lesson_View_Min_Order_By>;
  stddev?: InputMaybe<Course_Lesson_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Course_Lesson_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Course_Lesson_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Course_Lesson_View_Sum_Order_By>;
  var_pop?: InputMaybe<Course_Lesson_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Course_Lesson_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Course_Lesson_View_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Course_Lesson_View_Avg_Fields = {
  __typename?: 'course_lesson_view_avg_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  courseid?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Avg_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_lesson_view". All fields are combined with a logical 'AND'. */
export type Course_Lesson_View_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Course_Lesson_View_Bool_Exp>>>;
  _not?: InputMaybe<Course_Lesson_View_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Course_Lesson_View_Bool_Exp>>>;
  avg_point?: InputMaybe<Float8_Comparison_Exp>;
  course_id?: InputMaybe<Int_Comparison_Exp>;
  courseid?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  fake_views?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  is_highlight?: InputMaybe<Boolean_Comparison_Exp>;
  is_highlight_on_category?: InputMaybe<Boolean_Comparison_Exp>;
  lesson_category_id?: InputMaybe<Int_Comparison_Exp>;
  lesson_classify_id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_file?: InputMaybe<String_Comparison_Exp>;
  normalized_name?: InputMaybe<String_Comparison_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  static_video_url?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  video_url?: InputMaybe<String_Comparison_Exp>;
  views?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Course_Lesson_View_Max_Fields = {
  __typename?: 'course_lesson_view_max_fields';
  avg_point?: Maybe<Scalars['float8']>;
  course_id?: Maybe<Scalars['Int']>;
  courseid?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lesson_category_id?: Maybe<Scalars['Int']>;
  lesson_classify_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_file?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['numeric']>;
  static_video_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Max_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  lesson_classify_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  static_video_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Lesson_View_Min_Fields = {
  __typename?: 'course_lesson_view_min_fields';
  avg_point?: Maybe<Scalars['float8']>;
  course_id?: Maybe<Scalars['Int']>;
  courseid?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lesson_category_id?: Maybe<Scalars['Int']>;
  lesson_classify_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_file?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['numeric']>;
  static_video_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Min_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  lesson_classify_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  static_video_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "course_lesson_view" */
export type Course_Lesson_View_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  is_highlight?: InputMaybe<Order_By>;
  is_highlight_on_category?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  lesson_classify_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  static_video_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** select columns of table "course_lesson_view" */
export enum Course_Lesson_View_Select_Column {
  /** column name */
  AvgPoint = 'avg_point',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  Courseid = 'courseid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  FakeViews = 'fake_views',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  IsHighlight = 'is_highlight',
  /** column name */
  IsHighlightOnCategory = 'is_highlight_on_category',
  /** column name */
  LessonCategoryId = 'lesson_category_id',
  /** column name */
  LessonClassifyId = 'lesson_classify_id',
  /** column name */
  Name = 'name',
  /** column name */
  NameFile = 'name_file',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  Rating = 'rating',
  /** column name */
  StaticVideoUrl = 'static_video_url',
  /** column name */
  Status = 'status',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VideoUrl = 'video_url',
  /** column name */
  Views = 'views'
}

/** aggregate stddev on columns */
export type Course_Lesson_View_Stddev_Fields = {
  __typename?: 'course_lesson_view_stddev_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  courseid?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Stddev_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Lesson_View_Stddev_Pop_Fields = {
  __typename?: 'course_lesson_view_stddev_pop_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  courseid?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Stddev_Pop_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Lesson_View_Stddev_Samp_Fields = {
  __typename?: 'course_lesson_view_stddev_samp_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  courseid?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Stddev_Samp_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Lesson_View_Sum_Fields = {
  __typename?: 'course_lesson_view_sum_fields';
  avg_point?: Maybe<Scalars['float8']>;
  course_id?: Maybe<Scalars['Int']>;
  courseid?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lesson_category_id?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['numeric']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Sum_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Course_Lesson_View_Var_Pop_Fields = {
  __typename?: 'course_lesson_view_var_pop_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  courseid?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Var_Pop_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Lesson_View_Var_Samp_Fields = {
  __typename?: 'course_lesson_view_var_samp_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  courseid?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Var_Samp_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Lesson_View_Variance_Fields = {
  __typename?: 'course_lesson_view_variance_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  courseid?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_lesson_view" */
export type Course_Lesson_View_Variance_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  courseid?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Course_Max_Fields = {
  __typename?: 'course_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  avg_rating?: Maybe<Scalars['float8']>;
  content?: Maybe<Scalars['String']>;
  course_category?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  document_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  learning_path_id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  level_course?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course" */
export type Course_Max_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  course_category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  learning_path_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Min_Fields = {
  __typename?: 'course_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  avg_rating?: Maybe<Scalars['float8']>;
  content?: Maybe<Scalars['String']>;
  course_category?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  document_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  learning_path_id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['String']>;
  level_course?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course" */
export type Course_Min_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  course_category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  learning_path_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "course" */
export type Course_Order_By = {
  author?: InputMaybe<Author_Order_By>;
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  category?: InputMaybe<Course_Category_Order_By>;
  content?: InputMaybe<Order_By>;
  course_category?: InputMaybe<Order_By>;
  course_course_categories_aggregate?: InputMaybe<Course_Course_Category_Aggregate_Order_By>;
  course_goal_maps_aggregate?: InputMaybe<Course_Goal_Map_Aggregate_Order_By>;
  course_ratings_aggregate?: InputMaybe<Course_Rating_Aggregate_Order_By>;
  courses_lesson_aggregate?: InputMaybe<Lesson_Course_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  is_feature?: InputMaybe<Order_By>;
  learning_path?: InputMaybe<Learning_Path_Order_By>;
  learning_path_id?: InputMaybe<Order_By>;
  learning_processes_aggregate?: InputMaybe<Learning_Process_Aggregate_Order_By>;
  lessons_aggregate?: InputMaybe<Lesson_Aggregate_Order_By>;
  level?: InputMaybe<Order_By>;
  levelCourseByLevelCourse?: InputMaybe<Level_Course_Order_By>;
  level_course?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profileByUpdatedBy?: InputMaybe<Profile_Order_By>;
  status?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "course" */
export type Course_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "course_rating" */
export type Course_Rating = {
  __typename?: 'course_rating';
  /** An object relationship */
  course: Course;
  course_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  rating_description?: Maybe<Scalars['String']>;
  rating_title?: Maybe<Scalars['String']>;
  score: Scalars['float8'];
  /** An object relationship */
  seeder?: Maybe<Seeder>;
  updated_at: Scalars['timestamptz'];
  user_email: Scalars['String'];
  user_name: Scalars['String'];
};

/** aggregated selection of "course_rating" */
export type Course_Rating_Aggregate = {
  __typename?: 'course_rating_aggregate';
  aggregate?: Maybe<Course_Rating_Aggregate_Fields>;
  nodes: Array<Course_Rating>;
};

/** aggregate fields of "course_rating" */
export type Course_Rating_Aggregate_Fields = {
  __typename?: 'course_rating_aggregate_fields';
  avg?: Maybe<Course_Rating_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Course_Rating_Max_Fields>;
  min?: Maybe<Course_Rating_Min_Fields>;
  stddev?: Maybe<Course_Rating_Stddev_Fields>;
  stddev_pop?: Maybe<Course_Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Course_Rating_Stddev_Samp_Fields>;
  sum?: Maybe<Course_Rating_Sum_Fields>;
  var_pop?: Maybe<Course_Rating_Var_Pop_Fields>;
  var_samp?: Maybe<Course_Rating_Var_Samp_Fields>;
  variance?: Maybe<Course_Rating_Variance_Fields>;
};


/** aggregate fields of "course_rating" */
export type Course_Rating_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Course_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "course_rating" */
export type Course_Rating_Aggregate_Order_By = {
  avg?: InputMaybe<Course_Rating_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Course_Rating_Max_Order_By>;
  min?: InputMaybe<Course_Rating_Min_Order_By>;
  stddev?: InputMaybe<Course_Rating_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Course_Rating_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Course_Rating_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Course_Rating_Sum_Order_By>;
  var_pop?: InputMaybe<Course_Rating_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Course_Rating_Var_Samp_Order_By>;
  variance?: InputMaybe<Course_Rating_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "course_rating" */
export type Course_Rating_Arr_Rel_Insert_Input = {
  data: Array<Course_Rating_Insert_Input>;
  on_conflict?: InputMaybe<Course_Rating_On_Conflict>;
};

/** aggregate avg on columns */
export type Course_Rating_Avg_Fields = {
  __typename?: 'course_rating_avg_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "course_rating" */
export type Course_Rating_Avg_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "course_rating". All fields are combined with a logical 'AND'. */
export type Course_Rating_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Course_Rating_Bool_Exp>>>;
  _not?: InputMaybe<Course_Rating_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Course_Rating_Bool_Exp>>>;
  course?: InputMaybe<Course_Bool_Exp>;
  course_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  rating_description?: InputMaybe<String_Comparison_Exp>;
  rating_title?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Float8_Comparison_Exp>;
  seeder?: InputMaybe<Seeder_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "course_rating" */
export enum Course_Rating_Constraint {
  /** unique or primary key constraint */
  CourseRatingPkey = 'course_rating_pkey'
}

/** input type for incrementing integer column in table "course_rating" */
export type Course_Rating_Inc_Input = {
  score?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "course_rating" */
export type Course_Rating_Insert_Input = {
  course_id?: InputMaybe<Scalars['Int']>;
  rating_description?: InputMaybe<Scalars['String']>;
  rating_title?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['float8']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Course_Rating_Max_Fields = {
  __typename?: 'course_rating_max_fields';
  course_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  rating_description?: Maybe<Scalars['String']>;
  rating_title?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_email?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "course_rating" */
export type Course_Rating_Max_Order_By = {
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating_description?: InputMaybe<Order_By>;
  rating_title?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Course_Rating_Min_Fields = {
  __typename?: 'course_rating_min_fields';
  course_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  rating_description?: Maybe<Scalars['String']>;
  rating_title?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_email?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "course_rating" */
export type Course_Rating_Min_Order_By = {
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating_description?: InputMaybe<Order_By>;
  rating_title?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "course_rating" */
export type Course_Rating_Mutation_Response = {
  __typename?: 'course_rating_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Course_Rating>;
};

/** input type for inserting object relation for remote table "course_rating" */
export type Course_Rating_Obj_Rel_Insert_Input = {
  data: Course_Rating_Insert_Input;
  on_conflict?: InputMaybe<Course_Rating_On_Conflict>;
};

/** on conflict condition type for table "course_rating" */
export type Course_Rating_On_Conflict = {
  constraint: Course_Rating_Constraint;
  update_columns: Array<Course_Rating_Update_Column>;
  where?: InputMaybe<Course_Rating_Bool_Exp>;
};

/** ordering options when selecting data from "course_rating" */
export type Course_Rating_Order_By = {
  course?: InputMaybe<Course_Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating_description?: InputMaybe<Order_By>;
  rating_title?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  seeder?: InputMaybe<Seeder_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "course_rating" */
export type Course_Rating_Pk_Columns_Input = {
  course_id: Scalars['Int'];
  user_email: Scalars['String'];
};

/** select columns of table "course_rating" */
export enum Course_Rating_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RatingDescription = 'rating_description',
  /** column name */
  RatingTitle = 'rating_title',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "course_rating" */
export type Course_Rating_Set_Input = {
  rating_description?: InputMaybe<Scalars['String']>;
  rating_title?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['float8']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Course_Rating_Stddev_Fields = {
  __typename?: 'course_rating_stddev_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course_rating" */
export type Course_Rating_Stddev_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Rating_Stddev_Pop_Fields = {
  __typename?: 'course_rating_stddev_pop_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course_rating" */
export type Course_Rating_Stddev_Pop_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Rating_Stddev_Samp_Fields = {
  __typename?: 'course_rating_stddev_samp_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course_rating" */
export type Course_Rating_Stddev_Samp_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Rating_Sum_Fields = {
  __typename?: 'course_rating_sum_fields';
  course_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "course_rating" */
export type Course_Rating_Sum_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** update columns of table "course_rating" */
export enum Course_Rating_Update_Column {
  /** column name */
  RatingDescription = 'rating_description',
  /** column name */
  RatingTitle = 'rating_title',
  /** column name */
  Score = 'score',
  /** column name */
  UserName = 'user_name'
}

/** aggregate var_pop on columns */
export type Course_Rating_Var_Pop_Fields = {
  __typename?: 'course_rating_var_pop_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course_rating" */
export type Course_Rating_Var_Pop_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Rating_Var_Samp_Fields = {
  __typename?: 'course_rating_var_samp_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course_rating" */
export type Course_Rating_Var_Samp_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Rating_Variance_Fields = {
  __typename?: 'course_rating_variance_fields';
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course_rating" */
export type Course_Rating_Variance_Order_By = {
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** select columns of table "course" */
export enum Course_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  AvgRating = 'avg_rating',
  /** column name */
  Content = 'content',
  /** column name */
  CourseCategory = 'course_category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentUrl = 'document_url',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  IsFeature = 'is_feature',
  /** column name */
  LearningPathId = 'learning_path_id',
  /** column name */
  Level = 'level',
  /** column name */
  LevelCourse = 'level_course',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Course_Stddev_Fields = {
  __typename?: 'course_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  avg_rating?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "course" */
export type Course_Stddev_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Course_Stddev_Pop_Fields = {
  __typename?: 'course_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  avg_rating?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "course" */
export type Course_Stddev_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Course_Stddev_Samp_Fields = {
  __typename?: 'course_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  avg_rating?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "course" */
export type Course_Stddev_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Course_Sum_Fields = {
  __typename?: 'course_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  avg_rating?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  level_course?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "course" */
export type Course_Sum_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Course_Var_Pop_Fields = {
  __typename?: 'course_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  avg_rating?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "course" */
export type Course_Var_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Course_Var_Samp_Fields = {
  __typename?: 'course_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  avg_rating?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "course" */
export type Course_Var_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Course_Variance_Fields = {
  __typename?: 'course_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  avg_rating?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "course" */
export type Course_Variance_Order_By = {
  author_id?: InputMaybe<Order_By>;
  avg_rating?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

export type DeleteUserOutput = {
  __typename?: 'deleteUserOutput';
  data?: Maybe<Scalars['ObjectDeleteUser']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "document" */
export type Document = {
  __typename?: 'document';
  created_at: Scalars['timestamptz'];
  document_url?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  lesson_document_maps: Array<Lesson_Document_Map>;
  /** An aggregated array relationship */
  lesson_document_maps_aggregate: Lesson_Document_Map_Aggregate;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "document" */
export type DocumentLesson_Document_MapsArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Document_Map_Order_By>>;
  where?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
};


/** columns and relationships of "document" */
export type DocumentLesson_Document_Maps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Document_Map_Order_By>>;
  where?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
};

/** aggregated selection of "document" */
export type Document_Aggregate = {
  __typename?: 'document_aggregate';
  aggregate?: Maybe<Document_Aggregate_Fields>;
  nodes: Array<Document>;
};

/** aggregate fields of "document" */
export type Document_Aggregate_Fields = {
  __typename?: 'document_aggregate_fields';
  avg?: Maybe<Document_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Document_Max_Fields>;
  min?: Maybe<Document_Min_Fields>;
  stddev?: Maybe<Document_Stddev_Fields>;
  stddev_pop?: Maybe<Document_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Document_Stddev_Samp_Fields>;
  sum?: Maybe<Document_Sum_Fields>;
  var_pop?: Maybe<Document_Var_Pop_Fields>;
  var_samp?: Maybe<Document_Var_Samp_Fields>;
  variance?: Maybe<Document_Variance_Fields>;
};


/** aggregate fields of "document" */
export type Document_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Document_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "document" */
export type Document_Aggregate_Order_By = {
  avg?: InputMaybe<Document_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Document_Max_Order_By>;
  min?: InputMaybe<Document_Min_Order_By>;
  stddev?: InputMaybe<Document_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Document_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Document_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Document_Sum_Order_By>;
  var_pop?: InputMaybe<Document_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Document_Var_Samp_Order_By>;
  variance?: InputMaybe<Document_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Document_Avg_Fields = {
  __typename?: 'document_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "document" */
export type Document_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "document". All fields are combined with a logical 'AND'. */
export type Document_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Document_Bool_Exp>>>;
  _not?: InputMaybe<Document_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Document_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lesson_document_maps?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  normalized_name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Document_Max_Fields = {
  __typename?: 'document_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  document_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "document" */
export type Document_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Document_Min_Fields = {
  __typename?: 'document_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  document_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "document" */
export type Document_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "document" */
export type Document_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_document_maps_aggregate?: InputMaybe<Lesson_Document_Map_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "document" */
export type Document_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "document" */
export enum Document_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentUrl = 'document_url',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Document_Stddev_Fields = {
  __typename?: 'document_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "document" */
export type Document_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Document_Stddev_Pop_Fields = {
  __typename?: 'document_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "document" */
export type Document_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Document_Stddev_Samp_Fields = {
  __typename?: 'document_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "document" */
export type Document_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Document_Sum_Fields = {
  __typename?: 'document_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "document" */
export type Document_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Document_Var_Pop_Fields = {
  __typename?: 'document_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "document" */
export type Document_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Document_Var_Samp_Fields = {
  __typename?: 'document_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "document" */
export type Document_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Document_Variance_Fields = {
  __typename?: 'document_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "document" */
export type Document_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Draft_Comment_Exchange_Args = {
  user_id?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "exchange" */
export type Exchange = {
  __typename?: 'exchange';
  avatar?: Maybe<Scalars['String']>;
  /** An array relationship */
  comment: Array<Comment_Exchange>;
  /** An aggregated array relationship */
  comment_aggregate: Comment_Exchange_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  expert_score?: Maybe<Scalars['float8']>;
  founded_year?: Maybe<Scalars['String']>;
  funding_option?: Maybe<Scalars['String']>;
  headquarters?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  link?: Maybe<Scalars['String']>;
  max_leverage?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pin?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  profileByUpdatedBy?: Maybe<Profile>;
  short_description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  spread_eurusd_fee?: Maybe<Scalars['String']>;
  trading_license?: Maybe<Scalars['String']>;
  trading_platform?: Maybe<Scalars['String']>;
  trading_products?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_score?: Maybe<Scalars['float8']>;
  withdrawal_fee?: Maybe<Scalars['String']>;
};


/** columns and relationships of "exchange" */
export type ExchangeCommentArgs = {
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** columns and relationships of "exchange" */
export type ExchangeComment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};

/** aggregated selection of "exchange" */
export type Exchange_Aggregate = {
  __typename?: 'exchange_aggregate';
  aggregate?: Maybe<Exchange_Aggregate_Fields>;
  nodes: Array<Exchange>;
};

/** aggregate fields of "exchange" */
export type Exchange_Aggregate_Fields = {
  __typename?: 'exchange_aggregate_fields';
  avg?: Maybe<Exchange_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Exchange_Max_Fields>;
  min?: Maybe<Exchange_Min_Fields>;
  stddev?: Maybe<Exchange_Stddev_Fields>;
  stddev_pop?: Maybe<Exchange_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Exchange_Stddev_Samp_Fields>;
  sum?: Maybe<Exchange_Sum_Fields>;
  var_pop?: Maybe<Exchange_Var_Pop_Fields>;
  var_samp?: Maybe<Exchange_Var_Samp_Fields>;
  variance?: Maybe<Exchange_Variance_Fields>;
};


/** aggregate fields of "exchange" */
export type Exchange_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Exchange_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "exchange" */
export type Exchange_Aggregate_Order_By = {
  avg?: InputMaybe<Exchange_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Exchange_Max_Order_By>;
  min?: InputMaybe<Exchange_Min_Order_By>;
  stddev?: InputMaybe<Exchange_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Exchange_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Exchange_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Exchange_Sum_Order_By>;
  var_pop?: InputMaybe<Exchange_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Exchange_Var_Samp_Order_By>;
  variance?: InputMaybe<Exchange_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Exchange_Avg_Fields = {
  __typename?: 'exchange_avg_fields';
  expert_score?: Maybe<Scalars['Float']>;
  user_score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "exchange" */
export type Exchange_Avg_Order_By = {
  expert_score?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "exchange". All fields are combined with a logical 'AND'. */
export type Exchange_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Exchange_Bool_Exp>>>;
  _not?: InputMaybe<Exchange_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Exchange_Bool_Exp>>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  comment?: InputMaybe<Comment_Exchange_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expert_score?: InputMaybe<Float8_Comparison_Exp>;
  founded_year?: InputMaybe<String_Comparison_Exp>;
  funding_option?: InputMaybe<String_Comparison_Exp>;
  headquarters?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  max_leverage?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pin?: InputMaybe<Boolean_Comparison_Exp>;
  profileByUpdatedBy?: InputMaybe<Profile_Bool_Exp>;
  short_description?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  spread_eurusd_fee?: InputMaybe<String_Comparison_Exp>;
  trading_license?: InputMaybe<String_Comparison_Exp>;
  trading_platform?: InputMaybe<String_Comparison_Exp>;
  trading_products?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_score?: InputMaybe<Float8_Comparison_Exp>;
  withdrawal_fee?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Exchange_Max_Fields = {
  __typename?: 'exchange_max_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  expert_score?: Maybe<Scalars['float8']>;
  founded_year?: Maybe<Scalars['String']>;
  funding_option?: Maybe<Scalars['String']>;
  headquarters?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  max_leverage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  spread_eurusd_fee?: Maybe<Scalars['String']>;
  trading_license?: Maybe<Scalars['String']>;
  trading_platform?: Maybe<Scalars['String']>;
  trading_products?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_score?: Maybe<Scalars['float8']>;
  withdrawal_fee?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "exchange" */
export type Exchange_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expert_score?: InputMaybe<Order_By>;
  founded_year?: InputMaybe<Order_By>;
  funding_option?: InputMaybe<Order_By>;
  headquarters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  max_leverage?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  spread_eurusd_fee?: InputMaybe<Order_By>;
  trading_license?: InputMaybe<Order_By>;
  trading_platform?: InputMaybe<Order_By>;
  trading_products?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
  withdrawal_fee?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Exchange_Min_Fields = {
  __typename?: 'exchange_min_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  expert_score?: Maybe<Scalars['float8']>;
  founded_year?: Maybe<Scalars['String']>;
  funding_option?: Maybe<Scalars['String']>;
  headquarters?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  link?: Maybe<Scalars['String']>;
  max_leverage?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  spread_eurusd_fee?: Maybe<Scalars['String']>;
  trading_license?: Maybe<Scalars['String']>;
  trading_platform?: Maybe<Scalars['String']>;
  trading_products?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_score?: Maybe<Scalars['float8']>;
  withdrawal_fee?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "exchange" */
export type Exchange_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expert_score?: InputMaybe<Order_By>;
  founded_year?: InputMaybe<Order_By>;
  funding_option?: InputMaybe<Order_By>;
  headquarters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  max_leverage?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  short_description?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  spread_eurusd_fee?: InputMaybe<Order_By>;
  trading_license?: InputMaybe<Order_By>;
  trading_platform?: InputMaybe<Order_By>;
  trading_products?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
  withdrawal_fee?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "exchange" */
export type Exchange_Order_By = {
  avatar?: InputMaybe<Order_By>;
  comment_aggregate?: InputMaybe<Comment_Exchange_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expert_score?: InputMaybe<Order_By>;
  founded_year?: InputMaybe<Order_By>;
  funding_option?: InputMaybe<Order_By>;
  headquarters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  max_leverage?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pin?: InputMaybe<Order_By>;
  profileByUpdatedBy?: InputMaybe<Profile_Order_By>;
  short_description?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  spread_eurusd_fee?: InputMaybe<Order_By>;
  trading_license?: InputMaybe<Order_By>;
  trading_platform?: InputMaybe<Order_By>;
  trading_products?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
  withdrawal_fee?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "exchange" */
export type Exchange_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "exchange" */
export enum Exchange_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  ExpertScore = 'expert_score',
  /** column name */
  FoundedYear = 'founded_year',
  /** column name */
  FundingOption = 'funding_option',
  /** column name */
  Headquarters = 'headquarters',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MaxLeverage = 'max_leverage',
  /** column name */
  Name = 'name',
  /** column name */
  Pin = 'pin',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  Slug = 'slug',
  /** column name */
  SpreadEurusdFee = 'spread_eurusd_fee',
  /** column name */
  TradingLicense = 'trading_license',
  /** column name */
  TradingPlatform = 'trading_platform',
  /** column name */
  TradingProducts = 'trading_products',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserScore = 'user_score',
  /** column name */
  WithdrawalFee = 'withdrawal_fee'
}

/** aggregate stddev on columns */
export type Exchange_Stddev_Fields = {
  __typename?: 'exchange_stddev_fields';
  expert_score?: Maybe<Scalars['Float']>;
  user_score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "exchange" */
export type Exchange_Stddev_Order_By = {
  expert_score?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Exchange_Stddev_Pop_Fields = {
  __typename?: 'exchange_stddev_pop_fields';
  expert_score?: Maybe<Scalars['Float']>;
  user_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "exchange" */
export type Exchange_Stddev_Pop_Order_By = {
  expert_score?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Exchange_Stddev_Samp_Fields = {
  __typename?: 'exchange_stddev_samp_fields';
  expert_score?: Maybe<Scalars['Float']>;
  user_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "exchange" */
export type Exchange_Stddev_Samp_Order_By = {
  expert_score?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Exchange_Sum_Fields = {
  __typename?: 'exchange_sum_fields';
  expert_score?: Maybe<Scalars['float8']>;
  user_score?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "exchange" */
export type Exchange_Sum_Order_By = {
  expert_score?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Exchange_Var_Pop_Fields = {
  __typename?: 'exchange_var_pop_fields';
  expert_score?: Maybe<Scalars['Float']>;
  user_score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "exchange" */
export type Exchange_Var_Pop_Order_By = {
  expert_score?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Exchange_Var_Samp_Fields = {
  __typename?: 'exchange_var_samp_fields';
  expert_score?: Maybe<Scalars['Float']>;
  user_score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "exchange" */
export type Exchange_Var_Samp_Order_By = {
  expert_score?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Exchange_Variance_Fields = {
  __typename?: 'exchange_variance_fields';
  expert_score?: Maybe<Scalars['Float']>;
  user_score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "exchange" */
export type Exchange_Variance_Order_By = {
  expert_score?: InputMaybe<Order_By>;
  user_score?: InputMaybe<Order_By>;
};

export type ExportRegisterWebinarOutput = {
  __typename?: 'exportRegisterWebinarOutput';
  data?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** expression to compare columns of type float8. All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

export type GetLearnedCourseOutput = {
  __typename?: 'getLearnedCourseOutput';
  data?: Maybe<Scalars['ListCourseObject']>;
  error?: Maybe<Scalars['Boolean']>;
};

export type GetLearnedLessonOutput = {
  __typename?: 'getLearnedLessonOutput';
  data?: Maybe<Scalars['Object']>;
  error?: Maybe<Scalars['Boolean']>;
};

export type GetListCourseOutput = {
  __typename?: 'getListCourseOutput';
  data?: Maybe<Scalars['ListCourseObject']>;
  error?: Maybe<Scalars['Boolean']>;
};

export type GetListLessonForUserOutPut = {
  __typename?: 'getListLessonForUserOutPut';
  data?: Maybe<Scalars['ObjectGetListLessonForUser']>;
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type GetListLessonOutput = {
  __typename?: 'getListLessonOutput';
  data?: Maybe<Scalars['Object']>;
  error?: Maybe<Scalars['Boolean']>;
};

export type GetListWebinarForUserOutPut = {
  __typename?: 'getListWebinarForUserOutPut';
  data?: Maybe<Scalars['ObjectgetListWebinarForUser']>;
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type GetMagazineOutput = {
  __typename?: 'getMagazineOutput';
  url: Scalars['String'];
};

export type GetMediaUrlOutput = {
  __typename?: 'getMediaURLOutput';
  url: Scalars['String'];
};

export type GetPointaverageOutput = {
  __typename?: 'getPointaverageOutput';
  data?: Maybe<Scalars['RenderedObjectPointaverage']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type HotNewsRespnose = {
  __typename?: 'hotNewsRespnose';
  data?: Maybe<Scalars['RenderedObjectHotNews']>;
  message?: Maybe<Scalars['String']>;
};

export type InsertUserOutput = {
  __typename?: 'insertUserOutput';
  data?: Maybe<Scalars['Object']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "insight" */
export type Insight = {
  __typename?: 'insight';
  content?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  insight_order?: Maybe<Scalars['Int']>;
  is_hot?: Maybe<Scalars['Boolean']>;
  is_publish?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  magazine?: Maybe<Magazine>;
  magazine_id?: Maybe<Scalars['Int']>;
  published_at: Scalars['timestamptz'];
  slug: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "insight" */
export type InsightContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "insight". All fields are combined with a logical 'AND'. */
export type Insight_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Insight_Bool_Exp>>>;
  _not?: InputMaybe<Insight_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Insight_Bool_Exp>>>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  insight_order?: InputMaybe<Int_Comparison_Exp>;
  is_hot?: InputMaybe<Boolean_Comparison_Exp>;
  is_publish?: InputMaybe<Boolean_Comparison_Exp>;
  magazine?: InputMaybe<Magazine_Bool_Exp>;
  magazine_id?: InputMaybe<Int_Comparison_Exp>;
  published_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** ordering options when selecting data from "insight" */
export type Insight_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insight_order?: InputMaybe<Order_By>;
  is_hot?: InputMaybe<Order_By>;
  is_publish?: InputMaybe<Order_By>;
  magazine?: InputMaybe<Magazine_Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "insight" */
export type Insight_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "insight" */
export enum Insight_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InsightOrder = 'insight_order',
  /** column name */
  IsHot = 'is_hot',
  /** column name */
  IsPublish = 'is_publish',
  /** column name */
  MagazineId = 'magazine_id',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Slug = 'slug',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "knowledge" */
export type Knowledge = {
  __typename?: 'knowledge';
  content: Scalars['jsonb'];
  created_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  is_publish: Scalars['Boolean'];
  /** An array relationship */
  knowledge_actions: Array<Knowledge_Action>;
  published_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  series_knowledges: Array<Series_Knowledge>;
  slug: Scalars['String'];
  thumbnail: Scalars['String'];
  title: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "knowledge" */
export type KnowledgeContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "knowledge" */
export type KnowledgeKnowledge_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Action_Order_By>>;
  where?: InputMaybe<Knowledge_Action_Bool_Exp>;
};


/** columns and relationships of "knowledge" */
export type KnowledgeSeries_KnowledgesArgs = {
  distinct_on?: InputMaybe<Array<Series_Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Knowledge_Order_By>>;
  where?: InputMaybe<Series_Knowledge_Bool_Exp>;
};

/** columns and relationships of "knowledge_action" */
export type Knowledge_Action = {
  __typename?: 'knowledge_action';
  action_type: Scalars['bpchar'];
  browser_id: Scalars['String'];
  id: Scalars['Int'];
  /** An object relationship */
  knowledge: Knowledge;
  knowledge_id: Scalars['Int'];
};

/** input type for inserting array relation for remote table "knowledge_action" */
export type Knowledge_Action_Arr_Rel_Insert_Input = {
  data: Array<Knowledge_Action_Insert_Input>;
  on_conflict?: InputMaybe<Knowledge_Action_On_Conflict>;
};

/** Boolean expression to filter rows from the table "knowledge_action". All fields are combined with a logical 'AND'. */
export type Knowledge_Action_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Knowledge_Action_Bool_Exp>>>;
  _not?: InputMaybe<Knowledge_Action_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Knowledge_Action_Bool_Exp>>>;
  action_type?: InputMaybe<Bpchar_Comparison_Exp>;
  browser_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  knowledge?: InputMaybe<Knowledge_Bool_Exp>;
  knowledge_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "knowledge_action" */
export enum Knowledge_Action_Constraint {
  /** unique or primary key constraint */
  KnowledgeActionBrowserIdKnowledgeIdKey = 'knowledge_action_browser_id_knowledge_id_key',
  /** unique or primary key constraint */
  KnowledgeActionPkey = 'knowledge_action_pkey'
}

/** input type for incrementing integer column in table "knowledge_action" */
export type Knowledge_Action_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  knowledge_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "knowledge_action" */
export type Knowledge_Action_Insert_Input = {
  action_type?: InputMaybe<Scalars['bpchar']>;
  browser_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  knowledge_id?: InputMaybe<Scalars['Int']>;
};

/** response of any mutation on the table "knowledge_action" */
export type Knowledge_Action_Mutation_Response = {
  __typename?: 'knowledge_action_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Knowledge_Action>;
};

/** input type for inserting object relation for remote table "knowledge_action" */
export type Knowledge_Action_Obj_Rel_Insert_Input = {
  data: Knowledge_Action_Insert_Input;
  on_conflict?: InputMaybe<Knowledge_Action_On_Conflict>;
};

/** on conflict condition type for table "knowledge_action" */
export type Knowledge_Action_On_Conflict = {
  constraint: Knowledge_Action_Constraint;
  update_columns: Array<Knowledge_Action_Update_Column>;
  where?: InputMaybe<Knowledge_Action_Bool_Exp>;
};

/** ordering options when selecting data from "knowledge_action" */
export type Knowledge_Action_Order_By = {
  action_type?: InputMaybe<Order_By>;
  browser_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  knowledge?: InputMaybe<Knowledge_Order_By>;
  knowledge_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "knowledge_action" */
export type Knowledge_Action_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "knowledge_action" */
export enum Knowledge_Action_Select_Column {
  /** column name */
  ActionType = 'action_type',
  /** column name */
  BrowserId = 'browser_id',
  /** column name */
  Id = 'id',
  /** column name */
  KnowledgeId = 'knowledge_id'
}

/** input type for updating data in table "knowledge_action" */
export type Knowledge_Action_Set_Input = {
  action_type?: InputMaybe<Scalars['bpchar']>;
  browser_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  knowledge_id?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "knowledge_action" */
export enum Knowledge_Action_Update_Column {
  /** column name */
  ActionType = 'action_type',
  /** column name */
  BrowserId = 'browser_id',
  /** column name */
  Id = 'id',
  /** column name */
  KnowledgeId = 'knowledge_id'
}

/** aggregated selection of "knowledge" */
export type Knowledge_Aggregate = {
  __typename?: 'knowledge_aggregate';
  aggregate?: Maybe<Knowledge_Aggregate_Fields>;
  nodes: Array<Knowledge>;
};

/** aggregate fields of "knowledge" */
export type Knowledge_Aggregate_Fields = {
  __typename?: 'knowledge_aggregate_fields';
  avg?: Maybe<Knowledge_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Knowledge_Max_Fields>;
  min?: Maybe<Knowledge_Min_Fields>;
  stddev?: Maybe<Knowledge_Stddev_Fields>;
  stddev_pop?: Maybe<Knowledge_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Knowledge_Stddev_Samp_Fields>;
  sum?: Maybe<Knowledge_Sum_Fields>;
  var_pop?: Maybe<Knowledge_Var_Pop_Fields>;
  var_samp?: Maybe<Knowledge_Var_Samp_Fields>;
  variance?: Maybe<Knowledge_Variance_Fields>;
};


/** aggregate fields of "knowledge" */
export type Knowledge_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Knowledge_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "knowledge" */
export type Knowledge_Aggregate_Order_By = {
  avg?: InputMaybe<Knowledge_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Knowledge_Max_Order_By>;
  min?: InputMaybe<Knowledge_Min_Order_By>;
  stddev?: InputMaybe<Knowledge_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Knowledge_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Knowledge_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Knowledge_Sum_Order_By>;
  var_pop?: InputMaybe<Knowledge_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Knowledge_Var_Samp_Order_By>;
  variance?: InputMaybe<Knowledge_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Knowledge_Avg_Fields = {
  __typename?: 'knowledge_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "knowledge" */
export type Knowledge_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "knowledge". All fields are combined with a logical 'AND'. */
export type Knowledge_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Knowledge_Bool_Exp>>>;
  _not?: InputMaybe<Knowledge_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Knowledge_Bool_Exp>>>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_publish?: InputMaybe<Boolean_Comparison_Exp>;
  knowledge_actions?: InputMaybe<Knowledge_Action_Bool_Exp>;
  published_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  series_knowledges?: InputMaybe<Series_Knowledge_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Knowledge_Max_Fields = {
  __typename?: 'knowledge_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "knowledge" */
export type Knowledge_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Knowledge_Min_Fields = {
  __typename?: 'knowledge_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "knowledge" */
export type Knowledge_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "knowledge" */
export type Knowledge_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_publish?: InputMaybe<Order_By>;
  published_at?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "knowledge" */
export type Knowledge_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "knowledge" */
export enum Knowledge_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublish = 'is_publish',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  Slug = 'slug',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Knowledge_Stddev_Fields = {
  __typename?: 'knowledge_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "knowledge" */
export type Knowledge_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Knowledge_Stddev_Pop_Fields = {
  __typename?: 'knowledge_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "knowledge" */
export type Knowledge_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Knowledge_Stddev_Samp_Fields = {
  __typename?: 'knowledge_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "knowledge" */
export type Knowledge_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Knowledge_Sum_Fields = {
  __typename?: 'knowledge_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "knowledge" */
export type Knowledge_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Knowledge_Var_Pop_Fields = {
  __typename?: 'knowledge_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "knowledge" */
export type Knowledge_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Knowledge_Var_Samp_Fields = {
  __typename?: 'knowledge_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "knowledge" */
export type Knowledge_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Knowledge_Variance_Fields = {
  __typename?: 'knowledge_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "knowledge" */
export type Knowledge_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "learning_path" */
export type Learning_Path = {
  __typename?: 'learning_path';
  /** An array relationship */
  courses: Array<Course>;
  /** An aggregated array relationship */
  courses_aggregate: Course_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};


/** columns and relationships of "learning_path" */
export type Learning_PathCoursesArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** columns and relationships of "learning_path" */
export type Learning_PathCourses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};

/** aggregated selection of "learning_path" */
export type Learning_Path_Aggregate = {
  __typename?: 'learning_path_aggregate';
  aggregate?: Maybe<Learning_Path_Aggregate_Fields>;
  nodes: Array<Learning_Path>;
};

/** aggregate fields of "learning_path" */
export type Learning_Path_Aggregate_Fields = {
  __typename?: 'learning_path_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Learning_Path_Max_Fields>;
  min?: Maybe<Learning_Path_Min_Fields>;
};


/** aggregate fields of "learning_path" */
export type Learning_Path_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Path_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "learning_path" */
export type Learning_Path_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Learning_Path_Max_Order_By>;
  min?: InputMaybe<Learning_Path_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "learning_path". All fields are combined with a logical 'AND'. */
export type Learning_Path_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Learning_Path_Bool_Exp>>>;
  _not?: InputMaybe<Learning_Path_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Learning_Path_Bool_Exp>>>;
  courses?: InputMaybe<Course_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Learning_Path_Max_Fields = {
  __typename?: 'learning_path_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "learning_path" */
export type Learning_Path_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Learning_Path_Min_Fields = {
  __typename?: 'learning_path_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "learning_path" */
export type Learning_Path_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "learning_path" */
export type Learning_Path_Order_By = {
  courses_aggregate?: InputMaybe<Course_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "learning_path" */
export type Learning_Path_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "learning_path" */
export enum Learning_Path_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** columns and relationships of "learning_process" */
export type Learning_Process = {
  __typename?: 'learning_process';
  completeness: Scalars['numeric'];
  /** An object relationship */
  course: Course;
  course_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  lesson: Lesson;
  lesson_id: Scalars['Int'];
  status: Scalars['String'];
  user_id: Scalars['String'];
};

/** aggregated selection of "learning_process" */
export type Learning_Process_Aggregate = {
  __typename?: 'learning_process_aggregate';
  aggregate?: Maybe<Learning_Process_Aggregate_Fields>;
  nodes: Array<Learning_Process>;
};

/** aggregate fields of "learning_process" */
export type Learning_Process_Aggregate_Fields = {
  __typename?: 'learning_process_aggregate_fields';
  avg?: Maybe<Learning_Process_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Learning_Process_Max_Fields>;
  min?: Maybe<Learning_Process_Min_Fields>;
  stddev?: Maybe<Learning_Process_Stddev_Fields>;
  stddev_pop?: Maybe<Learning_Process_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Learning_Process_Stddev_Samp_Fields>;
  sum?: Maybe<Learning_Process_Sum_Fields>;
  var_pop?: Maybe<Learning_Process_Var_Pop_Fields>;
  var_samp?: Maybe<Learning_Process_Var_Samp_Fields>;
  variance?: Maybe<Learning_Process_Variance_Fields>;
};


/** aggregate fields of "learning_process" */
export type Learning_Process_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Process_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "learning_process" */
export type Learning_Process_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Process_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Learning_Process_Max_Order_By>;
  min?: InputMaybe<Learning_Process_Min_Order_By>;
  stddev?: InputMaybe<Learning_Process_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Learning_Process_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Learning_Process_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Learning_Process_Sum_Order_By>;
  var_pop?: InputMaybe<Learning_Process_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Learning_Process_Var_Samp_Order_By>;
  variance?: InputMaybe<Learning_Process_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Learning_Process_Avg_Fields = {
  __typename?: 'learning_process_avg_fields';
  completeness?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "learning_process" */
export type Learning_Process_Avg_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "learning_process". All fields are combined with a logical 'AND'. */
export type Learning_Process_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Learning_Process_Bool_Exp>>>;
  _not?: InputMaybe<Learning_Process_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Learning_Process_Bool_Exp>>>;
  completeness?: InputMaybe<Numeric_Comparison_Exp>;
  course?: InputMaybe<Course_Bool_Exp>;
  course_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lesson?: InputMaybe<Lesson_Bool_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Learning_Process_Max_Fields = {
  __typename?: 'learning_process_max_fields';
  completeness?: Maybe<Scalars['numeric']>;
  course_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "learning_process" */
export type Learning_Process_Max_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Learning_Process_Min_Fields = {
  __typename?: 'learning_process_min_fields';
  completeness?: Maybe<Scalars['numeric']>;
  course_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "learning_process" */
export type Learning_Process_Min_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "learning_process" */
export type Learning_Process_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course?: InputMaybe<Course_Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson?: InputMaybe<Lesson_Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "learning_process" */
export type Learning_Process_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "learning_process" */
export enum Learning_Process_Select_Column {
  /** column name */
  Completeness = 'completeness',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Learning_Process_Stddev_Fields = {
  __typename?: 'learning_process_stddev_fields';
  completeness?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "learning_process" */
export type Learning_Process_Stddev_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Learning_Process_Stddev_Pop_Fields = {
  __typename?: 'learning_process_stddev_pop_fields';
  completeness?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "learning_process" */
export type Learning_Process_Stddev_Pop_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Learning_Process_Stddev_Samp_Fields = {
  __typename?: 'learning_process_stddev_samp_fields';
  completeness?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "learning_process" */
export type Learning_Process_Stddev_Samp_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Learning_Process_Sum_Fields = {
  __typename?: 'learning_process_sum_fields';
  completeness?: Maybe<Scalars['numeric']>;
  course_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "learning_process" */
export type Learning_Process_Sum_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Learning_Process_Var_Pop_Fields = {
  __typename?: 'learning_process_var_pop_fields';
  completeness?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "learning_process" */
export type Learning_Process_Var_Pop_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Learning_Process_Var_Samp_Fields = {
  __typename?: 'learning_process_var_samp_fields';
  completeness?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "learning_process" */
export type Learning_Process_Var_Samp_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Learning_Process_Variance_Fields = {
  __typename?: 'learning_process_variance_fields';
  completeness?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "learning_process" */
export type Learning_Process_Variance_Order_By = {
  completeness?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lesson" */
export type Lesson = {
  __typename?: 'lesson';
  avg_point?: Maybe<Scalars['float8']>;
  content?: Maybe<Scalars['String']>;
  /** An object relationship */
  course?: Maybe<Course>;
  course_id?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  dislike_amount: Scalars['Int'];
  duration?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  index: Scalars['Int'];
  is_highlight?: Maybe<Scalars['Boolean']>;
  is_highlight_on_category?: Maybe<Scalars['Boolean']>;
  /** A computed field, executes function "testfunction5" */
  learned?: Maybe<Scalars['String']>;
  /** An array relationship */
  learning_processes: Array<Learning_Process>;
  /** An aggregated array relationship */
  learning_processes_aggregate: Learning_Process_Aggregate;
  /** An object relationship */
  lesson_category?: Maybe<Lesson_Category>;
  lesson_category_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  lesson_classify?: Maybe<Lesson_Classify>;
  lesson_classify_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  lesson_comment_webs: Array<Lesson_Comment_Web>;
  /** An aggregated array relationship */
  lesson_comment_webs_aggregate: Lesson_Comment_Web_Aggregate;
  /** An array relationship */
  lesson_courses: Array<Lesson_Course>;
  /** An aggregated array relationship */
  lesson_courses_aggregate: Lesson_Course_Aggregate;
  /** An array relationship */
  lesson_document_maps: Array<Lesson_Document_Map>;
  /** An aggregated array relationship */
  lesson_document_maps_aggregate: Lesson_Document_Map_Aggregate;
  /** An array relationship */
  lesson_ratings: Array<Lesson_Rating>;
  /** An aggregated array relationship */
  lesson_ratings_aggregate: Lesson_Rating_Aggregate;
  like_amount: Scalars['Int'];
  /** An array relationship */
  like_counts: Array<Like_Count>;
  /** An aggregated array relationship */
  like_counts_aggregate: Like_Count_Aggregate;
  name: Scalars['String'];
  name_file?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  rating?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  review_lessons: Array<Review_Lesson>;
  static_video_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  /** A computed field, executes function "lesson_video_convert" */
  video_convert?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  video_watcheds: Array<Video_Watched>;
  views?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "lesson" */
export type LessonLearnedArgs = {
  args: Testfunction5_Args;
};


/** columns and relationships of "lesson" */
export type LessonLearning_ProcessesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Process_Order_By>>;
  where?: InputMaybe<Learning_Process_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLearning_Processes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Process_Order_By>>;
  where?: InputMaybe<Learning_Process_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLesson_Comment_WebsArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Comment_Web_Order_By>>;
  where?: InputMaybe<Lesson_Comment_Web_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLesson_Comment_Webs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Comment_Web_Order_By>>;
  where?: InputMaybe<Lesson_Comment_Web_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLesson_CoursesArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_Order_By>>;
  where?: InputMaybe<Lesson_Course_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLesson_Courses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_Order_By>>;
  where?: InputMaybe<Lesson_Course_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLesson_Document_MapsArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Document_Map_Order_By>>;
  where?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLesson_Document_Maps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Document_Map_Order_By>>;
  where?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLesson_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Rating_Order_By>>;
  where?: InputMaybe<Lesson_Rating_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLesson_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Rating_Order_By>>;
  where?: InputMaybe<Lesson_Rating_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLike_CountsArgs = {
  distinct_on?: InputMaybe<Array<Like_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Like_Count_Order_By>>;
  where?: InputMaybe<Like_Count_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonLike_Counts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Like_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Like_Count_Order_By>>;
  where?: InputMaybe<Like_Count_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonReview_LessonsArgs = {
  distinct_on?: InputMaybe<Array<Review_Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Lesson_Order_By>>;
  where?: InputMaybe<Review_Lesson_Bool_Exp>;
};


/** columns and relationships of "lesson" */
export type LessonVideo_WatchedsArgs = {
  distinct_on?: InputMaybe<Array<Video_Watched_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Watched_Order_By>>;
  where?: InputMaybe<Video_Watched_Bool_Exp>;
};

/** aggregated selection of "lesson" */
export type Lesson_Aggregate = {
  __typename?: 'lesson_aggregate';
  aggregate?: Maybe<Lesson_Aggregate_Fields>;
  nodes: Array<Lesson>;
};

/** aggregate fields of "lesson" */
export type Lesson_Aggregate_Fields = {
  __typename?: 'lesson_aggregate_fields';
  avg?: Maybe<Lesson_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Lesson_Max_Fields>;
  min?: Maybe<Lesson_Min_Fields>;
  stddev?: Maybe<Lesson_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Sum_Fields>;
  var_pop?: Maybe<Lesson_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Variance_Fields>;
};


/** aggregate fields of "lesson" */
export type Lesson_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lesson_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson" */
export type Lesson_Aggregate_Order_By = {
  avg?: InputMaybe<Lesson_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lesson_Max_Order_By>;
  min?: InputMaybe<Lesson_Min_Order_By>;
  stddev?: InputMaybe<Lesson_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lesson_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lesson_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lesson_Sum_Order_By>;
  var_pop?: InputMaybe<Lesson_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lesson_Var_Samp_Order_By>;
  variance?: InputMaybe<Lesson_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Lesson_Avg_Fields = {
  __typename?: 'lesson_avg_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  dislike_amount?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  like_amount?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson" */
export type Lesson_Avg_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson". All fields are combined with a logical 'AND'. */
export type Lesson_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Lesson_Bool_Exp>>>;
  _not?: InputMaybe<Lesson_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Lesson_Bool_Exp>>>;
  avg_point?: InputMaybe<Float8_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  course?: InputMaybe<Course_Bool_Exp>;
  course_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  dislike_amount?: InputMaybe<Int_Comparison_Exp>;
  duration?: InputMaybe<Int_Comparison_Exp>;
  fake_views?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  is_highlight?: InputMaybe<Boolean_Comparison_Exp>;
  is_highlight_on_category?: InputMaybe<Boolean_Comparison_Exp>;
  learning_processes?: InputMaybe<Learning_Process_Bool_Exp>;
  lesson_category?: InputMaybe<Lesson_Category_Bool_Exp>;
  lesson_category_id?: InputMaybe<Int_Comparison_Exp>;
  lesson_classify?: InputMaybe<Lesson_Classify_Bool_Exp>;
  lesson_classify_id?: InputMaybe<Uuid_Comparison_Exp>;
  lesson_comment_webs?: InputMaybe<Lesson_Comment_Web_Bool_Exp>;
  lesson_courses?: InputMaybe<Lesson_Course_Bool_Exp>;
  lesson_document_maps?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
  lesson_ratings?: InputMaybe<Lesson_Rating_Bool_Exp>;
  like_amount?: InputMaybe<Int_Comparison_Exp>;
  like_counts?: InputMaybe<Like_Count_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_file?: InputMaybe<String_Comparison_Exp>;
  normalized_name?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  rating?: InputMaybe<Numeric_Comparison_Exp>;
  review_lessons?: InputMaybe<Review_Lesson_Bool_Exp>;
  static_video_url?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  video_url?: InputMaybe<String_Comparison_Exp>;
  video_watcheds?: InputMaybe<Video_Watched_Bool_Exp>;
  views?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "lesson_category" */
export type Lesson_Category = {
  __typename?: 'lesson_category';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

/** aggregated selection of "lesson_category" */
export type Lesson_Category_Aggregate = {
  __typename?: 'lesson_category_aggregate';
  aggregate?: Maybe<Lesson_Category_Aggregate_Fields>;
  nodes: Array<Lesson_Category>;
};

/** aggregate fields of "lesson_category" */
export type Lesson_Category_Aggregate_Fields = {
  __typename?: 'lesson_category_aggregate_fields';
  avg?: Maybe<Lesson_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Lesson_Category_Max_Fields>;
  min?: Maybe<Lesson_Category_Min_Fields>;
  stddev?: Maybe<Lesson_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Category_Sum_Fields>;
  var_pop?: Maybe<Lesson_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Category_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Category_Variance_Fields>;
};


/** aggregate fields of "lesson_category" */
export type Lesson_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_category" */
export type Lesson_Category_Aggregate_Order_By = {
  avg?: InputMaybe<Lesson_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lesson_Category_Max_Order_By>;
  min?: InputMaybe<Lesson_Category_Min_Order_By>;
  stddev?: InputMaybe<Lesson_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lesson_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lesson_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lesson_Category_Sum_Order_By>;
  var_pop?: InputMaybe<Lesson_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lesson_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Lesson_Category_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Lesson_Category_Avg_Fields = {
  __typename?: 'lesson_category_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_category" */
export type Lesson_Category_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_category". All fields are combined with a logical 'AND'. */
export type Lesson_Category_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Lesson_Category_Bool_Exp>>>;
  _not?: InputMaybe<Lesson_Category_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Lesson_Category_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Lesson_Category_Max_Fields = {
  __typename?: 'lesson_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lesson_category" */
export type Lesson_Category_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Category_Min_Fields = {
  __typename?: 'lesson_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lesson_category" */
export type Lesson_Category_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "lesson_category" */
export type Lesson_Category_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "lesson_category" */
export type Lesson_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "lesson_category" */
export enum Lesson_Category_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Lesson_Category_Stddev_Fields = {
  __typename?: 'lesson_category_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_category" */
export type Lesson_Category_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Category_Stddev_Pop_Fields = {
  __typename?: 'lesson_category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_category" */
export type Lesson_Category_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Category_Stddev_Samp_Fields = {
  __typename?: 'lesson_category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_category" */
export type Lesson_Category_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Category_Sum_Fields = {
  __typename?: 'lesson_category_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_category" */
export type Lesson_Category_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Lesson_Category_Var_Pop_Fields = {
  __typename?: 'lesson_category_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_category" */
export type Lesson_Category_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Category_Var_Samp_Fields = {
  __typename?: 'lesson_category_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_category" */
export type Lesson_Category_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Category_Variance_Fields = {
  __typename?: 'lesson_category_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_category" */
export type Lesson_Category_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lesson_classify" */
export type Lesson_Classify = {
  __typename?: 'lesson_classify';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  lessons: Array<Lesson>;
  /** An aggregated array relationship */
  lessons_aggregate: Lesson_Aggregate;
  name: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};


/** columns and relationships of "lesson_classify" */
export type Lesson_ClassifyLessonsArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** columns and relationships of "lesson_classify" */
export type Lesson_ClassifyLessons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};

/** aggregated selection of "lesson_classify" */
export type Lesson_Classify_Aggregate = {
  __typename?: 'lesson_classify_aggregate';
  aggregate?: Maybe<Lesson_Classify_Aggregate_Fields>;
  nodes: Array<Lesson_Classify>;
};

/** aggregate fields of "lesson_classify" */
export type Lesson_Classify_Aggregate_Fields = {
  __typename?: 'lesson_classify_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Lesson_Classify_Max_Fields>;
  min?: Maybe<Lesson_Classify_Min_Fields>;
};


/** aggregate fields of "lesson_classify" */
export type Lesson_Classify_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_classify" */
export type Lesson_Classify_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lesson_Classify_Max_Order_By>;
  min?: InputMaybe<Lesson_Classify_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_classify". All fields are combined with a logical 'AND'. */
export type Lesson_Classify_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Lesson_Classify_Bool_Exp>>>;
  _not?: InputMaybe<Lesson_Classify_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Lesson_Classify_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lessons?: InputMaybe<Lesson_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Lesson_Classify_Max_Fields = {
  __typename?: 'lesson_classify_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lesson_classify" */
export type Lesson_Classify_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Classify_Min_Fields = {
  __typename?: 'lesson_classify_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lesson_classify" */
export type Lesson_Classify_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "lesson_classify" */
export type Lesson_Classify_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lessons_aggregate?: InputMaybe<Lesson_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "lesson_classify" */
export type Lesson_Classify_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lesson_classify" */
export enum Lesson_Classify_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** columns and relationships of "lesson_comment_web" */
export type Lesson_Comment_Web = {
  __typename?: 'lesson_comment_web';
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  lesson: Lesson;
  lesson_id: Scalars['Int'];
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  seeder?: Maybe<Seeder>;
  updated_at: Scalars['timestamptz'];
  user_email: Scalars['String'];
  user_name: Scalars['String'];
};

/** aggregated selection of "lesson_comment_web" */
export type Lesson_Comment_Web_Aggregate = {
  __typename?: 'lesson_comment_web_aggregate';
  aggregate?: Maybe<Lesson_Comment_Web_Aggregate_Fields>;
  nodes: Array<Lesson_Comment_Web>;
};

/** aggregate fields of "lesson_comment_web" */
export type Lesson_Comment_Web_Aggregate_Fields = {
  __typename?: 'lesson_comment_web_aggregate_fields';
  avg?: Maybe<Lesson_Comment_Web_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Lesson_Comment_Web_Max_Fields>;
  min?: Maybe<Lesson_Comment_Web_Min_Fields>;
  stddev?: Maybe<Lesson_Comment_Web_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Comment_Web_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Comment_Web_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Comment_Web_Sum_Fields>;
  var_pop?: Maybe<Lesson_Comment_Web_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Comment_Web_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Comment_Web_Variance_Fields>;
};


/** aggregate fields of "lesson_comment_web" */
export type Lesson_Comment_Web_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lesson_Comment_Web_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_comment_web" */
export type Lesson_Comment_Web_Aggregate_Order_By = {
  avg?: InputMaybe<Lesson_Comment_Web_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lesson_Comment_Web_Max_Order_By>;
  min?: InputMaybe<Lesson_Comment_Web_Min_Order_By>;
  stddev?: InputMaybe<Lesson_Comment_Web_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lesson_Comment_Web_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lesson_Comment_Web_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lesson_Comment_Web_Sum_Order_By>;
  var_pop?: InputMaybe<Lesson_Comment_Web_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lesson_Comment_Web_Var_Samp_Order_By>;
  variance?: InputMaybe<Lesson_Comment_Web_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "lesson_comment_web" */
export type Lesson_Comment_Web_Arr_Rel_Insert_Input = {
  data: Array<Lesson_Comment_Web_Insert_Input>;
};

/** aggregate avg on columns */
export type Lesson_Comment_Web_Avg_Fields = {
  __typename?: 'lesson_comment_web_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_comment_web". All fields are combined with a logical 'AND'. */
export type Lesson_Comment_Web_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Lesson_Comment_Web_Bool_Exp>>>;
  _not?: InputMaybe<Lesson_Comment_Web_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Lesson_Comment_Web_Bool_Exp>>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lesson?: InputMaybe<Lesson_Bool_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  seeder?: InputMaybe<Seeder_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "lesson_comment_web" */
export type Lesson_Comment_Web_Insert_Input = {
  content?: InputMaybe<Scalars['String']>;
  lesson_id?: InputMaybe<Scalars['Int']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lesson_Comment_Web_Max_Fields = {
  __typename?: 'lesson_comment_web_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_email?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Comment_Web_Min_Fields = {
  __typename?: 'lesson_comment_web_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_email?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lesson_comment_web" */
export type Lesson_Comment_Web_Mutation_Response = {
  __typename?: 'lesson_comment_web_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Lesson_Comment_Web>;
};

/** input type for inserting object relation for remote table "lesson_comment_web" */
export type Lesson_Comment_Web_Obj_Rel_Insert_Input = {
  data: Lesson_Comment_Web_Insert_Input;
};

/** ordering options when selecting data from "lesson_comment_web" */
export type Lesson_Comment_Web_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson?: InputMaybe<Lesson_Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  seeder?: InputMaybe<Seeder_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "lesson_comment_web" */
export type Lesson_Comment_Web_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "lesson_comment_web" */
export enum Lesson_Comment_Web_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserName = 'user_name'
}

/** aggregate stddev on columns */
export type Lesson_Comment_Web_Stddev_Fields = {
  __typename?: 'lesson_comment_web_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Comment_Web_Stddev_Pop_Fields = {
  __typename?: 'lesson_comment_web_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Comment_Web_Stddev_Samp_Fields = {
  __typename?: 'lesson_comment_web_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Comment_Web_Sum_Fields = {
  __typename?: 'lesson_comment_web_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Lesson_Comment_Web_Var_Pop_Fields = {
  __typename?: 'lesson_comment_web_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Comment_Web_Var_Samp_Fields = {
  __typename?: 'lesson_comment_web_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Comment_Web_Variance_Fields = {
  __typename?: 'lesson_comment_web_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_comment_web" */
export type Lesson_Comment_Web_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lesson_course" */
export type Lesson_Course = {
  __typename?: 'lesson_course';
  /** An object relationship */
  course: Course;
  course_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  lesson: Lesson;
  lesson_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "lesson_course" */
export type Lesson_Course_Aggregate = {
  __typename?: 'lesson_course_aggregate';
  aggregate?: Maybe<Lesson_Course_Aggregate_Fields>;
  nodes: Array<Lesson_Course>;
};

/** aggregate fields of "lesson_course" */
export type Lesson_Course_Aggregate_Fields = {
  __typename?: 'lesson_course_aggregate_fields';
  avg?: Maybe<Lesson_Course_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Lesson_Course_Max_Fields>;
  min?: Maybe<Lesson_Course_Min_Fields>;
  stddev?: Maybe<Lesson_Course_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Course_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Course_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Course_Sum_Fields>;
  var_pop?: Maybe<Lesson_Course_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Course_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Course_Variance_Fields>;
};


/** aggregate fields of "lesson_course" */
export type Lesson_Course_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_course" */
export type Lesson_Course_Aggregate_Order_By = {
  avg?: InputMaybe<Lesson_Course_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lesson_Course_Max_Order_By>;
  min?: InputMaybe<Lesson_Course_Min_Order_By>;
  stddev?: InputMaybe<Lesson_Course_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lesson_Course_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lesson_Course_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lesson_Course_Sum_Order_By>;
  var_pop?: InputMaybe<Lesson_Course_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lesson_Course_Var_Samp_Order_By>;
  variance?: InputMaybe<Lesson_Course_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Lesson_Course_Avg_Fields = {
  __typename?: 'lesson_course_avg_fields';
  course_id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_course" */
export type Lesson_Course_Avg_Order_By = {
  course_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_course". All fields are combined with a logical 'AND'. */
export type Lesson_Course_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Lesson_Course_Bool_Exp>>>;
  _not?: InputMaybe<Lesson_Course_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Lesson_Course_Bool_Exp>>>;
  course?: InputMaybe<Course_Bool_Exp>;
  course_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lesson?: InputMaybe<Lesson_Bool_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Lesson_Course_Max_Fields = {
  __typename?: 'lesson_course_max_fields';
  course_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lesson_course" */
export type Lesson_Course_Max_Order_By = {
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Course_Min_Fields = {
  __typename?: 'lesson_course_min_fields';
  course_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lesson_course" */
export type Lesson_Course_Min_Order_By = {
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "lesson_course" */
export type Lesson_Course_Order_By = {
  course?: InputMaybe<Course_Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson?: InputMaybe<Lesson_Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "lesson_course" */
export type Lesson_Course_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "lesson_course" */
export enum Lesson_Course_Select_Column {
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type Lesson_Course_Stddev_Fields = {
  __typename?: 'lesson_course_stddev_fields';
  course_id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_course" */
export type Lesson_Course_Stddev_Order_By = {
  course_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Course_Stddev_Pop_Fields = {
  __typename?: 'lesson_course_stddev_pop_fields';
  course_id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_course" */
export type Lesson_Course_Stddev_Pop_Order_By = {
  course_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Course_Stddev_Samp_Fields = {
  __typename?: 'lesson_course_stddev_samp_fields';
  course_id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_course" */
export type Lesson_Course_Stddev_Samp_Order_By = {
  course_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Course_Sum_Fields = {
  __typename?: 'lesson_course_sum_fields';
  course_id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_course" */
export type Lesson_Course_Sum_Order_By = {
  course_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Lesson_Course_Var_Pop_Fields = {
  __typename?: 'lesson_course_var_pop_fields';
  course_id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_course" */
export type Lesson_Course_Var_Pop_Order_By = {
  course_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Course_Var_Samp_Fields = {
  __typename?: 'lesson_course_var_samp_fields';
  course_id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_course" */
export type Lesson_Course_Var_Samp_Order_By = {
  course_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Course_Variance_Fields = {
  __typename?: 'lesson_course_variance_fields';
  course_id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_course" */
export type Lesson_Course_Variance_Order_By = {
  course_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "lesson_course_view" */
export type Lesson_Course_View = {
  __typename?: 'lesson_course_view';
  author_id?: Maybe<Scalars['Int']>;
  course_category?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  document_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  is_feature?: Maybe<Scalars['Boolean']>;
  learning_path_id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  level_course?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "lesson_course_view" */
export type Lesson_Course_View_Aggregate = {
  __typename?: 'lesson_course_view_aggregate';
  aggregate?: Maybe<Lesson_Course_View_Aggregate_Fields>;
  nodes: Array<Lesson_Course_View>;
};

/** aggregate fields of "lesson_course_view" */
export type Lesson_Course_View_Aggregate_Fields = {
  __typename?: 'lesson_course_view_aggregate_fields';
  avg?: Maybe<Lesson_Course_View_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Lesson_Course_View_Max_Fields>;
  min?: Maybe<Lesson_Course_View_Min_Fields>;
  stddev?: Maybe<Lesson_Course_View_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Course_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Course_View_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Course_View_Sum_Fields>;
  var_pop?: Maybe<Lesson_Course_View_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Course_View_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Course_View_Variance_Fields>;
};


/** aggregate fields of "lesson_course_view" */
export type Lesson_Course_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lesson_Course_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_course_view" */
export type Lesson_Course_View_Aggregate_Order_By = {
  avg?: InputMaybe<Lesson_Course_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lesson_Course_View_Max_Order_By>;
  min?: InputMaybe<Lesson_Course_View_Min_Order_By>;
  stddev?: InputMaybe<Lesson_Course_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lesson_Course_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lesson_Course_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lesson_Course_View_Sum_Order_By>;
  var_pop?: InputMaybe<Lesson_Course_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lesson_Course_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Lesson_Course_View_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Lesson_Course_View_Avg_Fields = {
  __typename?: 'lesson_course_view_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Avg_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_course_view". All fields are combined with a logical 'AND'. */
export type Lesson_Course_View_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Lesson_Course_View_Bool_Exp>>>;
  _not?: InputMaybe<Lesson_Course_View_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Lesson_Course_View_Bool_Exp>>>;
  author_id?: InputMaybe<Int_Comparison_Exp>;
  course_category?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  document_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  is_feature?: InputMaybe<Boolean_Comparison_Exp>;
  learning_path_id?: InputMaybe<Uuid_Comparison_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
  level?: InputMaybe<String_Comparison_Exp>;
  level_course?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<String_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Lesson_Course_View_Max_Fields = {
  __typename?: 'lesson_course_view_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  course_category?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  document_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  learning_path_id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  level_course?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Max_Order_By = {
  author_id?: InputMaybe<Order_By>;
  course_category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  learning_path_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Course_View_Min_Fields = {
  __typename?: 'lesson_course_view_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  course_category?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  document_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  learning_path_id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  level_course?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Min_Order_By = {
  author_id?: InputMaybe<Order_By>;
  course_category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  learning_path_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "lesson_course_view" */
export type Lesson_Course_View_Order_By = {
  author_id?: InputMaybe<Order_By>;
  course_category?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  document_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  is_feature?: InputMaybe<Order_By>;
  learning_path_id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "lesson_course_view" */
export enum Lesson_Course_View_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CourseCategory = 'course_category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentUrl = 'document_url',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  IsFeature = 'is_feature',
  /** column name */
  LearningPathId = 'learning_path_id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  Level = 'level',
  /** column name */
  LevelCourse = 'level_course',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Lesson_Course_View_Stddev_Fields = {
  __typename?: 'lesson_course_view_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Stddev_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Course_View_Stddev_Pop_Fields = {
  __typename?: 'lesson_course_view_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Stddev_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Course_View_Stddev_Samp_Fields = {
  __typename?: 'lesson_course_view_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Stddev_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Course_View_Sum_Fields = {
  __typename?: 'lesson_course_view_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
  level_course?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Sum_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Lesson_Course_View_Var_Pop_Fields = {
  __typename?: 'lesson_course_view_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Var_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Course_View_Var_Samp_Fields = {
  __typename?: 'lesson_course_view_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Var_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Course_View_Variance_Fields = {
  __typename?: 'lesson_course_view_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  level_course?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_course_view" */
export type Lesson_Course_View_Variance_Order_By = {
  author_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  level_course?: InputMaybe<Order_By>;
};

/** columns and relationships of "lesson_document_map" */
export type Lesson_Document_Map = {
  __typename?: 'lesson_document_map';
  /** An object relationship */
  document?: Maybe<Document>;
  document_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  lesson?: Maybe<Lesson>;
  lesson_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "lesson_document_map" */
export type Lesson_Document_Map_Aggregate = {
  __typename?: 'lesson_document_map_aggregate';
  aggregate?: Maybe<Lesson_Document_Map_Aggregate_Fields>;
  nodes: Array<Lesson_Document_Map>;
};

/** aggregate fields of "lesson_document_map" */
export type Lesson_Document_Map_Aggregate_Fields = {
  __typename?: 'lesson_document_map_aggregate_fields';
  avg?: Maybe<Lesson_Document_Map_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Lesson_Document_Map_Max_Fields>;
  min?: Maybe<Lesson_Document_Map_Min_Fields>;
  stddev?: Maybe<Lesson_Document_Map_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Document_Map_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Document_Map_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Document_Map_Sum_Fields>;
  var_pop?: Maybe<Lesson_Document_Map_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Document_Map_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Document_Map_Variance_Fields>;
};


/** aggregate fields of "lesson_document_map" */
export type Lesson_Document_Map_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_document_map" */
export type Lesson_Document_Map_Aggregate_Order_By = {
  avg?: InputMaybe<Lesson_Document_Map_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lesson_Document_Map_Max_Order_By>;
  min?: InputMaybe<Lesson_Document_Map_Min_Order_By>;
  stddev?: InputMaybe<Lesson_Document_Map_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lesson_Document_Map_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lesson_Document_Map_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lesson_Document_Map_Sum_Order_By>;
  var_pop?: InputMaybe<Lesson_Document_Map_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lesson_Document_Map_Var_Samp_Order_By>;
  variance?: InputMaybe<Lesson_Document_Map_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Lesson_Document_Map_Avg_Fields = {
  __typename?: 'lesson_document_map_avg_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Avg_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_document_map". All fields are combined with a logical 'AND'. */
export type Lesson_Document_Map_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Lesson_Document_Map_Bool_Exp>>>;
  _not?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Lesson_Document_Map_Bool_Exp>>>;
  document?: InputMaybe<Document_Bool_Exp>;
  document_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lesson?: InputMaybe<Lesson_Bool_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Lesson_Document_Map_Max_Fields = {
  __typename?: 'lesson_document_map_max_fields';
  document_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Max_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Document_Map_Min_Fields = {
  __typename?: 'lesson_document_map_min_fields';
  document_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Min_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "lesson_document_map" */
export type Lesson_Document_Map_Order_By = {
  document?: InputMaybe<Document_Order_By>;
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson?: InputMaybe<Lesson_Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "lesson_document_map" */
export type Lesson_Document_Map_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "lesson_document_map" */
export enum Lesson_Document_Map_Select_Column {
  /** column name */
  DocumentId = 'document_id',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id'
}

/** aggregate stddev on columns */
export type Lesson_Document_Map_Stddev_Fields = {
  __typename?: 'lesson_document_map_stddev_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Stddev_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Document_Map_Stddev_Pop_Fields = {
  __typename?: 'lesson_document_map_stddev_pop_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Stddev_Pop_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Document_Map_Stddev_Samp_Fields = {
  __typename?: 'lesson_document_map_stddev_samp_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Stddev_Samp_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Document_Map_Sum_Fields = {
  __typename?: 'lesson_document_map_sum_fields';
  document_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Sum_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Lesson_Document_Map_Var_Pop_Fields = {
  __typename?: 'lesson_document_map_var_pop_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Var_Pop_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Document_Map_Var_Samp_Fields = {
  __typename?: 'lesson_document_map_var_samp_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Var_Samp_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Document_Map_Variance_Fields = {
  __typename?: 'lesson_document_map_variance_fields';
  document_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_document_map" */
export type Lesson_Document_Map_Variance_Order_By = {
  document_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Lesson_Max_Fields = {
  __typename?: 'lesson_max_fields';
  avg_point?: Maybe<Scalars['float8']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dislike_amount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lesson_category_id?: Maybe<Scalars['Int']>;
  lesson_classify_id?: Maybe<Scalars['uuid']>;
  like_amount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_file?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['numeric']>;
  static_video_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "lesson" */
export type Lesson_Max_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  lesson_classify_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  static_video_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Min_Fields = {
  __typename?: 'lesson_min_fields';
  avg_point?: Maybe<Scalars['float8']>;
  content?: Maybe<Scalars['String']>;
  course_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dislike_amount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lesson_category_id?: Maybe<Scalars['Int']>;
  lesson_classify_id?: Maybe<Scalars['uuid']>;
  like_amount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_file?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['numeric']>;
  static_video_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "lesson" */
export type Lesson_Min_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  lesson_classify_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  static_video_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "lesson" */
export type Lesson_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  course?: InputMaybe<Course_Order_By>;
  course_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  is_highlight?: InputMaybe<Order_By>;
  is_highlight_on_category?: InputMaybe<Order_By>;
  learning_processes_aggregate?: InputMaybe<Learning_Process_Aggregate_Order_By>;
  lesson_category?: InputMaybe<Lesson_Category_Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  lesson_classify?: InputMaybe<Lesson_Classify_Order_By>;
  lesson_classify_id?: InputMaybe<Order_By>;
  lesson_comment_webs_aggregate?: InputMaybe<Lesson_Comment_Web_Aggregate_Order_By>;
  lesson_courses_aggregate?: InputMaybe<Lesson_Course_Aggregate_Order_By>;
  lesson_document_maps_aggregate?: InputMaybe<Lesson_Document_Map_Aggregate_Order_By>;
  lesson_ratings_aggregate?: InputMaybe<Lesson_Rating_Aggregate_Order_By>;
  like_amount?: InputMaybe<Order_By>;
  like_counts_aggregate?: InputMaybe<Like_Count_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  rating?: InputMaybe<Order_By>;
  static_video_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "lesson" */
export type Lesson_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "lesson_rating" */
export type Lesson_Rating = {
  __typename?: 'lesson_rating';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  lesson: Lesson;
  lesson_id: Scalars['Int'];
  rating_type?: Maybe<Scalars['String']>;
  score: Scalars['numeric'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};

/** aggregated selection of "lesson_rating" */
export type Lesson_Rating_Aggregate = {
  __typename?: 'lesson_rating_aggregate';
  aggregate?: Maybe<Lesson_Rating_Aggregate_Fields>;
  nodes: Array<Lesson_Rating>;
};

/** aggregate fields of "lesson_rating" */
export type Lesson_Rating_Aggregate_Fields = {
  __typename?: 'lesson_rating_aggregate_fields';
  avg?: Maybe<Lesson_Rating_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Lesson_Rating_Max_Fields>;
  min?: Maybe<Lesson_Rating_Min_Fields>;
  stddev?: Maybe<Lesson_Rating_Stddev_Fields>;
  stddev_pop?: Maybe<Lesson_Rating_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Lesson_Rating_Stddev_Samp_Fields>;
  sum?: Maybe<Lesson_Rating_Sum_Fields>;
  var_pop?: Maybe<Lesson_Rating_Var_Pop_Fields>;
  var_samp?: Maybe<Lesson_Rating_Var_Samp_Fields>;
  variance?: Maybe<Lesson_Rating_Variance_Fields>;
};


/** aggregate fields of "lesson_rating" */
export type Lesson_Rating_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lesson_Rating_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lesson_rating" */
export type Lesson_Rating_Aggregate_Order_By = {
  avg?: InputMaybe<Lesson_Rating_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lesson_Rating_Max_Order_By>;
  min?: InputMaybe<Lesson_Rating_Min_Order_By>;
  stddev?: InputMaybe<Lesson_Rating_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Lesson_Rating_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Lesson_Rating_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Lesson_Rating_Sum_Order_By>;
  var_pop?: InputMaybe<Lesson_Rating_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Lesson_Rating_Var_Samp_Order_By>;
  variance?: InputMaybe<Lesson_Rating_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Lesson_Rating_Avg_Fields = {
  __typename?: 'lesson_rating_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "lesson_rating" */
export type Lesson_Rating_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "lesson_rating". All fields are combined with a logical 'AND'. */
export type Lesson_Rating_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Lesson_Rating_Bool_Exp>>>;
  _not?: InputMaybe<Lesson_Rating_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Lesson_Rating_Bool_Exp>>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lesson?: InputMaybe<Lesson_Bool_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
  rating_type?: InputMaybe<String_Comparison_Exp>;
  score?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Lesson_Rating_Max_Fields = {
  __typename?: 'lesson_rating_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
  rating_type?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "lesson_rating" */
export type Lesson_Rating_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  rating_type?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lesson_Rating_Min_Fields = {
  __typename?: 'lesson_rating_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
  rating_type?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "lesson_rating" */
export type Lesson_Rating_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  rating_type?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "lesson_rating" */
export type Lesson_Rating_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson?: InputMaybe<Lesson_Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  rating_type?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "lesson_rating" */
export type Lesson_Rating_Pk_Columns_Input = {
  lesson_id: Scalars['Int'];
  user_id: Scalars['String'];
};

/** select columns of table "lesson_rating" */
export enum Lesson_Rating_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  RatingType = 'rating_type',
  /** column name */
  Score = 'score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Lesson_Rating_Stddev_Fields = {
  __typename?: 'lesson_rating_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson_rating" */
export type Lesson_Rating_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Rating_Stddev_Pop_Fields = {
  __typename?: 'lesson_rating_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson_rating" */
export type Lesson_Rating_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Rating_Stddev_Samp_Fields = {
  __typename?: 'lesson_rating_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson_rating" */
export type Lesson_Rating_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Rating_Sum_Fields = {
  __typename?: 'lesson_rating_sum_fields';
  id?: Maybe<Scalars['Int']>;
  lesson_id?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "lesson_rating" */
export type Lesson_Rating_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Lesson_Rating_Var_Pop_Fields = {
  __typename?: 'lesson_rating_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson_rating" */
export type Lesson_Rating_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Rating_Var_Samp_Fields = {
  __typename?: 'lesson_rating_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson_rating" */
export type Lesson_Rating_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Rating_Variance_Fields = {
  __typename?: 'lesson_rating_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lesson_id?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson_rating" */
export type Lesson_Rating_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  score?: InputMaybe<Order_By>;
};

/** select columns of table "lesson" */
export enum Lesson_Select_Column {
  /** column name */
  AvgPoint = 'avg_point',
  /** column name */
  Content = 'content',
  /** column name */
  CourseId = 'course_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DislikeAmount = 'dislike_amount',
  /** column name */
  Duration = 'duration',
  /** column name */
  FakeViews = 'fake_views',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  IsHighlight = 'is_highlight',
  /** column name */
  IsHighlightOnCategory = 'is_highlight_on_category',
  /** column name */
  LessonCategoryId = 'lesson_category_id',
  /** column name */
  LessonClassifyId = 'lesson_classify_id',
  /** column name */
  LikeAmount = 'like_amount',
  /** column name */
  Name = 'name',
  /** column name */
  NameFile = 'name_file',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  Rating = 'rating',
  /** column name */
  StaticVideoUrl = 'static_video_url',
  /** column name */
  Status = 'status',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VideoUrl = 'video_url',
  /** column name */
  Views = 'views'
}

/** aggregate stddev on columns */
export type Lesson_Stddev_Fields = {
  __typename?: 'lesson_stddev_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  dislike_amount?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  like_amount?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "lesson" */
export type Lesson_Stddev_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Lesson_Stddev_Pop_Fields = {
  __typename?: 'lesson_stddev_pop_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  dislike_amount?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  like_amount?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "lesson" */
export type Lesson_Stddev_Pop_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Lesson_Stddev_Samp_Fields = {
  __typename?: 'lesson_stddev_samp_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  dislike_amount?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  like_amount?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "lesson" */
export type Lesson_Stddev_Samp_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Lesson_Sum_Fields = {
  __typename?: 'lesson_sum_fields';
  avg_point?: Maybe<Scalars['float8']>;
  course_id?: Maybe<Scalars['Int']>;
  dislike_amount?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  lesson_category_id?: Maybe<Scalars['Int']>;
  like_amount?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['numeric']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "lesson" */
export type Lesson_Sum_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Lesson_Var_Pop_Fields = {
  __typename?: 'lesson_var_pop_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  dislike_amount?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  like_amount?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "lesson" */
export type Lesson_Var_Pop_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Lesson_Var_Samp_Fields = {
  __typename?: 'lesson_var_samp_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  dislike_amount?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  like_amount?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "lesson" */
export type Lesson_Var_Samp_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Lesson_Variance_Fields = {
  __typename?: 'lesson_variance_fields';
  avg_point?: Maybe<Scalars['Float']>;
  course_id?: Maybe<Scalars['Float']>;
  dislike_amount?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  lesson_category_id?: Maybe<Scalars['Float']>;
  like_amount?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "lesson" */
export type Lesson_Variance_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  course_id?: InputMaybe<Order_By>;
  dislike_amount?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  lesson_category_id?: InputMaybe<Order_By>;
  like_amount?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** columns and relationships of "level_course" */
export type Level_Course = {
  __typename?: 'level_course';
  /** An array relationship */
  courses: Array<Course>;
  /** An aggregated array relationship */
  courses_aggregate: Course_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An object relationship */
  profile?: Maybe<Profile>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "level_course" */
export type Level_CourseCoursesArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** columns and relationships of "level_course" */
export type Level_CourseCourses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};

/** aggregated selection of "level_course" */
export type Level_Course_Aggregate = {
  __typename?: 'level_course_aggregate';
  aggregate?: Maybe<Level_Course_Aggregate_Fields>;
  nodes: Array<Level_Course>;
};

/** aggregate fields of "level_course" */
export type Level_Course_Aggregate_Fields = {
  __typename?: 'level_course_aggregate_fields';
  avg?: Maybe<Level_Course_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Level_Course_Max_Fields>;
  min?: Maybe<Level_Course_Min_Fields>;
  stddev?: Maybe<Level_Course_Stddev_Fields>;
  stddev_pop?: Maybe<Level_Course_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Level_Course_Stddev_Samp_Fields>;
  sum?: Maybe<Level_Course_Sum_Fields>;
  var_pop?: Maybe<Level_Course_Var_Pop_Fields>;
  var_samp?: Maybe<Level_Course_Var_Samp_Fields>;
  variance?: Maybe<Level_Course_Variance_Fields>;
};


/** aggregate fields of "level_course" */
export type Level_Course_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Level_Course_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "level_course" */
export type Level_Course_Aggregate_Order_By = {
  avg?: InputMaybe<Level_Course_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Level_Course_Max_Order_By>;
  min?: InputMaybe<Level_Course_Min_Order_By>;
  stddev?: InputMaybe<Level_Course_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Level_Course_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Level_Course_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Level_Course_Sum_Order_By>;
  var_pop?: InputMaybe<Level_Course_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Level_Course_Var_Samp_Order_By>;
  variance?: InputMaybe<Level_Course_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Level_Course_Avg_Fields = {
  __typename?: 'level_course_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "level_course" */
export type Level_Course_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "level_course". All fields are combined with a logical 'AND'. */
export type Level_Course_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Level_Course_Bool_Exp>>>;
  _not?: InputMaybe<Level_Course_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Level_Course_Bool_Exp>>>;
  courses?: InputMaybe<Course_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Level_Course_Max_Fields = {
  __typename?: 'level_course_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "level_course" */
export type Level_Course_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Level_Course_Min_Fields = {
  __typename?: 'level_course_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "level_course" */
export type Level_Course_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "level_course" */
export type Level_Course_Order_By = {
  courses_aggregate?: InputMaybe<Course_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "level_course" */
export type Level_Course_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "level_course" */
export enum Level_Course_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Level_Course_Stddev_Fields = {
  __typename?: 'level_course_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "level_course" */
export type Level_Course_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Level_Course_Stddev_Pop_Fields = {
  __typename?: 'level_course_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "level_course" */
export type Level_Course_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Level_Course_Stddev_Samp_Fields = {
  __typename?: 'level_course_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "level_course" */
export type Level_Course_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Level_Course_Sum_Fields = {
  __typename?: 'level_course_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "level_course" */
export type Level_Course_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Level_Course_Var_Pop_Fields = {
  __typename?: 'level_course_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "level_course" */
export type Level_Course_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Level_Course_Var_Samp_Fields = {
  __typename?: 'level_course_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "level_course" */
export type Level_Course_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Level_Course_Variance_Fields = {
  __typename?: 'level_course_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "level_course" */
export type Level_Course_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/**
 * a collection of like count for each video
 *
 *
 * columns and relationships of "like_count"
 *
 */
export type Like_Count = {
  __typename?: 'like_count';
  browser_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_dislike: Scalars['Boolean'];
  is_like: Scalars['Boolean'];
  /** An object relationship */
  lesson: Lesson;
  lesson_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "like_count" */
export type Like_Count_Aggregate = {
  __typename?: 'like_count_aggregate';
  aggregate?: Maybe<Like_Count_Aggregate_Fields>;
  nodes: Array<Like_Count>;
};

/** aggregate fields of "like_count" */
export type Like_Count_Aggregate_Fields = {
  __typename?: 'like_count_aggregate_fields';
  avg?: Maybe<Like_Count_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Like_Count_Max_Fields>;
  min?: Maybe<Like_Count_Min_Fields>;
  stddev?: Maybe<Like_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Like_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Like_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Like_Count_Sum_Fields>;
  var_pop?: Maybe<Like_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Like_Count_Var_Samp_Fields>;
  variance?: Maybe<Like_Count_Variance_Fields>;
};


/** aggregate fields of "like_count" */
export type Like_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Like_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "like_count" */
export type Like_Count_Aggregate_Order_By = {
  avg?: InputMaybe<Like_Count_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Like_Count_Max_Order_By>;
  min?: InputMaybe<Like_Count_Min_Order_By>;
  stddev?: InputMaybe<Like_Count_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Like_Count_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Like_Count_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Like_Count_Sum_Order_By>;
  var_pop?: InputMaybe<Like_Count_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Like_Count_Var_Samp_Order_By>;
  variance?: InputMaybe<Like_Count_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "like_count" */
export type Like_Count_Arr_Rel_Insert_Input = {
  data: Array<Like_Count_Insert_Input>;
  on_conflict?: InputMaybe<Like_Count_On_Conflict>;
};

/** aggregate avg on columns */
export type Like_Count_Avg_Fields = {
  __typename?: 'like_count_avg_fields';
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "like_count" */
export type Like_Count_Avg_Order_By = {
  lesson_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "like_count". All fields are combined with a logical 'AND'. */
export type Like_Count_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Like_Count_Bool_Exp>>>;
  _not?: InputMaybe<Like_Count_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Like_Count_Bool_Exp>>>;
  browser_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_dislike?: InputMaybe<Boolean_Comparison_Exp>;
  is_like?: InputMaybe<Boolean_Comparison_Exp>;
  lesson?: InputMaybe<Lesson_Bool_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "like_count" */
export enum Like_Count_Constraint {
  /** unique or primary key constraint */
  LikeCountPkey = 'like_count_pkey',
  /** unique or primary key constraint */
  LikeCountProfileIdLessonIdBrowserIdKey = 'like_count_profile_id_lesson_id_browser_id_key'
}

/** input type for inserting data into table "like_count" */
export type Like_Count_Insert_Input = {
  browser_id?: InputMaybe<Scalars['String']>;
  is_dislike?: InputMaybe<Scalars['Boolean']>;
  is_like?: InputMaybe<Scalars['Boolean']>;
  lesson_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Like_Count_Max_Fields = {
  __typename?: 'like_count_max_fields';
  browser_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "like_count" */
export type Like_Count_Max_Order_By = {
  browser_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Like_Count_Min_Fields = {
  __typename?: 'like_count_min_fields';
  browser_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  lesson_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "like_count" */
export type Like_Count_Min_Order_By = {
  browser_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "like_count" */
export type Like_Count_Mutation_Response = {
  __typename?: 'like_count_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Like_Count>;
};

/** input type for inserting object relation for remote table "like_count" */
export type Like_Count_Obj_Rel_Insert_Input = {
  data: Like_Count_Insert_Input;
  on_conflict?: InputMaybe<Like_Count_On_Conflict>;
};

/** on conflict condition type for table "like_count" */
export type Like_Count_On_Conflict = {
  constraint: Like_Count_Constraint;
  update_columns: Array<Like_Count_Update_Column>;
  where?: InputMaybe<Like_Count_Bool_Exp>;
};

/** ordering options when selecting data from "like_count" */
export type Like_Count_Order_By = {
  browser_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_dislike?: InputMaybe<Order_By>;
  is_like?: InputMaybe<Order_By>;
  lesson?: InputMaybe<Lesson_Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "like_count" */
export type Like_Count_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "like_count" */
export enum Like_Count_Select_Column {
  /** column name */
  BrowserId = 'browser_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDislike = 'is_dislike',
  /** column name */
  IsLike = 'is_like',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "like_count" */
export type Like_Count_Set_Input = {
  is_dislike?: InputMaybe<Scalars['Boolean']>;
  is_like?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Like_Count_Stddev_Fields = {
  __typename?: 'like_count_stddev_fields';
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "like_count" */
export type Like_Count_Stddev_Order_By = {
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Like_Count_Stddev_Pop_Fields = {
  __typename?: 'like_count_stddev_pop_fields';
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "like_count" */
export type Like_Count_Stddev_Pop_Order_By = {
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Like_Count_Stddev_Samp_Fields = {
  __typename?: 'like_count_stddev_samp_fields';
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "like_count" */
export type Like_Count_Stddev_Samp_Order_By = {
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Like_Count_Sum_Fields = {
  __typename?: 'like_count_sum_fields';
  lesson_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "like_count" */
export type Like_Count_Sum_Order_By = {
  lesson_id?: InputMaybe<Order_By>;
};

/** update columns of table "like_count" */
export enum Like_Count_Update_Column {
  /** column name */
  IsDislike = 'is_dislike',
  /** column name */
  IsLike = 'is_like'
}

/** aggregate var_pop on columns */
export type Like_Count_Var_Pop_Fields = {
  __typename?: 'like_count_var_pop_fields';
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "like_count" */
export type Like_Count_Var_Pop_Order_By = {
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Like_Count_Var_Samp_Fields = {
  __typename?: 'like_count_var_samp_fields';
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "like_count" */
export type Like_Count_Var_Samp_Order_By = {
  lesson_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Like_Count_Variance_Fields = {
  __typename?: 'like_count_variance_fields';
  lesson_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "like_count" */
export type Like_Count_Variance_Order_By = {
  lesson_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "magazine" */
export type Magazine = {
  __typename?: 'magazine';
  avg_point?: Maybe<Scalars['float8']>;
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  index?: Maybe<Scalars['Int']>;
  insight_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  insights: Array<Insight>;
  /** An array relationship */
  magazine_comment_webs: Array<Magazine_Comment_Web>;
  /** An aggregated array relationship */
  magazine_comment_webs_aggregate: Magazine_Comment_Web_Aggregate;
  name_pdf?: Maybe<Scalars['String']>;
  name_thumbnail?: Maybe<Scalars['String']>;
  /** A computed field, executes function "pdf_link_magazine" */
  pdf_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  profileByUpdatedBy?: Maybe<Profile>;
  public_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  review_magazines: Array<Review_Magazine>;
  /** An aggregated array relationship */
  review_magazines_aggregate: Review_Magazine_Aggregate;
  slug?: Maybe<Scalars['String']>;
  table_of_contents?: Maybe<Scalars['jsonb']>;
  thumbnail?: Maybe<Scalars['String']>;
  /** A computed field, executes function "thumbnail_link_magazine" */
  thumbnail_link?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "magazine" */
export type MagazineInsightsArgs = {
  distinct_on?: InputMaybe<Array<Insight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insight_Order_By>>;
  where?: InputMaybe<Insight_Bool_Exp>;
};


/** columns and relationships of "magazine" */
export type MagazineMagazine_Comment_WebsArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Comment_Web_Order_By>>;
  where?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
};


/** columns and relationships of "magazine" */
export type MagazineMagazine_Comment_Webs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Comment_Web_Order_By>>;
  where?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
};


/** columns and relationships of "magazine" */
export type MagazineReview_MagazinesArgs = {
  distinct_on?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Magazine_Order_By>>;
  where?: InputMaybe<Review_Magazine_Bool_Exp>;
};


/** columns and relationships of "magazine" */
export type MagazineReview_Magazines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Magazine_Order_By>>;
  where?: InputMaybe<Review_Magazine_Bool_Exp>;
};


/** columns and relationships of "magazine" */
export type MagazineTable_Of_ContentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "magazine" */
export type Magazine_Aggregate = {
  __typename?: 'magazine_aggregate';
  aggregate?: Maybe<Magazine_Aggregate_Fields>;
  nodes: Array<Magazine>;
};

/** aggregate fields of "magazine" */
export type Magazine_Aggregate_Fields = {
  __typename?: 'magazine_aggregate_fields';
  avg?: Maybe<Magazine_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Magazine_Max_Fields>;
  min?: Maybe<Magazine_Min_Fields>;
  stddev?: Maybe<Magazine_Stddev_Fields>;
  stddev_pop?: Maybe<Magazine_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Magazine_Stddev_Samp_Fields>;
  sum?: Maybe<Magazine_Sum_Fields>;
  var_pop?: Maybe<Magazine_Var_Pop_Fields>;
  var_samp?: Maybe<Magazine_Var_Samp_Fields>;
  variance?: Maybe<Magazine_Variance_Fields>;
};


/** aggregate fields of "magazine" */
export type Magazine_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Magazine_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "magazine" */
export type Magazine_Aggregate_Order_By = {
  avg?: InputMaybe<Magazine_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Magazine_Max_Order_By>;
  min?: InputMaybe<Magazine_Min_Order_By>;
  stddev?: InputMaybe<Magazine_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Magazine_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Magazine_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Magazine_Sum_Order_By>;
  var_pop?: InputMaybe<Magazine_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Magazine_Var_Samp_Order_By>;
  variance?: InputMaybe<Magazine_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Magazine_Avg_Fields = {
  __typename?: 'magazine_avg_fields';
  avg_point?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  insight_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "magazine" */
export type Magazine_Avg_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "magazine". All fields are combined with a logical 'AND'. */
export type Magazine_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Magazine_Bool_Exp>>>;
  _not?: InputMaybe<Magazine_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Magazine_Bool_Exp>>>;
  avg_point?: InputMaybe<Float8_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  index?: InputMaybe<Int_Comparison_Exp>;
  insight_id?: InputMaybe<Int_Comparison_Exp>;
  insights?: InputMaybe<Insight_Bool_Exp>;
  magazine_comment_webs?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
  name_pdf?: InputMaybe<String_Comparison_Exp>;
  name_thumbnail?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profileByUpdatedBy?: InputMaybe<Profile_Bool_Exp>;
  public_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  review_magazines?: InputMaybe<Review_Magazine_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  table_of_contents?: InputMaybe<Jsonb_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** columns and relationships of "magazine_comment_web" */
export type Magazine_Comment_Web = {
  __typename?: 'magazine_comment_web';
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  magazine?: Maybe<Magazine>;
  magazine_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  seeder?: Maybe<Seeder>;
  user_email: Scalars['String'];
  user_name: Scalars['String'];
};

/** aggregated selection of "magazine_comment_web" */
export type Magazine_Comment_Web_Aggregate = {
  __typename?: 'magazine_comment_web_aggregate';
  aggregate?: Maybe<Magazine_Comment_Web_Aggregate_Fields>;
  nodes: Array<Magazine_Comment_Web>;
};

/** aggregate fields of "magazine_comment_web" */
export type Magazine_Comment_Web_Aggregate_Fields = {
  __typename?: 'magazine_comment_web_aggregate_fields';
  avg?: Maybe<Magazine_Comment_Web_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Magazine_Comment_Web_Max_Fields>;
  min?: Maybe<Magazine_Comment_Web_Min_Fields>;
  stddev?: Maybe<Magazine_Comment_Web_Stddev_Fields>;
  stddev_pop?: Maybe<Magazine_Comment_Web_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Magazine_Comment_Web_Stddev_Samp_Fields>;
  sum?: Maybe<Magazine_Comment_Web_Sum_Fields>;
  var_pop?: Maybe<Magazine_Comment_Web_Var_Pop_Fields>;
  var_samp?: Maybe<Magazine_Comment_Web_Var_Samp_Fields>;
  variance?: Maybe<Magazine_Comment_Web_Variance_Fields>;
};


/** aggregate fields of "magazine_comment_web" */
export type Magazine_Comment_Web_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Magazine_Comment_Web_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "magazine_comment_web" */
export type Magazine_Comment_Web_Aggregate_Order_By = {
  avg?: InputMaybe<Magazine_Comment_Web_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Magazine_Comment_Web_Max_Order_By>;
  min?: InputMaybe<Magazine_Comment_Web_Min_Order_By>;
  stddev?: InputMaybe<Magazine_Comment_Web_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Magazine_Comment_Web_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Magazine_Comment_Web_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Magazine_Comment_Web_Sum_Order_By>;
  var_pop?: InputMaybe<Magazine_Comment_Web_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Magazine_Comment_Web_Var_Samp_Order_By>;
  variance?: InputMaybe<Magazine_Comment_Web_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "magazine_comment_web" */
export type Magazine_Comment_Web_Arr_Rel_Insert_Input = {
  data: Array<Magazine_Comment_Web_Insert_Input>;
};

/** aggregate avg on columns */
export type Magazine_Comment_Web_Avg_Fields = {
  __typename?: 'magazine_comment_web_avg_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "magazine_comment_web". All fields are combined with a logical 'AND'. */
export type Magazine_Comment_Web_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Magazine_Comment_Web_Bool_Exp>>>;
  _not?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Magazine_Comment_Web_Bool_Exp>>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  magazine?: InputMaybe<Magazine_Bool_Exp>;
  magazine_id?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  seeder?: InputMaybe<Seeder_Bool_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
  user_name?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "magazine_comment_web" */
export type Magazine_Comment_Web_Insert_Input = {
  content?: InputMaybe<Scalars['String']>;
  magazine_id?: InputMaybe<Scalars['Int']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Magazine_Comment_Web_Max_Fields = {
  __typename?: 'magazine_comment_web_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  magazine_id?: Maybe<Scalars['Int']>;
  user_email?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Magazine_Comment_Web_Min_Fields = {
  __typename?: 'magazine_comment_web_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  magazine_id?: Maybe<Scalars['Int']>;
  user_email?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "magazine_comment_web" */
export type Magazine_Comment_Web_Mutation_Response = {
  __typename?: 'magazine_comment_web_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Magazine_Comment_Web>;
};

/** input type for inserting object relation for remote table "magazine_comment_web" */
export type Magazine_Comment_Web_Obj_Rel_Insert_Input = {
  data: Magazine_Comment_Web_Insert_Input;
};

/** ordering options when selecting data from "magazine_comment_web" */
export type Magazine_Comment_Web_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  magazine?: InputMaybe<Magazine_Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  seeder?: InputMaybe<Seeder_Order_By>;
  user_email?: InputMaybe<Order_By>;
  user_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "magazine_comment_web" */
export type Magazine_Comment_Web_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "magazine_comment_web" */
export enum Magazine_Comment_Web_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MagazineId = 'magazine_id',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserName = 'user_name'
}

/** aggregate stddev on columns */
export type Magazine_Comment_Web_Stddev_Fields = {
  __typename?: 'magazine_comment_web_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Magazine_Comment_Web_Stddev_Pop_Fields = {
  __typename?: 'magazine_comment_web_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Magazine_Comment_Web_Stddev_Samp_Fields = {
  __typename?: 'magazine_comment_web_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Magazine_Comment_Web_Sum_Fields = {
  __typename?: 'magazine_comment_web_sum_fields';
  id?: Maybe<Scalars['Int']>;
  magazine_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Magazine_Comment_Web_Var_Pop_Fields = {
  __typename?: 'magazine_comment_web_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Magazine_Comment_Web_Var_Samp_Fields = {
  __typename?: 'magazine_comment_web_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Magazine_Comment_Web_Variance_Fields = {
  __typename?: 'magazine_comment_web_variance_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "magazine_comment_web" */
export type Magazine_Comment_Web_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Magazine_Max_Fields = {
  __typename?: 'magazine_max_fields';
  avg_point?: Maybe<Scalars['float8']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  insight_id?: Maybe<Scalars['Int']>;
  name_pdf?: Maybe<Scalars['String']>;
  name_thumbnail?: Maybe<Scalars['String']>;
  public_at?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "magazine" */
export type Magazine_Max_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
  name_pdf?: InputMaybe<Order_By>;
  name_thumbnail?: InputMaybe<Order_By>;
  public_at?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Magazine_Min_Fields = {
  __typename?: 'magazine_min_fields';
  avg_point?: Maybe<Scalars['float8']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  insight_id?: Maybe<Scalars['Int']>;
  name_pdf?: Maybe<Scalars['String']>;
  name_thumbnail?: Maybe<Scalars['String']>;
  public_at?: Maybe<Scalars['timestamptz']>;
  slug?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "magazine" */
export type Magazine_Min_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
  name_pdf?: InputMaybe<Order_By>;
  name_thumbnail?: InputMaybe<Order_By>;
  public_at?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "magazine" */
export type Magazine_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
  magazine_comment_webs_aggregate?: InputMaybe<Magazine_Comment_Web_Aggregate_Order_By>;
  name_pdf?: InputMaybe<Order_By>;
  name_thumbnail?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profileByUpdatedBy?: InputMaybe<Profile_Order_By>;
  public_at?: InputMaybe<Order_By>;
  review_magazines_aggregate?: InputMaybe<Review_Magazine_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  table_of_contents?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "magazine" */
export type Magazine_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "magazine" */
export enum Magazine_Select_Column {
  /** column name */
  AvgPoint = 'avg_point',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  InsightId = 'insight_id',
  /** column name */
  NamePdf = 'name_pdf',
  /** column name */
  NameThumbnail = 'name_thumbnail',
  /** column name */
  PublicAt = 'public_at',
  /** column name */
  Slug = 'slug',
  /** column name */
  TableOfContents = 'table_of_contents',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Uuid = 'uuid'
}

/** aggregate stddev on columns */
export type Magazine_Stddev_Fields = {
  __typename?: 'magazine_stddev_fields';
  avg_point?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  insight_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "magazine" */
export type Magazine_Stddev_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Magazine_Stddev_Pop_Fields = {
  __typename?: 'magazine_stddev_pop_fields';
  avg_point?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  insight_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "magazine" */
export type Magazine_Stddev_Pop_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Magazine_Stddev_Samp_Fields = {
  __typename?: 'magazine_stddev_samp_fields';
  avg_point?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  insight_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "magazine" */
export type Magazine_Stddev_Samp_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Magazine_Sum_Fields = {
  __typename?: 'magazine_sum_fields';
  avg_point?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  insight_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "magazine" */
export type Magazine_Sum_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Magazine_Var_Pop_Fields = {
  __typename?: 'magazine_var_pop_fields';
  avg_point?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  insight_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "magazine" */
export type Magazine_Var_Pop_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Magazine_Var_Samp_Fields = {
  __typename?: 'magazine_var_samp_fields';
  avg_point?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  insight_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "magazine" */
export type Magazine_Var_Samp_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Magazine_Variance_Fields = {
  __typename?: 'magazine_variance_fields';
  avg_point?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  index?: Maybe<Scalars['Float']>;
  insight_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "magazine" */
export type Magazine_Variance_Order_By = {
  avg_point?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  index?: InputMaybe<Order_By>;
  insight_id?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** perform the action: "addReviewWebinar" */
  addReviewWebinar?: Maybe<AddReviewWebnarOutput>;
  /** delete data from the table: "knowledge_action" */
  delete_knowledge_action?: Maybe<Knowledge_Action_Mutation_Response>;
  /** delete single row from the table: "knowledge_action" */
  delete_knowledge_action_by_pk?: Maybe<Knowledge_Action>;
  /** perform the action: "forgotPassword" */
  forgotPassword?: Maybe<RegistrationOutput>;
  /** insert data into the table: "comment_exchange" */
  insert_comment_exchange?: Maybe<Comment_Exchange_Mutation_Response>;
  /** insert a single row into the table: "comment_exchange" */
  insert_comment_exchange_one?: Maybe<Comment_Exchange>;
  /** insert data into the table: "contact_form" */
  insert_contact_form?: Maybe<Contact_Form_Mutation_Response>;
  /** insert data into the table: "course_rating" */
  insert_course_rating?: Maybe<Course_Rating_Mutation_Response>;
  /** insert a single row into the table: "course_rating" */
  insert_course_rating_one?: Maybe<Course_Rating>;
  /** insert data into the table: "knowledge_action" */
  insert_knowledge_action?: Maybe<Knowledge_Action_Mutation_Response>;
  /** insert a single row into the table: "knowledge_action" */
  insert_knowledge_action_one?: Maybe<Knowledge_Action>;
  /** insert data into the table: "lesson_comment_web" */
  insert_lesson_comment_web?: Maybe<Lesson_Comment_Web_Mutation_Response>;
  /** insert a single row into the table: "lesson_comment_web" */
  insert_lesson_comment_web_one?: Maybe<Lesson_Comment_Web>;
  /** insert data into the table: "like_count" */
  insert_like_count?: Maybe<Like_Count_Mutation_Response>;
  /** insert a single row into the table: "like_count" */
  insert_like_count_one?: Maybe<Like_Count>;
  /** insert data into the table: "magazine_comment_web" */
  insert_magazine_comment_web?: Maybe<Magazine_Comment_Web_Mutation_Response>;
  /** insert a single row into the table: "magazine_comment_web" */
  insert_magazine_comment_web_one?: Maybe<Magazine_Comment_Web>;
  /** insert data into the table: "video_watched" */
  insert_video_watched?: Maybe<Video_Watched_Mutation_Response>;
  /** insert a single row into the table: "video_watched" */
  insert_video_watched_one?: Maybe<Video_Watched>;
  /** perform the action: "onboard" */
  onboard?: Maybe<OnboardOutput>;
  /** perform the action: "register" */
  register?: Maybe<RegistrationOutput>;
  /** perform the action: "reviewLesson" */
  reviewLesson?: Maybe<ReviewLessonOutput>;
  /** perform the action: "sendVerifyEmail" */
  sendVerifyEmail?: Maybe<UserVerificationOutput>;
  /** perform the action: "toggleLike" */
  toggleLike?: Maybe<ToggleLikeOutput>;
  /** update data of the table: "blog" */
  update_blog?: Maybe<Blog_Mutation_Response>;
  /** update single row of the table: "blog" */
  update_blog_by_pk?: Maybe<Blog>;
  /** update data of the table: "comment_exchange" */
  update_comment_exchange?: Maybe<Comment_Exchange_Mutation_Response>;
  /** update single row of the table: "comment_exchange" */
  update_comment_exchange_by_pk?: Maybe<Comment_Exchange>;
  /** update data of the table: "course_rating" */
  update_course_rating?: Maybe<Course_Rating_Mutation_Response>;
  /** update single row of the table: "course_rating" */
  update_course_rating_by_pk?: Maybe<Course_Rating>;
  /** update data of the table: "knowledge_action" */
  update_knowledge_action?: Maybe<Knowledge_Action_Mutation_Response>;
  /** update single row of the table: "knowledge_action" */
  update_knowledge_action_by_pk?: Maybe<Knowledge_Action>;
  /** update data of the table: "like_count" */
  update_like_count?: Maybe<Like_Count_Mutation_Response>;
  /** update single row of the table: "like_count" */
  update_like_count_by_pk?: Maybe<Like_Count>;
  /** update data of the table: "news" */
  update_news?: Maybe<News_Mutation_Response>;
  /** update single row of the table: "news" */
  update_news_by_pk?: Maybe<News>;
  /** update data of the table: "webinars" */
  update_webinars?: Maybe<Webinars_Mutation_Response>;
  /** update single row of the table: "webinars" */
  update_webinars_by_pk?: Maybe<Webinars>;
  /** perform the action: "userReward" */
  userReward?: Maybe<Output>;
  /** perform the action: "videoWatched" */
  videoWatched?: Maybe<VideoWatchedOutput>;
};


/** mutation root */
export type Mutation_RootAddReviewWebinarArgs = {
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  point?: InputMaybe<Scalars['Float']>;
  webinar_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Knowledge_ActionArgs = {
  where: Knowledge_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Knowledge_Action_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootForgotPasswordArgs = {
  input: UserVerification;
};


/** mutation root */
export type Mutation_RootInsert_Comment_ExchangeArgs = {
  objects: Array<Comment_Exchange_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Exchange_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_Exchange_OneArgs = {
  object: Comment_Exchange_Insert_Input;
  on_conflict?: InputMaybe<Comment_Exchange_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contact_FormArgs = {
  objects: Array<Contact_Form_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Course_RatingArgs = {
  objects: Array<Course_Rating_Insert_Input>;
  on_conflict?: InputMaybe<Course_Rating_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Course_Rating_OneArgs = {
  object: Course_Rating_Insert_Input;
  on_conflict?: InputMaybe<Course_Rating_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Knowledge_ActionArgs = {
  objects: Array<Knowledge_Action_Insert_Input>;
  on_conflict?: InputMaybe<Knowledge_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Knowledge_Action_OneArgs = {
  object: Knowledge_Action_Insert_Input;
  on_conflict?: InputMaybe<Knowledge_Action_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Comment_WebArgs = {
  objects: Array<Lesson_Comment_Web_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Lesson_Comment_Web_OneArgs = {
  object: Lesson_Comment_Web_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Like_CountArgs = {
  objects: Array<Like_Count_Insert_Input>;
  on_conflict?: InputMaybe<Like_Count_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Like_Count_OneArgs = {
  object: Like_Count_Insert_Input;
  on_conflict?: InputMaybe<Like_Count_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Magazine_Comment_WebArgs = {
  objects: Array<Magazine_Comment_Web_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Magazine_Comment_Web_OneArgs = {
  object: Magazine_Comment_Web_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Video_WatchedArgs = {
  objects: Array<Video_Watched_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Video_Watched_OneArgs = {
  object: Video_Watched_Insert_Input;
};


/** mutation root */
export type Mutation_RootRegisterArgs = {
  input?: InputMaybe<UserInfo>;
};


/** mutation root */
export type Mutation_RootReviewLessonArgs = {
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  lesson_id: Scalars['Int'];
  point?: InputMaybe<Scalars['Float']>;
};


/** mutation root */
export type Mutation_RootSendVerifyEmailArgs = {
  input: UserVerification;
};


/** mutation root */
export type Mutation_RootToggleLikeArgs = {
  lessonId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootUpdate_BlogArgs = {
  _inc?: InputMaybe<Blog_Inc_Input>;
  _set?: InputMaybe<Blog_Set_Input>;
  where: Blog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_By_PkArgs = {
  _inc?: InputMaybe<Blog_Inc_Input>;
  _set?: InputMaybe<Blog_Set_Input>;
  pk_columns: Blog_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_ExchangeArgs = {
  _inc?: InputMaybe<Comment_Exchange_Inc_Input>;
  _set?: InputMaybe<Comment_Exchange_Set_Input>;
  where: Comment_Exchange_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Exchange_By_PkArgs = {
  _inc?: InputMaybe<Comment_Exchange_Inc_Input>;
  _set?: InputMaybe<Comment_Exchange_Set_Input>;
  pk_columns: Comment_Exchange_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Course_RatingArgs = {
  _inc?: InputMaybe<Course_Rating_Inc_Input>;
  _set?: InputMaybe<Course_Rating_Set_Input>;
  where: Course_Rating_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Course_Rating_By_PkArgs = {
  _inc?: InputMaybe<Course_Rating_Inc_Input>;
  _set?: InputMaybe<Course_Rating_Set_Input>;
  pk_columns: Course_Rating_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Knowledge_ActionArgs = {
  _inc?: InputMaybe<Knowledge_Action_Inc_Input>;
  _set?: InputMaybe<Knowledge_Action_Set_Input>;
  where: Knowledge_Action_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Knowledge_Action_By_PkArgs = {
  _inc?: InputMaybe<Knowledge_Action_Inc_Input>;
  _set?: InputMaybe<Knowledge_Action_Set_Input>;
  pk_columns: Knowledge_Action_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Like_CountArgs = {
  _set?: InputMaybe<Like_Count_Set_Input>;
  where: Like_Count_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Like_Count_By_PkArgs = {
  _set?: InputMaybe<Like_Count_Set_Input>;
  pk_columns: Like_Count_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NewsArgs = {
  _inc?: InputMaybe<News_Inc_Input>;
  _set?: InputMaybe<News_Set_Input>;
  where: News_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_News_By_PkArgs = {
  _inc?: InputMaybe<News_Inc_Input>;
  _set?: InputMaybe<News_Set_Input>;
  pk_columns: News_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_WebinarsArgs = {
  _inc?: InputMaybe<Webinars_Inc_Input>;
  _set?: InputMaybe<Webinars_Set_Input>;
  where: Webinars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Webinars_By_PkArgs = {
  _inc?: InputMaybe<Webinars_Inc_Input>;
  _set?: InputMaybe<Webinars_Set_Input>;
  pk_columns: Webinars_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUserRewardArgs = {
  lesson_id: Scalars['Int'];
  type: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootVideoWatchedArgs = {
  lesson_id: Scalars['Int'];
};

/** columns and relationships of "news" */
export type News = {
  __typename?: 'news';
  canonical?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['jsonb']>;
  create_at?: Maybe<Scalars['timestamptz']>;
  date: Scalars['timestamptz'];
  excerpt?: Maybe<Scalars['jsonb']>;
  fake_views?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isHot?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  /** An object relationship */
  news_category?: Maybe<News_Category>;
  noindex?: Maybe<Scalars['Boolean']>;
  normalized_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "news" */
export type NewsContentArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "news" */
export type NewsExcerptArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "news" */
export type News_Aggregate = {
  __typename?: 'news_aggregate';
  aggregate?: Maybe<News_Aggregate_Fields>;
  nodes: Array<News>;
};

/** aggregate fields of "news" */
export type News_Aggregate_Fields = {
  __typename?: 'news_aggregate_fields';
  avg?: Maybe<News_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<News_Max_Fields>;
  min?: Maybe<News_Min_Fields>;
  stddev?: Maybe<News_Stddev_Fields>;
  stddev_pop?: Maybe<News_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<News_Stddev_Samp_Fields>;
  sum?: Maybe<News_Sum_Fields>;
  var_pop?: Maybe<News_Var_Pop_Fields>;
  var_samp?: Maybe<News_Var_Samp_Fields>;
  variance?: Maybe<News_Variance_Fields>;
};


/** aggregate fields of "news" */
export type News_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<News_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "news" */
export type News_Aggregate_Order_By = {
  avg?: InputMaybe<News_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<News_Max_Order_By>;
  min?: InputMaybe<News_Min_Order_By>;
  stddev?: InputMaybe<News_Stddev_Order_By>;
  stddev_pop?: InputMaybe<News_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<News_Stddev_Samp_Order_By>;
  sum?: InputMaybe<News_Sum_Order_By>;
  var_pop?: InputMaybe<News_Var_Pop_Order_By>;
  var_samp?: InputMaybe<News_Var_Samp_Order_By>;
  variance?: InputMaybe<News_Variance_Order_By>;
};

/** aggregate avg on columns */
export type News_Avg_Fields = {
  __typename?: 'news_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "news" */
export type News_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "news". All fields are combined with a logical 'AND'. */
export type News_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<News_Bool_Exp>>>;
  _not?: InputMaybe<News_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<News_Bool_Exp>>>;
  canonical?: InputMaybe<String_Comparison_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<Jsonb_Comparison_Exp>;
  create_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  excerpt?: InputMaybe<Jsonb_Comparison_Exp>;
  fake_views?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isHot?: InputMaybe<Boolean_Comparison_Exp>;
  is_public?: InputMaybe<Boolean_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  meta_description?: InputMaybe<String_Comparison_Exp>;
  news_category?: InputMaybe<News_Category_Bool_Exp>;
  noindex?: InputMaybe<Boolean_Comparison_Exp>;
  normalized_name?: InputMaybe<String_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  update_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  views?: InputMaybe<Int_Comparison_Exp>;
};

/** columns and relationships of "news_category" */
export type News_Category = {
  __typename?: 'news_category';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  meta_description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  news: Array<News>;
  /** An aggregated array relationship */
  news_aggregate: News_Aggregate;
  position?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
};


/** columns and relationships of "news_category" */
export type News_CategoryNewsArgs = {
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** columns and relationships of "news_category" */
export type News_CategoryNews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};

/** aggregated selection of "news_category" */
export type News_Category_Aggregate = {
  __typename?: 'news_category_aggregate';
  aggregate?: Maybe<News_Category_Aggregate_Fields>;
  nodes: Array<News_Category>;
};

/** aggregate fields of "news_category" */
export type News_Category_Aggregate_Fields = {
  __typename?: 'news_category_aggregate_fields';
  avg?: Maybe<News_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<News_Category_Max_Fields>;
  min?: Maybe<News_Category_Min_Fields>;
  stddev?: Maybe<News_Category_Stddev_Fields>;
  stddev_pop?: Maybe<News_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<News_Category_Stddev_Samp_Fields>;
  sum?: Maybe<News_Category_Sum_Fields>;
  var_pop?: Maybe<News_Category_Var_Pop_Fields>;
  var_samp?: Maybe<News_Category_Var_Samp_Fields>;
  variance?: Maybe<News_Category_Variance_Fields>;
};


/** aggregate fields of "news_category" */
export type News_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<News_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "news_category" */
export type News_Category_Aggregate_Order_By = {
  avg?: InputMaybe<News_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<News_Category_Max_Order_By>;
  min?: InputMaybe<News_Category_Min_Order_By>;
  stddev?: InputMaybe<News_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<News_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<News_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<News_Category_Sum_Order_By>;
  var_pop?: InputMaybe<News_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<News_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<News_Category_Variance_Order_By>;
};

/** aggregate avg on columns */
export type News_Category_Avg_Fields = {
  __typename?: 'news_category_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "news_category" */
export type News_Category_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "news_category". All fields are combined with a logical 'AND'. */
export type News_Category_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<News_Category_Bool_Exp>>>;
  _not?: InputMaybe<News_Category_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<News_Category_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  meta_description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  news?: InputMaybe<News_Bool_Exp>;
  position?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type News_Category_Max_Fields = {
  __typename?: 'news_category_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "news_category" */
export type News_Category_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type News_Category_Min_Fields = {
  __typename?: 'news_category_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "news_category" */
export type News_Category_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "news_category" */
export type News_Category_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  news_aggregate?: InputMaybe<News_Aggregate_Order_By>;
  position?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "news_category" */
export type News_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "news_category" */
export enum News_Category_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  Name = 'name',
  /** column name */
  Position = 'position',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate stddev on columns */
export type News_Category_Stddev_Fields = {
  __typename?: 'news_category_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "news_category" */
export type News_Category_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type News_Category_Stddev_Pop_Fields = {
  __typename?: 'news_category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "news_category" */
export type News_Category_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type News_Category_Stddev_Samp_Fields = {
  __typename?: 'news_category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "news_category" */
export type News_Category_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type News_Category_Sum_Fields = {
  __typename?: 'news_category_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "news_category" */
export type News_Category_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type News_Category_Var_Pop_Fields = {
  __typename?: 'news_category_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "news_category" */
export type News_Category_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type News_Category_Var_Samp_Fields = {
  __typename?: 'news_category_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "news_category" */
export type News_Category_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type News_Category_Variance_Fields = {
  __typename?: 'news_category_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "news_category" */
export type News_Category_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "news" */
export enum News_Constraint {
  /** unique or primary key constraint */
  NewsPkey = 'news_pkey'
}

/** input type for incrementing integer column in table "news" */
export type News_Inc_Input = {
  views?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type News_Max_Fields = {
  __typename?: 'news_max_fields';
  canonical?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  create_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "news" */
export type News_Max_Order_By = {
  canonical?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  create_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type News_Min_Fields = {
  __typename?: 'news_min_fields';
  canonical?: Maybe<Scalars['String']>;
  category_id?: Maybe<Scalars['Int']>;
  create_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['timestamptz']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  update_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "news" */
export type News_Min_Order_By = {
  canonical?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  create_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "news" */
export type News_Mutation_Response = {
  __typename?: 'news_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<News>;
};

/** on conflict condition type for table "news" */
export type News_On_Conflict = {
  constraint: News_Constraint;
  update_columns: Array<News_Update_Column>;
  where?: InputMaybe<News_Bool_Exp>;
};

/** ordering options when selecting data from "news" */
export type News_Order_By = {
  canonical?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  create_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  excerpt?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isHot?: InputMaybe<Order_By>;
  is_public?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  meta_description?: InputMaybe<Order_By>;
  news_category?: InputMaybe<News_Category_Order_By>;
  noindex?: InputMaybe<Order_By>;
  normalized_name?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  update_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "news" */
export type News_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "news" */
export enum News_Select_Column {
  /** column name */
  Canonical = 'canonical',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreateAt = 'create_at',
  /** column name */
  Date = 'date',
  /** column name */
  Excerpt = 'excerpt',
  /** column name */
  FakeViews = 'fake_views',
  /** column name */
  Id = 'id',
  /** column name */
  IsHot = 'isHot',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  Link = 'link',
  /** column name */
  MetaDescription = 'meta_description',
  /** column name */
  Noindex = 'noindex',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  UpdateAt = 'update_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views'
}

/** input type for updating data in table "news" */
export type News_Set_Input = {
  views?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type News_Stddev_Fields = {
  __typename?: 'news_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "news" */
export type News_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type News_Stddev_Pop_Fields = {
  __typename?: 'news_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "news" */
export type News_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type News_Stddev_Samp_Fields = {
  __typename?: 'news_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "news" */
export type News_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type News_Sum_Fields = {
  __typename?: 'news_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  fake_views?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  views?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "news" */
export type News_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** update columns of table "news" */
export enum News_Update_Column {
  /** column name */
  Views = 'views'
}

/** aggregate var_pop on columns */
export type News_Var_Pop_Fields = {
  __typename?: 'news_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "news" */
export type News_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type News_Var_Samp_Fields = {
  __typename?: 'news_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "news" */
export type News_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type News_Variance_Fields = {
  __typename?: 'news_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  fake_views?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  views?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "news" */
export type News_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  fake_views?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "profile" */
export type Profile = {
  __typename?: 'profile';
  /** An array relationship */
  courses: Array<Course>;
  /** An aggregated array relationship */
  courses_aggregate: Course_Aggregate;
  created_at: Scalars['timestamptz'];
  date_of_birth?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  earned_point: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  exp_level?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  interested_products?: Maybe<Scalars['jsonb']>;
  last_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  lessons: Array<Lesson>;
  /** An aggregated array relationship */
  lessons_aggregate: Lesson_Aggregate;
  location?: Maybe<Scalars['String']>;
  /** An array relationship */
  magazines: Array<Magazine>;
  /** An array relationship */
  magazinesByUpdatedBy: Array<Magazine>;
  /** An aggregated array relationship */
  magazinesByUpdatedBy_aggregate: Magazine_Aggregate;
  /** An aggregated array relationship */
  magazines_aggregate: Magazine_Aggregate;
  /** An array relationship */
  news: Array<News>;
  /** An aggregated array relationship */
  news_aggregate: News_Aggregate;
  otp?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  /** A computed field, executes function "user_profile_pic" */
  profile_picture_s3_url?: Maybe<Scalars['String']>;
  profile_picture_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  review_magazines: Array<Review_Magazine>;
  /** An aggregated array relationship */
  review_magazines_aggregate: Review_Magazine_Aggregate;
  /** An array relationship */
  review_webinars: Array<Review_Webinar>;
  /** An aggregated array relationship */
  review_webinars_aggregate: Review_Webinar_Aggregate;
  role?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
  uuid?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  video_watcheds: Array<Video_Watched>;
  /** An array relationship */
  webinars: Array<Webinars>;
  /** An aggregated array relationship */
  webinars_aggregate: Webinars_Aggregate;
};


/** columns and relationships of "profile" */
export type ProfileCoursesArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileCourses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileInterested_ProductsArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "profile" */
export type ProfileLessonsArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileLessons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileMagazinesArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Order_By>>;
  where?: InputMaybe<Magazine_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileMagazinesByUpdatedByArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Order_By>>;
  where?: InputMaybe<Magazine_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileMagazinesByUpdatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Order_By>>;
  where?: InputMaybe<Magazine_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileMagazines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Order_By>>;
  where?: InputMaybe<Magazine_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileNewsArgs = {
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileNews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileReview_MagazinesArgs = {
  distinct_on?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Magazine_Order_By>>;
  where?: InputMaybe<Review_Magazine_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileReview_Magazines_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Magazine_Order_By>>;
  where?: InputMaybe<Review_Magazine_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileReview_WebinarsArgs = {
  distinct_on?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Webinar_Order_By>>;
  where?: InputMaybe<Review_Webinar_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileReview_Webinars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Webinar_Order_By>>;
  where?: InputMaybe<Review_Webinar_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileVideo_WatchedsArgs = {
  distinct_on?: InputMaybe<Array<Video_Watched_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Watched_Order_By>>;
  where?: InputMaybe<Video_Watched_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileWebinarsArgs = {
  distinct_on?: InputMaybe<Array<Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webinars_Order_By>>;
  where?: InputMaybe<Webinars_Bool_Exp>;
};


/** columns and relationships of "profile" */
export type ProfileWebinars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webinars_Order_By>>;
  where?: InputMaybe<Webinars_Bool_Exp>;
};

/** aggregated selection of "profile" */
export type Profile_Aggregate = {
  __typename?: 'profile_aggregate';
  aggregate?: Maybe<Profile_Aggregate_Fields>;
  nodes: Array<Profile>;
};

/** aggregate fields of "profile" */
export type Profile_Aggregate_Fields = {
  __typename?: 'profile_aggregate_fields';
  avg?: Maybe<Profile_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Profile_Max_Fields>;
  min?: Maybe<Profile_Min_Fields>;
  stddev?: Maybe<Profile_Stddev_Fields>;
  stddev_pop?: Maybe<Profile_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Profile_Stddev_Samp_Fields>;
  sum?: Maybe<Profile_Sum_Fields>;
  var_pop?: Maybe<Profile_Var_Pop_Fields>;
  var_samp?: Maybe<Profile_Var_Samp_Fields>;
  variance?: Maybe<Profile_Variance_Fields>;
};


/** aggregate fields of "profile" */
export type Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "profile" */
export type Profile_Aggregate_Order_By = {
  avg?: InputMaybe<Profile_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Profile_Max_Order_By>;
  min?: InputMaybe<Profile_Min_Order_By>;
  stddev?: InputMaybe<Profile_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Profile_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Profile_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Profile_Sum_Order_By>;
  var_pop?: InputMaybe<Profile_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Profile_Var_Samp_Order_By>;
  variance?: InputMaybe<Profile_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Profile_Avg_Fields = {
  __typename?: 'profile_avg_fields';
  earned_point?: Maybe<Scalars['Float']>;
  exp_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "profile" */
export type Profile_Avg_Order_By = {
  earned_point?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "profile". All fields are combined with a logical 'AND'. */
export type Profile_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Profile_Bool_Exp>>>;
  _not?: InputMaybe<Profile_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Profile_Bool_Exp>>>;
  courses?: InputMaybe<Course_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  earned_point?: InputMaybe<Int_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  exp_level?: InputMaybe<Int_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  interested_products?: InputMaybe<Jsonb_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  lessons?: InputMaybe<Lesson_Bool_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  magazines?: InputMaybe<Magazine_Bool_Exp>;
  magazinesByUpdatedBy?: InputMaybe<Magazine_Bool_Exp>;
  news?: InputMaybe<News_Bool_Exp>;
  otp?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  profile_picture_url?: InputMaybe<String_Comparison_Exp>;
  review_magazines?: InputMaybe<Review_Magazine_Bool_Exp>;
  review_webinars?: InputMaybe<Review_Webinar_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
  video_watcheds?: InputMaybe<Video_Watched_Bool_Exp>;
  webinars?: InputMaybe<Webinars_Bool_Exp>;
};

/** aggregate max on columns */
export type Profile_Max_Fields = {
  __typename?: 'profile_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  earned_point?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  exp_level?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  otp?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_picture_url?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "profile" */
export type Profile_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  earned_point?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  otp?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  profile_picture_url?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Profile_Min_Fields = {
  __typename?: 'profile_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  earned_point?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  exp_level?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  otp?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_picture_url?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "profile" */
export type Profile_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  earned_point?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  otp?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  profile_picture_url?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "profile" */
export type Profile_Order_By = {
  courses_aggregate?: InputMaybe<Course_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  earned_point?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interested_products?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  lessons_aggregate?: InputMaybe<Lesson_Aggregate_Order_By>;
  location?: InputMaybe<Order_By>;
  magazinesByUpdatedBy_aggregate?: InputMaybe<Magazine_Aggregate_Order_By>;
  magazines_aggregate?: InputMaybe<Magazine_Aggregate_Order_By>;
  news_aggregate?: InputMaybe<News_Aggregate_Order_By>;
  otp?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  profile_picture_url?: InputMaybe<Order_By>;
  review_magazines_aggregate?: InputMaybe<Review_Magazine_Aggregate_Order_By>;
  review_webinars_aggregate?: InputMaybe<Review_Webinar_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
  webinars_aggregate?: InputMaybe<Webinars_Aggregate_Order_By>;
};

/** primary key columns input for table: "profile" */
export type Profile_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "profile" */
export enum Profile_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Description = 'description',
  /** column name */
  EarnedPoint = 'earned_point',
  /** column name */
  Email = 'email',
  /** column name */
  ExpLevel = 'exp_level',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InterestedProducts = 'interested_products',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Location = 'location',
  /** column name */
  Otp = 'otp',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfilePictureUrl = 'profile_picture_url',
  /** column name */
  Role = 'role',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Uuid = 'uuid'
}

/** aggregate stddev on columns */
export type Profile_Stddev_Fields = {
  __typename?: 'profile_stddev_fields';
  earned_point?: Maybe<Scalars['Float']>;
  exp_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "profile" */
export type Profile_Stddev_Order_By = {
  earned_point?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Profile_Stddev_Pop_Fields = {
  __typename?: 'profile_stddev_pop_fields';
  earned_point?: Maybe<Scalars['Float']>;
  exp_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "profile" */
export type Profile_Stddev_Pop_Order_By = {
  earned_point?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Profile_Stddev_Samp_Fields = {
  __typename?: 'profile_stddev_samp_fields';
  earned_point?: Maybe<Scalars['Float']>;
  exp_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "profile" */
export type Profile_Stddev_Samp_Order_By = {
  earned_point?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Profile_Sum_Fields = {
  __typename?: 'profile_sum_fields';
  earned_point?: Maybe<Scalars['Int']>;
  exp_level?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "profile" */
export type Profile_Sum_Order_By = {
  earned_point?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Profile_Var_Pop_Fields = {
  __typename?: 'profile_var_pop_fields';
  earned_point?: Maybe<Scalars['Float']>;
  exp_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "profile" */
export type Profile_Var_Pop_Order_By = {
  earned_point?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Profile_Var_Samp_Fields = {
  __typename?: 'profile_var_samp_fields';
  earned_point?: Maybe<Scalars['Float']>;
  exp_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "profile" */
export type Profile_Var_Samp_Order_By = {
  earned_point?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Profile_Variance_Fields = {
  __typename?: 'profile_variance_fields';
  earned_point?: Maybe<Scalars['Float']>;
  exp_level?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "profile" */
export type Profile_Variance_Order_By = {
  earned_point?: InputMaybe<Order_By>;
  exp_level?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "advertisement" */
  advertisement: Array<Advertisement>;
  /** fetch aggregated fields from the table: "advertisement" */
  advertisement_aggregate: Advertisement_Aggregate;
  /** fetch data from the table: "advertisement" using primary key columns */
  advertisement_by_pk?: Maybe<Advertisement>;
  /** fetch data from the table: "author" */
  author: Array<Author>;
  /** fetch data from the table: "author" using primary key columns */
  author_by_pk?: Maybe<Author>;
  /** fetch data from the table: "blog" */
  blog: Array<Blog>;
  /** fetch aggregated fields from the table: "blog" */
  blog_aggregate: Blog_Aggregate;
  /** fetch data from the table: "blog" using primary key columns */
  blog_by_pk?: Maybe<Blog>;
  /** fetch data from the table: "blog_category" */
  blog_category: Array<Blog_Category>;
  /** fetch aggregated fields from the table: "blog_category" */
  blog_category_aggregate: Blog_Category_Aggregate;
  /** fetch data from the table: "blog_category" using primary key columns */
  blog_category_by_pk?: Maybe<Blog_Category>;
  /** fetch data from the table: "comment_exchange" */
  comment_exchange: Array<Comment_Exchange>;
  /** fetch aggregated fields from the table: "comment_exchange" */
  comment_exchange_aggregate: Comment_Exchange_Aggregate;
  /** fetch data from the table: "comment_exchange" using primary key columns */
  comment_exchange_by_pk?: Maybe<Comment_Exchange>;
  /** fetch data from the table: "comment_type" */
  comment_type: Array<Comment_Type>;
  /** fetch data from the table: "comment_type" using primary key columns */
  comment_type_by_pk?: Maybe<Comment_Type>;
  /** fetch data from the table: "course" */
  course: Array<Course>;
  /** fetch aggregated fields from the table: "course" */
  course_aggregate: Course_Aggregate;
  /** fetch data from the table: "course" using primary key columns */
  course_by_pk?: Maybe<Course>;
  /** fetch data from the table: "course_category" */
  course_category: Array<Course_Category>;
  /** fetch aggregated fields from the table: "course_category" */
  course_category_aggregate: Course_Category_Aggregate;
  /** fetch data from the table: "course_category" using primary key columns */
  course_category_by_pk?: Maybe<Course_Category>;
  /** fetch data from the table: "course_course_category" */
  course_course_category: Array<Course_Course_Category>;
  /** fetch aggregated fields from the table: "course_course_category" */
  course_course_category_aggregate: Course_Course_Category_Aggregate;
  /** fetch data from the table: "course_course_category" using primary key columns */
  course_course_category_by_pk?: Maybe<Course_Course_Category>;
  /** fetch data from the table: "course_goal" */
  course_goal: Array<Course_Goal>;
  /** fetch aggregated fields from the table: "course_goal" */
  course_goal_aggregate: Course_Goal_Aggregate;
  /** fetch data from the table: "course_goal" using primary key columns */
  course_goal_by_pk?: Maybe<Course_Goal>;
  /** fetch data from the table: "course_goal_map" */
  course_goal_map: Array<Course_Goal_Map>;
  /** fetch aggregated fields from the table: "course_goal_map" */
  course_goal_map_aggregate: Course_Goal_Map_Aggregate;
  /** fetch data from the table: "course_goal_map" using primary key columns */
  course_goal_map_by_pk?: Maybe<Course_Goal_Map>;
  /** fetch data from the table: "course_lesson_view" */
  course_lesson_view: Array<Course_Lesson_View>;
  /** fetch aggregated fields from the table: "course_lesson_view" */
  course_lesson_view_aggregate: Course_Lesson_View_Aggregate;
  /** fetch data from the table: "course_rating" */
  course_rating: Array<Course_Rating>;
  /** fetch aggregated fields from the table: "course_rating" */
  course_rating_aggregate: Course_Rating_Aggregate;
  /** fetch data from the table: "course_rating" using primary key columns */
  course_rating_by_pk?: Maybe<Course_Rating>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** execute function "draft_comment_exchange" which returns "comment_exchange" */
  draft_comment_exchange: Array<Comment_Exchange>;
  /** execute function "draft_comment_exchange" and query aggregates on result of table type "comment_exchange" */
  draft_comment_exchange_aggregate: Comment_Exchange_Aggregate;
  /** fetch data from the table: "exchange" */
  exchange: Array<Exchange>;
  /** fetch aggregated fields from the table: "exchange" */
  exchange_aggregate: Exchange_Aggregate;
  /** fetch data from the table: "exchange" using primary key columns */
  exchange_by_pk?: Maybe<Exchange>;
  /** perform the action: "findItems" */
  findItems: SearchOutput;
  /** perform the action: "getLearnedCourse" */
  getLearnedCourse?: Maybe<GetLearnedCourseOutput>;
  /** perform the action: "getLearnedLesson" */
  getLearnedLesson?: Maybe<GetLearnedLessonOutput>;
  /** perform the action: "getListWebinarForUser" */
  getListWebinarForUser?: Maybe<GetListWebinarForUserOutPut>;
  /** perform the action: "getMediaURL" */
  getMediaURL?: Maybe<GetMediaUrlOutput>;
  /** perform the action: "hotNews" */
  hotNews?: Maybe<HotNewsRespnose>;
  /** fetch data from the table: "insight" */
  insight: Array<Insight>;
  /** fetch data from the table: "insight" using primary key columns */
  insight_by_pk?: Maybe<Insight>;
  /** fetch data from the table: "knowledge" */
  knowledge: Array<Knowledge>;
  /** fetch data from the table: "knowledge_action" */
  knowledge_action: Array<Knowledge_Action>;
  /** fetch data from the table: "knowledge_action" using primary key columns */
  knowledge_action_by_pk?: Maybe<Knowledge_Action>;
  /** fetch aggregated fields from the table: "knowledge" */
  knowledge_aggregate: Knowledge_Aggregate;
  /** fetch data from the table: "knowledge" using primary key columns */
  knowledge_by_pk?: Maybe<Knowledge>;
  /** fetch data from the table: "learning_path" */
  learning_path: Array<Learning_Path>;
  /** fetch aggregated fields from the table: "learning_path" */
  learning_path_aggregate: Learning_Path_Aggregate;
  /** fetch data from the table: "learning_path" using primary key columns */
  learning_path_by_pk?: Maybe<Learning_Path>;
  /** fetch data from the table: "learning_process" */
  learning_process: Array<Learning_Process>;
  /** fetch aggregated fields from the table: "learning_process" */
  learning_process_aggregate: Learning_Process_Aggregate;
  /** fetch data from the table: "learning_process" using primary key columns */
  learning_process_by_pk?: Maybe<Learning_Process>;
  /** fetch data from the table: "lesson" */
  lesson: Array<Lesson>;
  /** fetch aggregated fields from the table: "lesson" */
  lesson_aggregate: Lesson_Aggregate;
  /** fetch data from the table: "lesson" using primary key columns */
  lesson_by_pk?: Maybe<Lesson>;
  /** fetch data from the table: "lesson_category" */
  lesson_category: Array<Lesson_Category>;
  /** fetch aggregated fields from the table: "lesson_category" */
  lesson_category_aggregate: Lesson_Category_Aggregate;
  /** fetch data from the table: "lesson_category" using primary key columns */
  lesson_category_by_pk?: Maybe<Lesson_Category>;
  /** fetch data from the table: "lesson_classify" */
  lesson_classify: Array<Lesson_Classify>;
  /** fetch aggregated fields from the table: "lesson_classify" */
  lesson_classify_aggregate: Lesson_Classify_Aggregate;
  /** fetch data from the table: "lesson_classify" using primary key columns */
  lesson_classify_by_pk?: Maybe<Lesson_Classify>;
  /** fetch data from the table: "lesson_comment_web" */
  lesson_comment_web: Array<Lesson_Comment_Web>;
  /** fetch aggregated fields from the table: "lesson_comment_web" */
  lesson_comment_web_aggregate: Lesson_Comment_Web_Aggregate;
  /** fetch data from the table: "lesson_comment_web" using primary key columns */
  lesson_comment_web_by_pk?: Maybe<Lesson_Comment_Web>;
  /** fetch data from the table: "lesson_course" */
  lesson_course: Array<Lesson_Course>;
  /** fetch aggregated fields from the table: "lesson_course" */
  lesson_course_aggregate: Lesson_Course_Aggregate;
  /** fetch data from the table: "lesson_course" using primary key columns */
  lesson_course_by_pk?: Maybe<Lesson_Course>;
  /** fetch data from the table: "lesson_course_view" */
  lesson_course_view: Array<Lesson_Course_View>;
  /** fetch aggregated fields from the table: "lesson_course_view" */
  lesson_course_view_aggregate: Lesson_Course_View_Aggregate;
  /** fetch data from the table: "lesson_document_map" */
  lesson_document_map: Array<Lesson_Document_Map>;
  /** fetch aggregated fields from the table: "lesson_document_map" */
  lesson_document_map_aggregate: Lesson_Document_Map_Aggregate;
  /** fetch data from the table: "lesson_document_map" using primary key columns */
  lesson_document_map_by_pk?: Maybe<Lesson_Document_Map>;
  /** fetch data from the table: "lesson_rating" */
  lesson_rating: Array<Lesson_Rating>;
  /** fetch aggregated fields from the table: "lesson_rating" */
  lesson_rating_aggregate: Lesson_Rating_Aggregate;
  /** fetch data from the table: "lesson_rating" using primary key columns */
  lesson_rating_by_pk?: Maybe<Lesson_Rating>;
  /** fetch data from the table: "level_course" */
  level_course: Array<Level_Course>;
  /** fetch aggregated fields from the table: "level_course" */
  level_course_aggregate: Level_Course_Aggregate;
  /** fetch data from the table: "level_course" using primary key columns */
  level_course_by_pk?: Maybe<Level_Course>;
  /** fetch data from the table: "like_count" */
  like_count: Array<Like_Count>;
  /** fetch aggregated fields from the table: "like_count" */
  like_count_aggregate: Like_Count_Aggregate;
  /** fetch data from the table: "like_count" using primary key columns */
  like_count_by_pk?: Maybe<Like_Count>;
  /** fetch data from the table: "magazine" */
  magazine: Array<Magazine>;
  /** fetch aggregated fields from the table: "magazine" */
  magazine_aggregate: Magazine_Aggregate;
  /** fetch data from the table: "magazine" using primary key columns */
  magazine_by_pk?: Maybe<Magazine>;
  /** fetch data from the table: "magazine_comment_web" */
  magazine_comment_web: Array<Magazine_Comment_Web>;
  /** fetch aggregated fields from the table: "magazine_comment_web" */
  magazine_comment_web_aggregate: Magazine_Comment_Web_Aggregate;
  /** fetch data from the table: "magazine_comment_web" using primary key columns */
  magazine_comment_web_by_pk?: Maybe<Magazine_Comment_Web>;
  /** fetch data from the table: "news" */
  news: Array<News>;
  /** fetch aggregated fields from the table: "news" */
  news_aggregate: News_Aggregate;
  /** fetch data from the table: "news" using primary key columns */
  news_by_pk?: Maybe<News>;
  /** fetch data from the table: "news_category" */
  news_category: Array<News_Category>;
  /** fetch aggregated fields from the table: "news_category" */
  news_category_aggregate: News_Category_Aggregate;
  /** fetch data from the table: "news_category" using primary key columns */
  news_category_by_pk?: Maybe<News_Category>;
  /** perform the action: "news_feed" */
  news_feed?: Maybe<Array<Maybe<NewsFeed>>>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "redirect" */
  redirect: Array<Redirect>;
  /** fetch data from the table: "redirect" using primary key columns */
  redirect_by_pk?: Maybe<Redirect>;
  /** fetch data from the table: "review_lesson" */
  review_lesson: Array<Review_Lesson>;
  /** fetch data from the table: "review_lesson" using primary key columns */
  review_lesson_by_pk?: Maybe<Review_Lesson>;
  /** fetch data from the table: "review_magazine" */
  review_magazine: Array<Review_Magazine>;
  /** fetch aggregated fields from the table: "review_magazine" */
  review_magazine_aggregate: Review_Magazine_Aggregate;
  /** fetch data from the table: "review_magazine" using primary key columns */
  review_magazine_by_pk?: Maybe<Review_Magazine>;
  /** fetch data from the table: "review_webinar" */
  review_webinar: Array<Review_Webinar>;
  /** fetch aggregated fields from the table: "review_webinar" */
  review_webinar_aggregate: Review_Webinar_Aggregate;
  /** fetch data from the table: "review_webinar" using primary key columns */
  review_webinar_by_pk?: Maybe<Review_Webinar>;
  /** execute function "search_blog" which returns "blog" */
  search_blog: Array<Blog>;
  /** execute function "search_blog" and query aggregates on result of table type "blog" */
  search_blog_aggregate: Blog_Aggregate;
  /** execute function "search_comment" which returns "comment_exchange" */
  search_comment: Array<Comment_Exchange>;
  /** execute function "search_comment" and query aggregates on result of table type "comment_exchange" */
  search_comment_aggregate: Comment_Exchange_Aggregate;
  /** execute function "search_comment_exchange" which returns "comment_exchange" */
  search_comment_exchange: Array<Comment_Exchange>;
  /** execute function "search_comment_exchange" and query aggregates on result of table type "comment_exchange" */
  search_comment_exchange_aggregate: Comment_Exchange_Aggregate;
  /** execute function "search_course" which returns "course" */
  search_course: Array<Course>;
  /** execute function "search_course" and query aggregates on result of table type "course" */
  search_course_aggregate: Course_Aggregate;
  /** execute function "search_course_category" which returns "course_category" */
  search_course_category: Array<Course_Category>;
  /** execute function "search_course_category" and query aggregates on result of table type "course_category" */
  search_course_category_aggregate: Course_Category_Aggregate;
  /** execute function "search_course_goal" which returns "course_goal" */
  search_course_goal: Array<Course_Goal>;
  /** execute function "search_course_goal" and query aggregates on result of table type "course_goal" */
  search_course_goal_aggregate: Course_Goal_Aggregate;
  /** execute function "search_document" which returns "document" */
  search_document: Array<Document>;
  /** execute function "search_document" and query aggregates on result of table type "document" */
  search_document_aggregate: Document_Aggregate;
  /** execute function "search_exchange" which returns "exchange" */
  search_exchange: Array<Exchange>;
  /** execute function "search_exchange" and query aggregates on result of table type "exchange" */
  search_exchange_aggregate: Exchange_Aggregate;
  /** execute function "search_insight" which returns "insight" */
  search_insight: Array<Insight>;
  /** execute function "search_knowledge" which returns "knowledge" */
  search_knowledge: Array<Knowledge>;
  /** execute function "search_knowledge" and query aggregates on result of table type "knowledge" */
  search_knowledge_aggregate: Knowledge_Aggregate;
  /** execute function "search_learning_path" which returns "learning_path" */
  search_learning_path: Array<Learning_Path>;
  /** execute function "search_learning_path" and query aggregates on result of table type "learning_path" */
  search_learning_path_aggregate: Learning_Path_Aggregate;
  /** execute function "search_lesson" which returns "lesson" */
  search_lesson: Array<Lesson>;
  /** execute function "search_lesson" and query aggregates on result of table type "lesson" */
  search_lesson_aggregate: Lesson_Aggregate;
  /** execute function "search_lesson_category" which returns "lesson_category" */
  search_lesson_category: Array<Lesson_Category>;
  /** execute function "search_lesson_category" and query aggregates on result of table type "lesson_category" */
  search_lesson_category_aggregate: Lesson_Category_Aggregate;
  /** execute function "search_lesson_classify" which returns "lesson_classify" */
  search_lesson_classify: Array<Lesson_Classify>;
  /** execute function "search_lesson_classify" and query aggregates on result of table type "lesson_classify" */
  search_lesson_classify_aggregate: Lesson_Classify_Aggregate;
  /** execute function "search_level_course" which returns "level_course" */
  search_level_course: Array<Level_Course>;
  /** execute function "search_level_course" and query aggregates on result of table type "level_course" */
  search_level_course_aggregate: Level_Course_Aggregate;
  /** execute function "search_news" which returns "news" */
  search_news: Array<News>;
  /** execute function "search_news" and query aggregates on result of table type "news" */
  search_news_aggregate: News_Aggregate;
  /** execute function "search_series" which returns "series" */
  search_series: Array<Series>;
  /** fetch data from the table: "seeder" */
  seeder: Array<Seeder>;
  /** fetch aggregated fields from the table: "seeder" */
  seeder_aggregate: Seeder_Aggregate;
  /** fetch data from the table: "seeder" using primary key columns */
  seeder_by_pk?: Maybe<Seeder>;
  /** fetch data from the table: "series" */
  series: Array<Series>;
  /** fetch data from the table: "series" using primary key columns */
  series_by_pk?: Maybe<Series>;
  /** fetch data from the table: "series_course_category" */
  series_course_category: Array<Series_Course_Category>;
  /** fetch data from the table: "series_course_category" using primary key columns */
  series_course_category_by_pk?: Maybe<Series_Course_Category>;
  /** fetch data from the table: "series_knowledge" */
  series_knowledge: Array<Series_Knowledge>;
  /** fetch data from the table: "series_knowledge" using primary key columns */
  series_knowledge_by_pk?: Maybe<Series_Knowledge>;
  /** fetch data from the table: "video_watched" */
  video_watched: Array<Video_Watched>;
  /** fetch data from the table: "video_watched" using primary key columns */
  video_watched_by_pk?: Maybe<Video_Watched>;
  /** perform the action: "webinar" */
  webinar?: Maybe<Array<Maybe<Webinar>>>;
  /** fetch data from the table: "webinars" */
  webinars: Array<Webinars>;
  /** fetch aggregated fields from the table: "webinars" */
  webinars_aggregate: Webinars_Aggregate;
  /** fetch data from the table: "webinars" using primary key columns */
  webinars_by_pk?: Maybe<Webinars>;
};


/** query root */
export type Query_RootAdvertisementArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};


/** query root */
export type Query_RootAdvertisement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};


/** query root */
export type Query_RootAdvertisement_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootAuthorArgs = {
  distinct_on?: InputMaybe<Array<Author_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Author_Order_By>>;
  where?: InputMaybe<Author_Bool_Exp>;
};


/** query root */
export type Query_RootAuthor_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootBlogArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** query root */
export type Query_RootBlog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** query root */
export type Query_RootBlog_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootBlog_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Blog_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Category_Order_By>>;
  where?: InputMaybe<Blog_Category_Bool_Exp>;
};


/** query root */
export type Query_RootBlog_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Category_Order_By>>;
  where?: InputMaybe<Blog_Category_Bool_Exp>;
};


/** query root */
export type Query_RootBlog_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootComment_ExchangeArgs = {
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootComment_Exchange_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootComment_Exchange_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootComment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Comment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Type_Order_By>>;
  where?: InputMaybe<Comment_Type_Bool_Exp>;
};


/** query root */
export type Query_RootComment_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootCourseArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootCourse_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Category_Order_By>>;
  where?: InputMaybe<Course_Category_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Category_Order_By>>;
  where?: InputMaybe<Course_Category_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Category_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCourse_Course_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Course_Category_Order_By>>;
  where?: InputMaybe<Course_Course_Category_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Course_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Course_Category_Order_By>>;
  where?: InputMaybe<Course_Course_Category_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Course_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootCourse_GoalArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Order_By>>;
  where?: InputMaybe<Course_Goal_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Goal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Order_By>>;
  where?: InputMaybe<Course_Goal_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Goal_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootCourse_Goal_MapArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Map_Order_By>>;
  where?: InputMaybe<Course_Goal_Map_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Goal_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Map_Order_By>>;
  where?: InputMaybe<Course_Goal_Map_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Goal_Map_By_PkArgs = {
  course_id: Scalars['Int'];
  goal_id: Scalars['Int'];
};


/** query root */
export type Query_RootCourse_Lesson_ViewArgs = {
  distinct_on?: InputMaybe<Array<Course_Lesson_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Lesson_View_Order_By>>;
  where?: InputMaybe<Course_Lesson_View_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Lesson_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Lesson_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Lesson_View_Order_By>>;
  where?: InputMaybe<Course_Lesson_View_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_RatingArgs = {
  distinct_on?: InputMaybe<Array<Course_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Rating_Order_By>>;
  where?: InputMaybe<Course_Rating_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Rating_Order_By>>;
  where?: InputMaybe<Course_Rating_Bool_Exp>;
};


/** query root */
export type Query_RootCourse_Rating_By_PkArgs = {
  course_id: Scalars['Int'];
  user_email: Scalars['String'];
};


/** query root */
export type Query_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** query root */
export type Query_RootDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** query root */
export type Query_RootDocument_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootDraft_Comment_ExchangeArgs = {
  args: Draft_Comment_Exchange_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootDraft_Comment_Exchange_AggregateArgs = {
  args: Draft_Comment_Exchange_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootExchangeArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Order_By>>;
  where?: InputMaybe<Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootExchange_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Order_By>>;
  where?: InputMaybe<Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootExchange_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootFindItemsArgs = {
  query: SearchInput;
};


/** query root */
export type Query_RootGetLearnedCourseArgs = {
  level?: InputMaybe<Scalars['String']>;
};


/** query root */
export type Query_RootGetLearnedLessonArgs = {
  course_id?: InputMaybe<Scalars['Int']>;
};


/** query root */
export type Query_RootGetMediaUrlArgs = {
  namefile?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


/** query root */
export type Query_RootInsightArgs = {
  distinct_on?: InputMaybe<Array<Insight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insight_Order_By>>;
  where?: InputMaybe<Insight_Bool_Exp>;
};


/** query root */
export type Query_RootInsight_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootKnowledgeArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Order_By>>;
  where?: InputMaybe<Knowledge_Bool_Exp>;
};


/** query root */
export type Query_RootKnowledge_ActionArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Action_Order_By>>;
  where?: InputMaybe<Knowledge_Action_Bool_Exp>;
};


/** query root */
export type Query_RootKnowledge_Action_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootKnowledge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Order_By>>;
  where?: InputMaybe<Knowledge_Bool_Exp>;
};


/** query root */
export type Query_RootKnowledge_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootLearning_PathArgs = {
  distinct_on?: InputMaybe<Array<Learning_Path_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Path_Order_By>>;
  where?: InputMaybe<Learning_Path_Bool_Exp>;
};


/** query root */
export type Query_RootLearning_Path_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Path_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Path_Order_By>>;
  where?: InputMaybe<Learning_Path_Bool_Exp>;
};


/** query root */
export type Query_RootLearning_Path_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootLearning_ProcessArgs = {
  distinct_on?: InputMaybe<Array<Learning_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Process_Order_By>>;
  where?: InputMaybe<Learning_Process_Bool_Exp>;
};


/** query root */
export type Query_RootLearning_Process_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Process_Order_By>>;
  where?: InputMaybe<Learning_Process_Bool_Exp>;
};


/** query root */
export type Query_RootLearning_Process_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootLessonArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootLesson_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Category_Order_By>>;
  where?: InputMaybe<Lesson_Category_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Category_Order_By>>;
  where?: InputMaybe<Lesson_Category_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootLesson_ClassifyArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Classify_Order_By>>;
  where?: InputMaybe<Lesson_Classify_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Classify_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Classify_Order_By>>;
  where?: InputMaybe<Lesson_Classify_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Classify_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootLesson_Comment_WebArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Comment_Web_Order_By>>;
  where?: InputMaybe<Lesson_Comment_Web_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Comment_Web_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Comment_Web_Order_By>>;
  where?: InputMaybe<Lesson_Comment_Web_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Comment_Web_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootLesson_CourseArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_Order_By>>;
  where?: InputMaybe<Lesson_Course_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Course_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_Order_By>>;
  where?: InputMaybe<Lesson_Course_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootLesson_Course_ViewArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_View_Order_By>>;
  where?: InputMaybe<Lesson_Course_View_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Course_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_View_Order_By>>;
  where?: InputMaybe<Lesson_Course_View_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Document_MapArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Document_Map_Order_By>>;
  where?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Document_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Document_Map_Order_By>>;
  where?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Document_Map_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootLesson_RatingArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Rating_Order_By>>;
  where?: InputMaybe<Lesson_Rating_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Rating_Order_By>>;
  where?: InputMaybe<Lesson_Rating_Bool_Exp>;
};


/** query root */
export type Query_RootLesson_Rating_By_PkArgs = {
  lesson_id: Scalars['Int'];
  user_id: Scalars['String'];
};


/** query root */
export type Query_RootLevel_CourseArgs = {
  distinct_on?: InputMaybe<Array<Level_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Level_Course_Order_By>>;
  where?: InputMaybe<Level_Course_Bool_Exp>;
};


/** query root */
export type Query_RootLevel_Course_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Level_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Level_Course_Order_By>>;
  where?: InputMaybe<Level_Course_Bool_Exp>;
};


/** query root */
export type Query_RootLevel_Course_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootLike_CountArgs = {
  distinct_on?: InputMaybe<Array<Like_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Like_Count_Order_By>>;
  where?: InputMaybe<Like_Count_Bool_Exp>;
};


/** query root */
export type Query_RootLike_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Like_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Like_Count_Order_By>>;
  where?: InputMaybe<Like_Count_Bool_Exp>;
};


/** query root */
export type Query_RootLike_Count_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootMagazineArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Order_By>>;
  where?: InputMaybe<Magazine_Bool_Exp>;
};


/** query root */
export type Query_RootMagazine_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Order_By>>;
  where?: InputMaybe<Magazine_Bool_Exp>;
};


/** query root */
export type Query_RootMagazine_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootMagazine_Comment_WebArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Comment_Web_Order_By>>;
  where?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
};


/** query root */
export type Query_RootMagazine_Comment_Web_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Comment_Web_Order_By>>;
  where?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
};


/** query root */
export type Query_RootMagazine_Comment_Web_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootNewsArgs = {
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** query root */
export type Query_RootNews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** query root */
export type Query_RootNews_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootNews_CategoryArgs = {
  distinct_on?: InputMaybe<Array<News_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Category_Order_By>>;
  where?: InputMaybe<News_Category_Bool_Exp>;
};


/** query root */
export type Query_RootNews_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<News_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Category_Order_By>>;
  where?: InputMaybe<News_Category_Bool_Exp>;
};


/** query root */
export type Query_RootNews_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


/** query root */
export type Query_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


/** query root */
export type Query_RootProfile_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootRedirectArgs = {
  distinct_on?: InputMaybe<Array<Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Redirect_Order_By>>;
  where?: InputMaybe<Redirect_Bool_Exp>;
};


/** query root */
export type Query_RootRedirect_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootReview_LessonArgs = {
  distinct_on?: InputMaybe<Array<Review_Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Lesson_Order_By>>;
  where?: InputMaybe<Review_Lesson_Bool_Exp>;
};


/** query root */
export type Query_RootReview_Lesson_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootReview_MagazineArgs = {
  distinct_on?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Magazine_Order_By>>;
  where?: InputMaybe<Review_Magazine_Bool_Exp>;
};


/** query root */
export type Query_RootReview_Magazine_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Magazine_Order_By>>;
  where?: InputMaybe<Review_Magazine_Bool_Exp>;
};


/** query root */
export type Query_RootReview_Magazine_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootReview_WebinarArgs = {
  distinct_on?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Webinar_Order_By>>;
  where?: InputMaybe<Review_Webinar_Bool_Exp>;
};


/** query root */
export type Query_RootReview_Webinar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Webinar_Order_By>>;
  where?: InputMaybe<Review_Webinar_Bool_Exp>;
};


/** query root */
export type Query_RootReview_Webinar_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootSearch_BlogArgs = {
  args: Search_Blog_Args;
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Blog_AggregateArgs = {
  args: Search_Blog_Args;
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_CommentArgs = {
  args: Search_Comment_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Comment_AggregateArgs = {
  args: Search_Comment_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Comment_ExchangeArgs = {
  args: Search_Comment_Exchange_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Comment_Exchange_AggregateArgs = {
  args: Search_Comment_Exchange_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_CourseArgs = {
  args: Search_Course_Args;
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Course_AggregateArgs = {
  args: Search_Course_Args;
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Course_CategoryArgs = {
  args: Search_Course_Category_Args;
  distinct_on?: InputMaybe<Array<Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Category_Order_By>>;
  where?: InputMaybe<Course_Category_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Course_Category_AggregateArgs = {
  args: Search_Course_Category_Args;
  distinct_on?: InputMaybe<Array<Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Category_Order_By>>;
  where?: InputMaybe<Course_Category_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Course_GoalArgs = {
  args: Search_Course_Goal_Args;
  distinct_on?: InputMaybe<Array<Course_Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Order_By>>;
  where?: InputMaybe<Course_Goal_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Course_Goal_AggregateArgs = {
  args: Search_Course_Goal_Args;
  distinct_on?: InputMaybe<Array<Course_Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Order_By>>;
  where?: InputMaybe<Course_Goal_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_DocumentArgs = {
  args: Search_Document_Args;
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Document_AggregateArgs = {
  args: Search_Document_Args;
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_ExchangeArgs = {
  args: Search_Exchange_Args;
  distinct_on?: InputMaybe<Array<Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Order_By>>;
  where?: InputMaybe<Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Exchange_AggregateArgs = {
  args: Search_Exchange_Args;
  distinct_on?: InputMaybe<Array<Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Order_By>>;
  where?: InputMaybe<Exchange_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_InsightArgs = {
  args: Search_Insight_Args;
  distinct_on?: InputMaybe<Array<Insight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insight_Order_By>>;
  where?: InputMaybe<Insight_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_KnowledgeArgs = {
  args: Search_Knowledge_Args;
  distinct_on?: InputMaybe<Array<Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Order_By>>;
  where?: InputMaybe<Knowledge_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Knowledge_AggregateArgs = {
  args: Search_Knowledge_Args;
  distinct_on?: InputMaybe<Array<Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Order_By>>;
  where?: InputMaybe<Knowledge_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Learning_PathArgs = {
  args: Search_Learning_Path_Args;
  distinct_on?: InputMaybe<Array<Learning_Path_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Path_Order_By>>;
  where?: InputMaybe<Learning_Path_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Learning_Path_AggregateArgs = {
  args: Search_Learning_Path_Args;
  distinct_on?: InputMaybe<Array<Learning_Path_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Path_Order_By>>;
  where?: InputMaybe<Learning_Path_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_LessonArgs = {
  args: Search_Lesson_Args;
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Lesson_AggregateArgs = {
  args: Search_Lesson_Args;
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Lesson_CategoryArgs = {
  args: Search_Lesson_Category_Args;
  distinct_on?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Category_Order_By>>;
  where?: InputMaybe<Lesson_Category_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Lesson_Category_AggregateArgs = {
  args: Search_Lesson_Category_Args;
  distinct_on?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Category_Order_By>>;
  where?: InputMaybe<Lesson_Category_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Lesson_ClassifyArgs = {
  args: Search_Lesson_Classify_Args;
  distinct_on?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Classify_Order_By>>;
  where?: InputMaybe<Lesson_Classify_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Lesson_Classify_AggregateArgs = {
  args: Search_Lesson_Classify_Args;
  distinct_on?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Classify_Order_By>>;
  where?: InputMaybe<Lesson_Classify_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Level_CourseArgs = {
  args: Search_Level_Course_Args;
  distinct_on?: InputMaybe<Array<Level_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Level_Course_Order_By>>;
  where?: InputMaybe<Level_Course_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Level_Course_AggregateArgs = {
  args: Search_Level_Course_Args;
  distinct_on?: InputMaybe<Array<Level_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Level_Course_Order_By>>;
  where?: InputMaybe<Level_Course_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_NewsArgs = {
  args: Search_News_Args;
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_News_AggregateArgs = {
  args: Search_News_Args;
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_SeriesArgs = {
  args: Search_Series_Args;
  distinct_on?: InputMaybe<Array<Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Order_By>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


/** query root */
export type Query_RootSeederArgs = {
  distinct_on?: InputMaybe<Array<Seeder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seeder_Order_By>>;
  where?: InputMaybe<Seeder_Bool_Exp>;
};


/** query root */
export type Query_RootSeeder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seeder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seeder_Order_By>>;
  where?: InputMaybe<Seeder_Bool_Exp>;
};


/** query root */
export type Query_RootSeeder_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSeriesArgs = {
  distinct_on?: InputMaybe<Array<Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Order_By>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


/** query root */
export type Query_RootSeries_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootSeries_Course_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Series_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Course_Category_Order_By>>;
  where?: InputMaybe<Series_Course_Category_Bool_Exp>;
};


/** query root */
export type Query_RootSeries_Course_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootSeries_KnowledgeArgs = {
  distinct_on?: InputMaybe<Array<Series_Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Knowledge_Order_By>>;
  where?: InputMaybe<Series_Knowledge_Bool_Exp>;
};


/** query root */
export type Query_RootSeries_Knowledge_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootVideo_WatchedArgs = {
  distinct_on?: InputMaybe<Array<Video_Watched_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Watched_Order_By>>;
  where?: InputMaybe<Video_Watched_Bool_Exp>;
};


/** query root */
export type Query_RootVideo_Watched_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootWebinarsArgs = {
  distinct_on?: InputMaybe<Array<Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webinars_Order_By>>;
  where?: InputMaybe<Webinars_Bool_Exp>;
};


/** query root */
export type Query_RootWebinars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webinars_Order_By>>;
  where?: InputMaybe<Webinars_Bool_Exp>;
};


/** query root */
export type Query_RootWebinars_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "redirect" */
export type Redirect = {
  __typename?: 'redirect';
  id: Scalars['Int'];
  new_url?: Maybe<Scalars['String']>;
  old_url?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "redirect". All fields are combined with a logical 'AND'. */
export type Redirect_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Redirect_Bool_Exp>>>;
  _not?: InputMaybe<Redirect_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Redirect_Bool_Exp>>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  new_url?: InputMaybe<String_Comparison_Exp>;
  old_url?: InputMaybe<String_Comparison_Exp>;
};

/** ordering options when selecting data from "redirect" */
export type Redirect_Order_By = {
  id?: InputMaybe<Order_By>;
  new_url?: InputMaybe<Order_By>;
  old_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "redirect" */
export type Redirect_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "redirect" */
export enum Redirect_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NewUrl = 'new_url',
  /** column name */
  OldUrl = 'old_url'
}

export type RegisterWebinarOutput = {
  __typename?: 'registerWebinarOutput';
  data?: Maybe<Scalars['registerWebinarOutputObject']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type ResultUpdateRole = {
  __typename?: 'resultUpdateRole';
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type ReviewLessonOutput = {
  __typename?: 'reviewLessonOutput';
  data?: Maybe<Scalars['reviewLessonObject']>;
  error?: Maybe<Scalars['Boolean']>;
};

export type ReviewMagazineOutput = {
  __typename?: 'reviewMagazineOutput';
  data?: Maybe<Scalars['reviewMagazineObject']>;
  error?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "review_lesson" */
export type Review_Lesson = {
  __typename?: 'review_lesson';
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  lesson?: Maybe<Lesson>;
  lesson_id?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  seeder?: Maybe<Seeder>;
  seeder_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "review_lesson". All fields are combined with a logical 'AND'. */
export type Review_Lesson_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Review_Lesson_Bool_Exp>>>;
  _not?: InputMaybe<Review_Lesson_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Review_Lesson_Bool_Exp>>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lesson?: InputMaybe<Lesson_Bool_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
  point?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  seeder?: InputMaybe<Seeder_Bool_Exp>;
  seeder_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** ordering options when selecting data from "review_lesson" */
export type Review_Lesson_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson?: InputMaybe<Lesson_Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  seeder?: InputMaybe<Seeder_Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "review_lesson" */
export type Review_Lesson_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "review_lesson" */
export enum Review_Lesson_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  Point = 'point',
  /** column name */
  SeederId = 'seeder_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "review_magazine" */
export type Review_Magazine = {
  __typename?: 'review_magazine';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  magazine: Magazine;
  magazine_id: Scalars['Int'];
  point?: Maybe<Scalars['float8']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  seeder?: Maybe<Seeder>;
  seeder_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "review_magazine" */
export type Review_Magazine_Aggregate = {
  __typename?: 'review_magazine_aggregate';
  aggregate?: Maybe<Review_Magazine_Aggregate_Fields>;
  nodes: Array<Review_Magazine>;
};

/** aggregate fields of "review_magazine" */
export type Review_Magazine_Aggregate_Fields = {
  __typename?: 'review_magazine_aggregate_fields';
  avg?: Maybe<Review_Magazine_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Review_Magazine_Max_Fields>;
  min?: Maybe<Review_Magazine_Min_Fields>;
  stddev?: Maybe<Review_Magazine_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Magazine_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Magazine_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Magazine_Sum_Fields>;
  var_pop?: Maybe<Review_Magazine_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Magazine_Var_Samp_Fields>;
  variance?: Maybe<Review_Magazine_Variance_Fields>;
};


/** aggregate fields of "review_magazine" */
export type Review_Magazine_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "review_magazine" */
export type Review_Magazine_Aggregate_Order_By = {
  avg?: InputMaybe<Review_Magazine_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Magazine_Max_Order_By>;
  min?: InputMaybe<Review_Magazine_Min_Order_By>;
  stddev?: InputMaybe<Review_Magazine_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Review_Magazine_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Review_Magazine_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Review_Magazine_Sum_Order_By>;
  var_pop?: InputMaybe<Review_Magazine_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Review_Magazine_Var_Samp_Order_By>;
  variance?: InputMaybe<Review_Magazine_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Review_Magazine_Avg_Fields = {
  __typename?: 'review_magazine_avg_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "review_magazine" */
export type Review_Magazine_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "review_magazine". All fields are combined with a logical 'AND'. */
export type Review_Magazine_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Review_Magazine_Bool_Exp>>>;
  _not?: InputMaybe<Review_Magazine_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Review_Magazine_Bool_Exp>>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  magazine?: InputMaybe<Magazine_Bool_Exp>;
  magazine_id?: InputMaybe<Int_Comparison_Exp>;
  point?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  seeder?: InputMaybe<Seeder_Bool_Exp>;
  seeder_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Review_Magazine_Max_Fields = {
  __typename?: 'review_magazine_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  magazine_id?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  seeder_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "review_magazine" */
export type Review_Magazine_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Magazine_Min_Fields = {
  __typename?: 'review_magazine_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  magazine_id?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  seeder_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "review_magazine" */
export type Review_Magazine_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "review_magazine" */
export type Review_Magazine_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  magazine?: InputMaybe<Magazine_Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  seeder?: InputMaybe<Seeder_Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "review_magazine" */
export type Review_Magazine_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "review_magazine" */
export enum Review_Magazine_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MagazineId = 'magazine_id',
  /** column name */
  Point = 'point',
  /** column name */
  SeederId = 'seeder_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type Review_Magazine_Stddev_Fields = {
  __typename?: 'review_magazine_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "review_magazine" */
export type Review_Magazine_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Review_Magazine_Stddev_Pop_Fields = {
  __typename?: 'review_magazine_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "review_magazine" */
export type Review_Magazine_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Review_Magazine_Stddev_Samp_Fields = {
  __typename?: 'review_magazine_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "review_magazine" */
export type Review_Magazine_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Review_Magazine_Sum_Fields = {
  __typename?: 'review_magazine_sum_fields';
  id?: Maybe<Scalars['Int']>;
  magazine_id?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  status?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "review_magazine" */
export type Review_Magazine_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Review_Magazine_Var_Pop_Fields = {
  __typename?: 'review_magazine_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "review_magazine" */
export type Review_Magazine_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Review_Magazine_Var_Samp_Fields = {
  __typename?: 'review_magazine_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "review_magazine" */
export type Review_Magazine_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Review_Magazine_Variance_Fields = {
  __typename?: 'review_magazine_variance_fields';
  id?: Maybe<Scalars['Float']>;
  magazine_id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "review_magazine" */
export type Review_Magazine_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  magazine_id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** columns and relationships of "review_webinar" */
export type Review_Webinar = {
  __typename?: 'review_webinar';
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  point?: Maybe<Scalars['float8']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  seeder?: Maybe<Seeder>;
  seeder_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  webinar: Webinars;
  webinar_id: Scalars['Int'];
};

/** aggregated selection of "review_webinar" */
export type Review_Webinar_Aggregate = {
  __typename?: 'review_webinar_aggregate';
  aggregate?: Maybe<Review_Webinar_Aggregate_Fields>;
  nodes: Array<Review_Webinar>;
};

/** aggregate fields of "review_webinar" */
export type Review_Webinar_Aggregate_Fields = {
  __typename?: 'review_webinar_aggregate_fields';
  avg?: Maybe<Review_Webinar_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Review_Webinar_Max_Fields>;
  min?: Maybe<Review_Webinar_Min_Fields>;
  stddev?: Maybe<Review_Webinar_Stddev_Fields>;
  stddev_pop?: Maybe<Review_Webinar_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Review_Webinar_Stddev_Samp_Fields>;
  sum?: Maybe<Review_Webinar_Sum_Fields>;
  var_pop?: Maybe<Review_Webinar_Var_Pop_Fields>;
  var_samp?: Maybe<Review_Webinar_Var_Samp_Fields>;
  variance?: Maybe<Review_Webinar_Variance_Fields>;
};


/** aggregate fields of "review_webinar" */
export type Review_Webinar_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "review_webinar" */
export type Review_Webinar_Aggregate_Order_By = {
  avg?: InputMaybe<Review_Webinar_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Webinar_Max_Order_By>;
  min?: InputMaybe<Review_Webinar_Min_Order_By>;
  stddev?: InputMaybe<Review_Webinar_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Review_Webinar_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Review_Webinar_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Review_Webinar_Sum_Order_By>;
  var_pop?: InputMaybe<Review_Webinar_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Review_Webinar_Var_Samp_Order_By>;
  variance?: InputMaybe<Review_Webinar_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Review_Webinar_Avg_Fields = {
  __typename?: 'review_webinar_avg_fields';
  id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  webinar_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "review_webinar" */
export type Review_Webinar_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "review_webinar". All fields are combined with a logical 'AND'. */
export type Review_Webinar_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Review_Webinar_Bool_Exp>>>;
  _not?: InputMaybe<Review_Webinar_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Review_Webinar_Bool_Exp>>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  point?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  seeder?: InputMaybe<Seeder_Bool_Exp>;
  seeder_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  webinar?: InputMaybe<Webinars_Bool_Exp>;
  webinar_id?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Review_Webinar_Max_Fields = {
  __typename?: 'review_webinar_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  seeder_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  webinar_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "review_webinar" */
export type Review_Webinar_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Webinar_Min_Fields = {
  __typename?: 'review_webinar_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  seeder_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  webinar_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "review_webinar" */
export type Review_Webinar_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "review_webinar" */
export type Review_Webinar_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  seeder?: InputMaybe<Seeder_Order_By>;
  seeder_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  webinar?: InputMaybe<Webinars_Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "review_webinar" */
export type Review_Webinar_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "review_webinar" */
export enum Review_Webinar_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Point = 'point',
  /** column name */
  SeederId = 'seeder_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WebinarId = 'webinar_id'
}

/** aggregate stddev on columns */
export type Review_Webinar_Stddev_Fields = {
  __typename?: 'review_webinar_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  webinar_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "review_webinar" */
export type Review_Webinar_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Review_Webinar_Stddev_Pop_Fields = {
  __typename?: 'review_webinar_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  webinar_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "review_webinar" */
export type Review_Webinar_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Review_Webinar_Stddev_Samp_Fields = {
  __typename?: 'review_webinar_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  webinar_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "review_webinar" */
export type Review_Webinar_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Review_Webinar_Sum_Fields = {
  __typename?: 'review_webinar_sum_fields';
  id?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  status?: Maybe<Scalars['Int']>;
  webinar_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "review_webinar" */
export type Review_Webinar_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Review_Webinar_Var_Pop_Fields = {
  __typename?: 'review_webinar_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  webinar_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "review_webinar" */
export type Review_Webinar_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Review_Webinar_Var_Samp_Fields = {
  __typename?: 'review_webinar_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  webinar_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "review_webinar" */
export type Review_Webinar_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Review_Webinar_Variance_Fields = {
  __typename?: 'review_webinar_variance_fields';
  id?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  webinar_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "review_webinar" */
export type Review_Webinar_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  webinar_id?: InputMaybe<Order_By>;
};

export type Search_Blog_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Comment_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Comment_Exchange_Args = {
  content_key?: InputMaybe<Scalars['String']>;
};

export type Search_Course_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Course_Category_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Course_Goal_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Document_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Exchange_Args = {
  description_key?: InputMaybe<Scalars['String']>;
  name_key?: InputMaybe<Scalars['String']>;
  trading_platform_key?: InputMaybe<Scalars['String']>;
  trading_products_key?: InputMaybe<Scalars['String']>;
};

export type Search_Insight_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Knowledge_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Learning_Path_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Lesson_Args = {
  course_name?: InputMaybe<Scalars['String']>;
  lesson_name?: InputMaybe<Scalars['String']>;
};

export type Search_Lesson_Category_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Lesson_Classify_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Level_Course_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_News_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Series_Args = {
  search?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "seeder" */
export type Seeder = {
  __typename?: 'seeder';
  avatar?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "seeder" */
export type Seeder_Aggregate = {
  __typename?: 'seeder_aggregate';
  aggregate?: Maybe<Seeder_Aggregate_Fields>;
  nodes: Array<Seeder>;
};

/** aggregate fields of "seeder" */
export type Seeder_Aggregate_Fields = {
  __typename?: 'seeder_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Seeder_Max_Fields>;
  min?: Maybe<Seeder_Min_Fields>;
};


/** aggregate fields of "seeder" */
export type Seeder_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Seeder_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "seeder" */
export type Seeder_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Seeder_Max_Order_By>;
  min?: InputMaybe<Seeder_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "seeder". All fields are combined with a logical 'AND'. */
export type Seeder_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Seeder_Bool_Exp>>>;
  _not?: InputMaybe<Seeder_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Seeder_Bool_Exp>>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Seeder_Max_Fields = {
  __typename?: 'seeder_max_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "seeder" */
export type Seeder_Max_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Seeder_Min_Fields = {
  __typename?: 'seeder_min_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "seeder" */
export type Seeder_Min_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "seeder" */
export type Seeder_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "seeder" */
export type Seeder_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "seeder" */
export enum Seeder_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type SendAllNotificationsInput = {
  body?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SendAllNotificationsOutput = {
  __typename?: 'sendAllNotificationsOutput';
  success?: Maybe<Scalars['Boolean']>;
};

export type SendEmailForAdminOutput = {
  __typename?: 'sendEmailForAdminOutput';
  data?: Maybe<Scalars['sendEmailForAdminOutputObject']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type SendNotificationsWhenHaveWebinarOutput = {
  __typename?: 'sendNotificationsWhenHaveWebinarOutput';
  success?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "series" */
export type Series = {
  __typename?: 'series';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
  seo_description?: Maybe<Scalars['String']>;
  seo_title?: Maybe<Scalars['String']>;
  /** An array relationship */
  series_course_categories: Array<Series_Course_Category>;
  /** An array relationship */
  series_knowledges: Array<Series_Knowledge>;
  slug: Scalars['String'];
  thumbnail?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "series" */
export type SeriesSeries_Course_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Series_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Course_Category_Order_By>>;
  where?: InputMaybe<Series_Course_Category_Bool_Exp>;
};


/** columns and relationships of "series" */
export type SeriesSeries_KnowledgesArgs = {
  distinct_on?: InputMaybe<Array<Series_Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Knowledge_Order_By>>;
  where?: InputMaybe<Series_Knowledge_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "series". All fields are combined with a logical 'AND'. */
export type Series_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Series_Bool_Exp>>>;
  _not?: InputMaybe<Series_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Series_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  seo_description?: InputMaybe<String_Comparison_Exp>;
  seo_title?: InputMaybe<String_Comparison_Exp>;
  series_course_categories?: InputMaybe<Series_Course_Category_Bool_Exp>;
  series_knowledges?: InputMaybe<Series_Knowledge_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  thumbnail?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "series_course_category" */
export type Series_Course_Category = {
  __typename?: 'series_course_category';
  /** An object relationship */
  courseCategoryByCourseCategory: Course_Category;
  course_category_id: Scalars['uuid'];
  id: Scalars['Int'];
  /** An object relationship */
  series: Series;
  series_id: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "series_course_category". All fields are combined with a logical 'AND'. */
export type Series_Course_Category_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Series_Course_Category_Bool_Exp>>>;
  _not?: InputMaybe<Series_Course_Category_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Series_Course_Category_Bool_Exp>>>;
  courseCategoryByCourseCategory?: InputMaybe<Course_Category_Bool_Exp>;
  course_category_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  series?: InputMaybe<Series_Bool_Exp>;
  series_id?: InputMaybe<Int_Comparison_Exp>;
};

/** ordering options when selecting data from "series_course_category" */
export type Series_Course_Category_Order_By = {
  courseCategoryByCourseCategory?: InputMaybe<Course_Category_Order_By>;
  course_category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  series?: InputMaybe<Series_Order_By>;
  series_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "series_course_category" */
export type Series_Course_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "series_course_category" */
export enum Series_Course_Category_Select_Column {
  /** column name */
  CourseCategoryId = 'course_category_id',
  /** column name */
  Id = 'id',
  /** column name */
  SeriesId = 'series_id'
}

/** columns and relationships of "series_knowledge" */
export type Series_Knowledge = {
  __typename?: 'series_knowledge';
  id: Scalars['Int'];
  /** An object relationship */
  knowledge: Knowledge;
  knowledge_id: Scalars['Int'];
  knowledge_order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  series: Series;
  series_id: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "series_knowledge". All fields are combined with a logical 'AND'. */
export type Series_Knowledge_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Series_Knowledge_Bool_Exp>>>;
  _not?: InputMaybe<Series_Knowledge_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Series_Knowledge_Bool_Exp>>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  knowledge?: InputMaybe<Knowledge_Bool_Exp>;
  knowledge_id?: InputMaybe<Int_Comparison_Exp>;
  knowledge_order?: InputMaybe<Int_Comparison_Exp>;
  series?: InputMaybe<Series_Bool_Exp>;
  series_id?: InputMaybe<Int_Comparison_Exp>;
};

/** ordering options when selecting data from "series_knowledge" */
export type Series_Knowledge_Order_By = {
  id?: InputMaybe<Order_By>;
  knowledge?: InputMaybe<Knowledge_Order_By>;
  knowledge_id?: InputMaybe<Order_By>;
  knowledge_order?: InputMaybe<Order_By>;
  series?: InputMaybe<Series_Order_By>;
  series_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "series_knowledge" */
export type Series_Knowledge_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "series_knowledge" */
export enum Series_Knowledge_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  KnowledgeId = 'knowledge_id',
  /** column name */
  KnowledgeOrder = 'knowledge_order',
  /** column name */
  SeriesId = 'series_id'
}

/** ordering options when selecting data from "series" */
export type Series_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  seo_description?: InputMaybe<Order_By>;
  seo_title?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  thumbnail?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "series" */
export type Series_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "series" */
export enum Series_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  SeoDescription = 'seo_description',
  /** column name */
  SeoTitle = 'seo_title',
  /** column name */
  Slug = 'slug',
  /** column name */
  Thumbnail = 'thumbnail',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "advertisement" */
  advertisement: Array<Advertisement>;
  /** fetch aggregated fields from the table: "advertisement" */
  advertisement_aggregate: Advertisement_Aggregate;
  /** fetch data from the table: "advertisement" using primary key columns */
  advertisement_by_pk?: Maybe<Advertisement>;
  /** fetch data from the table: "author" */
  author: Array<Author>;
  /** fetch data from the table: "author" using primary key columns */
  author_by_pk?: Maybe<Author>;
  /** fetch data from the table: "blog" */
  blog: Array<Blog>;
  /** fetch aggregated fields from the table: "blog" */
  blog_aggregate: Blog_Aggregate;
  /** fetch data from the table: "blog" using primary key columns */
  blog_by_pk?: Maybe<Blog>;
  /** fetch data from the table: "blog_category" */
  blog_category: Array<Blog_Category>;
  /** fetch aggregated fields from the table: "blog_category" */
  blog_category_aggregate: Blog_Category_Aggregate;
  /** fetch data from the table: "blog_category" using primary key columns */
  blog_category_by_pk?: Maybe<Blog_Category>;
  /** fetch data from the table: "comment_exchange" */
  comment_exchange: Array<Comment_Exchange>;
  /** fetch aggregated fields from the table: "comment_exchange" */
  comment_exchange_aggregate: Comment_Exchange_Aggregate;
  /** fetch data from the table: "comment_exchange" using primary key columns */
  comment_exchange_by_pk?: Maybe<Comment_Exchange>;
  /** fetch data from the table: "comment_type" */
  comment_type: Array<Comment_Type>;
  /** fetch data from the table: "comment_type" using primary key columns */
  comment_type_by_pk?: Maybe<Comment_Type>;
  /** fetch data from the table: "course" */
  course: Array<Course>;
  /** fetch aggregated fields from the table: "course" */
  course_aggregate: Course_Aggregate;
  /** fetch data from the table: "course" using primary key columns */
  course_by_pk?: Maybe<Course>;
  /** fetch data from the table: "course_category" */
  course_category: Array<Course_Category>;
  /** fetch aggregated fields from the table: "course_category" */
  course_category_aggregate: Course_Category_Aggregate;
  /** fetch data from the table: "course_category" using primary key columns */
  course_category_by_pk?: Maybe<Course_Category>;
  /** fetch data from the table: "course_course_category" */
  course_course_category: Array<Course_Course_Category>;
  /** fetch aggregated fields from the table: "course_course_category" */
  course_course_category_aggregate: Course_Course_Category_Aggregate;
  /** fetch data from the table: "course_course_category" using primary key columns */
  course_course_category_by_pk?: Maybe<Course_Course_Category>;
  /** fetch data from the table: "course_goal" */
  course_goal: Array<Course_Goal>;
  /** fetch aggregated fields from the table: "course_goal" */
  course_goal_aggregate: Course_Goal_Aggregate;
  /** fetch data from the table: "course_goal" using primary key columns */
  course_goal_by_pk?: Maybe<Course_Goal>;
  /** fetch data from the table: "course_goal_map" */
  course_goal_map: Array<Course_Goal_Map>;
  /** fetch aggregated fields from the table: "course_goal_map" */
  course_goal_map_aggregate: Course_Goal_Map_Aggregate;
  /** fetch data from the table: "course_goal_map" using primary key columns */
  course_goal_map_by_pk?: Maybe<Course_Goal_Map>;
  /** fetch data from the table: "course_lesson_view" */
  course_lesson_view: Array<Course_Lesson_View>;
  /** fetch aggregated fields from the table: "course_lesson_view" */
  course_lesson_view_aggregate: Course_Lesson_View_Aggregate;
  /** fetch data from the table: "course_rating" */
  course_rating: Array<Course_Rating>;
  /** fetch aggregated fields from the table: "course_rating" */
  course_rating_aggregate: Course_Rating_Aggregate;
  /** fetch data from the table: "course_rating" using primary key columns */
  course_rating_by_pk?: Maybe<Course_Rating>;
  /** fetch data from the table: "document" */
  document: Array<Document>;
  /** fetch aggregated fields from the table: "document" */
  document_aggregate: Document_Aggregate;
  /** fetch data from the table: "document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** execute function "draft_comment_exchange" which returns "comment_exchange" */
  draft_comment_exchange: Array<Comment_Exchange>;
  /** execute function "draft_comment_exchange" and query aggregates on result of table type "comment_exchange" */
  draft_comment_exchange_aggregate: Comment_Exchange_Aggregate;
  /** fetch data from the table: "exchange" */
  exchange: Array<Exchange>;
  /** fetch aggregated fields from the table: "exchange" */
  exchange_aggregate: Exchange_Aggregate;
  /** fetch data from the table: "exchange" using primary key columns */
  exchange_by_pk?: Maybe<Exchange>;
  /** perform the action: "findItems" */
  findItems: SearchOutput;
  /** perform the action: "getLearnedCourse" */
  getLearnedCourse?: Maybe<GetLearnedCourseOutput>;
  /** perform the action: "getLearnedLesson" */
  getLearnedLesson?: Maybe<GetLearnedLessonOutput>;
  /** perform the action: "getListWebinarForUser" */
  getListWebinarForUser?: Maybe<GetListWebinarForUserOutPut>;
  /** perform the action: "getMediaURL" */
  getMediaURL?: Maybe<GetMediaUrlOutput>;
  /** perform the action: "hotNews" */
  hotNews?: Maybe<HotNewsRespnose>;
  /** fetch data from the table: "insight" */
  insight: Array<Insight>;
  /** fetch data from the table: "insight" using primary key columns */
  insight_by_pk?: Maybe<Insight>;
  /** fetch data from the table: "knowledge" */
  knowledge: Array<Knowledge>;
  /** fetch data from the table: "knowledge_action" */
  knowledge_action: Array<Knowledge_Action>;
  /** fetch data from the table: "knowledge_action" using primary key columns */
  knowledge_action_by_pk?: Maybe<Knowledge_Action>;
  /** fetch aggregated fields from the table: "knowledge" */
  knowledge_aggregate: Knowledge_Aggregate;
  /** fetch data from the table: "knowledge" using primary key columns */
  knowledge_by_pk?: Maybe<Knowledge>;
  /** fetch data from the table: "learning_path" */
  learning_path: Array<Learning_Path>;
  /** fetch aggregated fields from the table: "learning_path" */
  learning_path_aggregate: Learning_Path_Aggregate;
  /** fetch data from the table: "learning_path" using primary key columns */
  learning_path_by_pk?: Maybe<Learning_Path>;
  /** fetch data from the table: "learning_process" */
  learning_process: Array<Learning_Process>;
  /** fetch aggregated fields from the table: "learning_process" */
  learning_process_aggregate: Learning_Process_Aggregate;
  /** fetch data from the table: "learning_process" using primary key columns */
  learning_process_by_pk?: Maybe<Learning_Process>;
  /** fetch data from the table: "lesson" */
  lesson: Array<Lesson>;
  /** fetch aggregated fields from the table: "lesson" */
  lesson_aggregate: Lesson_Aggregate;
  /** fetch data from the table: "lesson" using primary key columns */
  lesson_by_pk?: Maybe<Lesson>;
  /** fetch data from the table: "lesson_category" */
  lesson_category: Array<Lesson_Category>;
  /** fetch aggregated fields from the table: "lesson_category" */
  lesson_category_aggregate: Lesson_Category_Aggregate;
  /** fetch data from the table: "lesson_category" using primary key columns */
  lesson_category_by_pk?: Maybe<Lesson_Category>;
  /** fetch data from the table: "lesson_classify" */
  lesson_classify: Array<Lesson_Classify>;
  /** fetch aggregated fields from the table: "lesson_classify" */
  lesson_classify_aggregate: Lesson_Classify_Aggregate;
  /** fetch data from the table: "lesson_classify" using primary key columns */
  lesson_classify_by_pk?: Maybe<Lesson_Classify>;
  /** fetch data from the table: "lesson_comment_web" */
  lesson_comment_web: Array<Lesson_Comment_Web>;
  /** fetch aggregated fields from the table: "lesson_comment_web" */
  lesson_comment_web_aggregate: Lesson_Comment_Web_Aggregate;
  /** fetch data from the table: "lesson_comment_web" using primary key columns */
  lesson_comment_web_by_pk?: Maybe<Lesson_Comment_Web>;
  /** fetch data from the table: "lesson_course" */
  lesson_course: Array<Lesson_Course>;
  /** fetch aggregated fields from the table: "lesson_course" */
  lesson_course_aggregate: Lesson_Course_Aggregate;
  /** fetch data from the table: "lesson_course" using primary key columns */
  lesson_course_by_pk?: Maybe<Lesson_Course>;
  /** fetch data from the table: "lesson_course_view" */
  lesson_course_view: Array<Lesson_Course_View>;
  /** fetch aggregated fields from the table: "lesson_course_view" */
  lesson_course_view_aggregate: Lesson_Course_View_Aggregate;
  /** fetch data from the table: "lesson_document_map" */
  lesson_document_map: Array<Lesson_Document_Map>;
  /** fetch aggregated fields from the table: "lesson_document_map" */
  lesson_document_map_aggregate: Lesson_Document_Map_Aggregate;
  /** fetch data from the table: "lesson_document_map" using primary key columns */
  lesson_document_map_by_pk?: Maybe<Lesson_Document_Map>;
  /** fetch data from the table: "lesson_rating" */
  lesson_rating: Array<Lesson_Rating>;
  /** fetch aggregated fields from the table: "lesson_rating" */
  lesson_rating_aggregate: Lesson_Rating_Aggregate;
  /** fetch data from the table: "lesson_rating" using primary key columns */
  lesson_rating_by_pk?: Maybe<Lesson_Rating>;
  /** fetch data from the table: "level_course" */
  level_course: Array<Level_Course>;
  /** fetch aggregated fields from the table: "level_course" */
  level_course_aggregate: Level_Course_Aggregate;
  /** fetch data from the table: "level_course" using primary key columns */
  level_course_by_pk?: Maybe<Level_Course>;
  /** fetch data from the table: "like_count" */
  like_count: Array<Like_Count>;
  /** fetch aggregated fields from the table: "like_count" */
  like_count_aggregate: Like_Count_Aggregate;
  /** fetch data from the table: "like_count" using primary key columns */
  like_count_by_pk?: Maybe<Like_Count>;
  /** fetch data from the table: "magazine" */
  magazine: Array<Magazine>;
  /** fetch aggregated fields from the table: "magazine" */
  magazine_aggregate: Magazine_Aggregate;
  /** fetch data from the table: "magazine" using primary key columns */
  magazine_by_pk?: Maybe<Magazine>;
  /** fetch data from the table: "magazine_comment_web" */
  magazine_comment_web: Array<Magazine_Comment_Web>;
  /** fetch aggregated fields from the table: "magazine_comment_web" */
  magazine_comment_web_aggregate: Magazine_Comment_Web_Aggregate;
  /** fetch data from the table: "magazine_comment_web" using primary key columns */
  magazine_comment_web_by_pk?: Maybe<Magazine_Comment_Web>;
  /** fetch data from the table: "news" */
  news: Array<News>;
  /** fetch aggregated fields from the table: "news" */
  news_aggregate: News_Aggregate;
  /** fetch data from the table: "news" using primary key columns */
  news_by_pk?: Maybe<News>;
  /** fetch data from the table: "news_category" */
  news_category: Array<News_Category>;
  /** fetch aggregated fields from the table: "news_category" */
  news_category_aggregate: News_Category_Aggregate;
  /** fetch data from the table: "news_category" using primary key columns */
  news_category_by_pk?: Maybe<News_Category>;
  /** perform the action: "news_feed" */
  news_feed?: Maybe<Array<Maybe<NewsFeed>>>;
  /** fetch data from the table: "profile" */
  profile: Array<Profile>;
  /** fetch aggregated fields from the table: "profile" */
  profile_aggregate: Profile_Aggregate;
  /** fetch data from the table: "profile" using primary key columns */
  profile_by_pk?: Maybe<Profile>;
  /** fetch data from the table: "redirect" */
  redirect: Array<Redirect>;
  /** fetch data from the table: "redirect" using primary key columns */
  redirect_by_pk?: Maybe<Redirect>;
  /** fetch data from the table: "review_lesson" */
  review_lesson: Array<Review_Lesson>;
  /** fetch data from the table: "review_lesson" using primary key columns */
  review_lesson_by_pk?: Maybe<Review_Lesson>;
  /** fetch data from the table: "review_magazine" */
  review_magazine: Array<Review_Magazine>;
  /** fetch aggregated fields from the table: "review_magazine" */
  review_magazine_aggregate: Review_Magazine_Aggregate;
  /** fetch data from the table: "review_magazine" using primary key columns */
  review_magazine_by_pk?: Maybe<Review_Magazine>;
  /** fetch data from the table: "review_webinar" */
  review_webinar: Array<Review_Webinar>;
  /** fetch aggregated fields from the table: "review_webinar" */
  review_webinar_aggregate: Review_Webinar_Aggregate;
  /** fetch data from the table: "review_webinar" using primary key columns */
  review_webinar_by_pk?: Maybe<Review_Webinar>;
  /** execute function "search_blog" which returns "blog" */
  search_blog: Array<Blog>;
  /** execute function "search_blog" and query aggregates on result of table type "blog" */
  search_blog_aggregate: Blog_Aggregate;
  /** execute function "search_comment" which returns "comment_exchange" */
  search_comment: Array<Comment_Exchange>;
  /** execute function "search_comment" and query aggregates on result of table type "comment_exchange" */
  search_comment_aggregate: Comment_Exchange_Aggregate;
  /** execute function "search_comment_exchange" which returns "comment_exchange" */
  search_comment_exchange: Array<Comment_Exchange>;
  /** execute function "search_comment_exchange" and query aggregates on result of table type "comment_exchange" */
  search_comment_exchange_aggregate: Comment_Exchange_Aggregate;
  /** execute function "search_course" which returns "course" */
  search_course: Array<Course>;
  /** execute function "search_course" and query aggregates on result of table type "course" */
  search_course_aggregate: Course_Aggregate;
  /** execute function "search_course_category" which returns "course_category" */
  search_course_category: Array<Course_Category>;
  /** execute function "search_course_category" and query aggregates on result of table type "course_category" */
  search_course_category_aggregate: Course_Category_Aggregate;
  /** execute function "search_course_goal" which returns "course_goal" */
  search_course_goal: Array<Course_Goal>;
  /** execute function "search_course_goal" and query aggregates on result of table type "course_goal" */
  search_course_goal_aggregate: Course_Goal_Aggregate;
  /** execute function "search_document" which returns "document" */
  search_document: Array<Document>;
  /** execute function "search_document" and query aggregates on result of table type "document" */
  search_document_aggregate: Document_Aggregate;
  /** execute function "search_exchange" which returns "exchange" */
  search_exchange: Array<Exchange>;
  /** execute function "search_exchange" and query aggregates on result of table type "exchange" */
  search_exchange_aggregate: Exchange_Aggregate;
  /** execute function "search_insight" which returns "insight" */
  search_insight: Array<Insight>;
  /** execute function "search_knowledge" which returns "knowledge" */
  search_knowledge: Array<Knowledge>;
  /** execute function "search_knowledge" and query aggregates on result of table type "knowledge" */
  search_knowledge_aggregate: Knowledge_Aggregate;
  /** execute function "search_learning_path" which returns "learning_path" */
  search_learning_path: Array<Learning_Path>;
  /** execute function "search_learning_path" and query aggregates on result of table type "learning_path" */
  search_learning_path_aggregate: Learning_Path_Aggregate;
  /** execute function "search_lesson" which returns "lesson" */
  search_lesson: Array<Lesson>;
  /** execute function "search_lesson" and query aggregates on result of table type "lesson" */
  search_lesson_aggregate: Lesson_Aggregate;
  /** execute function "search_lesson_category" which returns "lesson_category" */
  search_lesson_category: Array<Lesson_Category>;
  /** execute function "search_lesson_category" and query aggregates on result of table type "lesson_category" */
  search_lesson_category_aggregate: Lesson_Category_Aggregate;
  /** execute function "search_lesson_classify" which returns "lesson_classify" */
  search_lesson_classify: Array<Lesson_Classify>;
  /** execute function "search_lesson_classify" and query aggregates on result of table type "lesson_classify" */
  search_lesson_classify_aggregate: Lesson_Classify_Aggregate;
  /** execute function "search_level_course" which returns "level_course" */
  search_level_course: Array<Level_Course>;
  /** execute function "search_level_course" and query aggregates on result of table type "level_course" */
  search_level_course_aggregate: Level_Course_Aggregate;
  /** execute function "search_news" which returns "news" */
  search_news: Array<News>;
  /** execute function "search_news" and query aggregates on result of table type "news" */
  search_news_aggregate: News_Aggregate;
  /** execute function "search_series" which returns "series" */
  search_series: Array<Series>;
  /** fetch data from the table: "seeder" */
  seeder: Array<Seeder>;
  /** fetch aggregated fields from the table: "seeder" */
  seeder_aggregate: Seeder_Aggregate;
  /** fetch data from the table: "seeder" using primary key columns */
  seeder_by_pk?: Maybe<Seeder>;
  /** fetch data from the table: "series" */
  series: Array<Series>;
  /** fetch data from the table: "series" using primary key columns */
  series_by_pk?: Maybe<Series>;
  /** fetch data from the table: "series_course_category" */
  series_course_category: Array<Series_Course_Category>;
  /** fetch data from the table: "series_course_category" using primary key columns */
  series_course_category_by_pk?: Maybe<Series_Course_Category>;
  /** fetch data from the table: "series_knowledge" */
  series_knowledge: Array<Series_Knowledge>;
  /** fetch data from the table: "series_knowledge" using primary key columns */
  series_knowledge_by_pk?: Maybe<Series_Knowledge>;
  /** fetch data from the table: "video_watched" */
  video_watched: Array<Video_Watched>;
  /** fetch data from the table: "video_watched" using primary key columns */
  video_watched_by_pk?: Maybe<Video_Watched>;
  /** perform the action: "webinar" */
  webinar?: Maybe<Array<Maybe<Webinar>>>;
  /** fetch data from the table: "webinars" */
  webinars: Array<Webinars>;
  /** fetch aggregated fields from the table: "webinars" */
  webinars_aggregate: Webinars_Aggregate;
  /** fetch data from the table: "webinars" using primary key columns */
  webinars_by_pk?: Maybe<Webinars>;
};


/** subscription root */
export type Subscription_RootAdvertisementArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAdvertisement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAdvertisement_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootAuthorArgs = {
  distinct_on?: InputMaybe<Array<Author_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Author_Order_By>>;
  where?: InputMaybe<Author_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuthor_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootBlogArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlog_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootBlog_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Blog_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Category_Order_By>>;
  where?: InputMaybe<Blog_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlog_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Category_Order_By>>;
  where?: InputMaybe<Blog_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlog_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootComment_ExchangeArgs = {
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComment_Exchange_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComment_Exchange_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootComment_TypeArgs = {
  distinct_on?: InputMaybe<Array<Comment_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Type_Order_By>>;
  where?: InputMaybe<Comment_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComment_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCourseArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootCourse_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Category_Order_By>>;
  where?: InputMaybe<Course_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Category_Order_By>>;
  where?: InputMaybe<Course_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Category_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCourse_Course_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Course_Category_Order_By>>;
  where?: InputMaybe<Course_Course_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Course_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Course_Category_Order_By>>;
  where?: InputMaybe<Course_Course_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Course_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootCourse_GoalArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Order_By>>;
  where?: InputMaybe<Course_Goal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Goal_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Order_By>>;
  where?: InputMaybe<Course_Goal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Goal_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootCourse_Goal_MapArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Map_Order_By>>;
  where?: InputMaybe<Course_Goal_Map_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Goal_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Goal_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Map_Order_By>>;
  where?: InputMaybe<Course_Goal_Map_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Goal_Map_By_PkArgs = {
  course_id: Scalars['Int'];
  goal_id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootCourse_Lesson_ViewArgs = {
  distinct_on?: InputMaybe<Array<Course_Lesson_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Lesson_View_Order_By>>;
  where?: InputMaybe<Course_Lesson_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Lesson_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Lesson_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Lesson_View_Order_By>>;
  where?: InputMaybe<Course_Lesson_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_RatingArgs = {
  distinct_on?: InputMaybe<Array<Course_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Rating_Order_By>>;
  where?: InputMaybe<Course_Rating_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Course_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Rating_Order_By>>;
  where?: InputMaybe<Course_Rating_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCourse_Rating_By_PkArgs = {
  course_id: Scalars['Int'];
  user_email: Scalars['String'];
};


/** subscription root */
export type Subscription_RootDocumentArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDocument_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootDraft_Comment_ExchangeArgs = {
  args: Draft_Comment_Exchange_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDraft_Comment_Exchange_AggregateArgs = {
  args: Draft_Comment_Exchange_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootExchangeArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Order_By>>;
  where?: InputMaybe<Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootExchange_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Order_By>>;
  where?: InputMaybe<Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootExchange_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootFindItemsArgs = {
  query: SearchInput;
};


/** subscription root */
export type Subscription_RootGetLearnedCourseArgs = {
  level?: InputMaybe<Scalars['String']>;
};


/** subscription root */
export type Subscription_RootGetLearnedLessonArgs = {
  course_id?: InputMaybe<Scalars['Int']>;
};


/** subscription root */
export type Subscription_RootGetMediaUrlArgs = {
  namefile?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


/** subscription root */
export type Subscription_RootInsightArgs = {
  distinct_on?: InputMaybe<Array<Insight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insight_Order_By>>;
  where?: InputMaybe<Insight_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsight_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootKnowledgeArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Order_By>>;
  where?: InputMaybe<Knowledge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKnowledge_ActionArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Action_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Action_Order_By>>;
  where?: InputMaybe<Knowledge_Action_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKnowledge_Action_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootKnowledge_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Order_By>>;
  where?: InputMaybe<Knowledge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootKnowledge_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootLearning_PathArgs = {
  distinct_on?: InputMaybe<Array<Learning_Path_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Path_Order_By>>;
  where?: InputMaybe<Learning_Path_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLearning_Path_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Path_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Path_Order_By>>;
  where?: InputMaybe<Learning_Path_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLearning_Path_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootLearning_ProcessArgs = {
  distinct_on?: InputMaybe<Array<Learning_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Process_Order_By>>;
  where?: InputMaybe<Learning_Process_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLearning_Process_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Process_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Process_Order_By>>;
  where?: InputMaybe<Learning_Process_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLearning_Process_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootLessonArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootLesson_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Category_Order_By>>;
  where?: InputMaybe<Lesson_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Category_Order_By>>;
  where?: InputMaybe<Lesson_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootLesson_ClassifyArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Classify_Order_By>>;
  where?: InputMaybe<Lesson_Classify_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Classify_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Classify_Order_By>>;
  where?: InputMaybe<Lesson_Classify_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Classify_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootLesson_Comment_WebArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Comment_Web_Order_By>>;
  where?: InputMaybe<Lesson_Comment_Web_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Comment_Web_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Comment_Web_Order_By>>;
  where?: InputMaybe<Lesson_Comment_Web_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Comment_Web_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootLesson_CourseArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_Order_By>>;
  where?: InputMaybe<Lesson_Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Course_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_Order_By>>;
  where?: InputMaybe<Lesson_Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Course_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootLesson_Course_ViewArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_View_Order_By>>;
  where?: InputMaybe<Lesson_Course_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Course_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Course_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Course_View_Order_By>>;
  where?: InputMaybe<Lesson_Course_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Document_MapArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Document_Map_Order_By>>;
  where?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Document_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Document_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Document_Map_Order_By>>;
  where?: InputMaybe<Lesson_Document_Map_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Document_Map_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootLesson_RatingArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Rating_Order_By>>;
  where?: InputMaybe<Lesson_Rating_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Rating_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lesson_Rating_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Rating_Order_By>>;
  where?: InputMaybe<Lesson_Rating_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLesson_Rating_By_PkArgs = {
  lesson_id: Scalars['Int'];
  user_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootLevel_CourseArgs = {
  distinct_on?: InputMaybe<Array<Level_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Level_Course_Order_By>>;
  where?: InputMaybe<Level_Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLevel_Course_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Level_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Level_Course_Order_By>>;
  where?: InputMaybe<Level_Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLevel_Course_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootLike_CountArgs = {
  distinct_on?: InputMaybe<Array<Like_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Like_Count_Order_By>>;
  where?: InputMaybe<Like_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLike_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Like_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Like_Count_Order_By>>;
  where?: InputMaybe<Like_Count_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLike_Count_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootMagazineArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Order_By>>;
  where?: InputMaybe<Magazine_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMagazine_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Order_By>>;
  where?: InputMaybe<Magazine_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMagazine_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootMagazine_Comment_WebArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Comment_Web_Order_By>>;
  where?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMagazine_Comment_Web_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Magazine_Comment_Web_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Magazine_Comment_Web_Order_By>>;
  where?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootMagazine_Comment_Web_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootNewsArgs = {
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootNews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootNews_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootNews_CategoryArgs = {
  distinct_on?: InputMaybe<Array<News_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Category_Order_By>>;
  where?: InputMaybe<News_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootNews_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<News_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Category_Order_By>>;
  where?: InputMaybe<News_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootNews_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootProfileArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profile_Order_By>>;
  where?: InputMaybe<Profile_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProfile_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootRedirectArgs = {
  distinct_on?: InputMaybe<Array<Redirect_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Redirect_Order_By>>;
  where?: InputMaybe<Redirect_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRedirect_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootReview_LessonArgs = {
  distinct_on?: InputMaybe<Array<Review_Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Lesson_Order_By>>;
  where?: InputMaybe<Review_Lesson_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReview_Lesson_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootReview_MagazineArgs = {
  distinct_on?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Magazine_Order_By>>;
  where?: InputMaybe<Review_Magazine_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReview_Magazine_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Magazine_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Magazine_Order_By>>;
  where?: InputMaybe<Review_Magazine_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReview_Magazine_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootReview_WebinarArgs = {
  distinct_on?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Webinar_Order_By>>;
  where?: InputMaybe<Review_Webinar_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReview_Webinar_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Webinar_Order_By>>;
  where?: InputMaybe<Review_Webinar_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReview_Webinar_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootSearch_BlogArgs = {
  args: Search_Blog_Args;
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Blog_AggregateArgs = {
  args: Search_Blog_Args;
  distinct_on?: InputMaybe<Array<Blog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Order_By>>;
  where?: InputMaybe<Blog_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_CommentArgs = {
  args: Search_Comment_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Comment_AggregateArgs = {
  args: Search_Comment_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Comment_ExchangeArgs = {
  args: Search_Comment_Exchange_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Comment_Exchange_AggregateArgs = {
  args: Search_Comment_Exchange_Args;
  distinct_on?: InputMaybe<Array<Comment_Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Exchange_Order_By>>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_CourseArgs = {
  args: Search_Course_Args;
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Course_AggregateArgs = {
  args: Search_Course_Args;
  distinct_on?: InputMaybe<Array<Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Order_By>>;
  where?: InputMaybe<Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Course_CategoryArgs = {
  args: Search_Course_Category_Args;
  distinct_on?: InputMaybe<Array<Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Category_Order_By>>;
  where?: InputMaybe<Course_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Course_Category_AggregateArgs = {
  args: Search_Course_Category_Args;
  distinct_on?: InputMaybe<Array<Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Category_Order_By>>;
  where?: InputMaybe<Course_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Course_GoalArgs = {
  args: Search_Course_Goal_Args;
  distinct_on?: InputMaybe<Array<Course_Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Order_By>>;
  where?: InputMaybe<Course_Goal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Course_Goal_AggregateArgs = {
  args: Search_Course_Goal_Args;
  distinct_on?: InputMaybe<Array<Course_Goal_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Course_Goal_Order_By>>;
  where?: InputMaybe<Course_Goal_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_DocumentArgs = {
  args: Search_Document_Args;
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Document_AggregateArgs = {
  args: Search_Document_Args;
  distinct_on?: InputMaybe<Array<Document_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Document_Order_By>>;
  where?: InputMaybe<Document_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_ExchangeArgs = {
  args: Search_Exchange_Args;
  distinct_on?: InputMaybe<Array<Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Order_By>>;
  where?: InputMaybe<Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Exchange_AggregateArgs = {
  args: Search_Exchange_Args;
  distinct_on?: InputMaybe<Array<Exchange_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Exchange_Order_By>>;
  where?: InputMaybe<Exchange_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_InsightArgs = {
  args: Search_Insight_Args;
  distinct_on?: InputMaybe<Array<Insight_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Insight_Order_By>>;
  where?: InputMaybe<Insight_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_KnowledgeArgs = {
  args: Search_Knowledge_Args;
  distinct_on?: InputMaybe<Array<Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Order_By>>;
  where?: InputMaybe<Knowledge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Knowledge_AggregateArgs = {
  args: Search_Knowledge_Args;
  distinct_on?: InputMaybe<Array<Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Knowledge_Order_By>>;
  where?: InputMaybe<Knowledge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Learning_PathArgs = {
  args: Search_Learning_Path_Args;
  distinct_on?: InputMaybe<Array<Learning_Path_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Path_Order_By>>;
  where?: InputMaybe<Learning_Path_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Learning_Path_AggregateArgs = {
  args: Search_Learning_Path_Args;
  distinct_on?: InputMaybe<Array<Learning_Path_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Learning_Path_Order_By>>;
  where?: InputMaybe<Learning_Path_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_LessonArgs = {
  args: Search_Lesson_Args;
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Lesson_AggregateArgs = {
  args: Search_Lesson_Args;
  distinct_on?: InputMaybe<Array<Lesson_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Order_By>>;
  where?: InputMaybe<Lesson_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Lesson_CategoryArgs = {
  args: Search_Lesson_Category_Args;
  distinct_on?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Category_Order_By>>;
  where?: InputMaybe<Lesson_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Lesson_Category_AggregateArgs = {
  args: Search_Lesson_Category_Args;
  distinct_on?: InputMaybe<Array<Lesson_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Category_Order_By>>;
  where?: InputMaybe<Lesson_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Lesson_ClassifyArgs = {
  args: Search_Lesson_Classify_Args;
  distinct_on?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Classify_Order_By>>;
  where?: InputMaybe<Lesson_Classify_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Lesson_Classify_AggregateArgs = {
  args: Search_Lesson_Classify_Args;
  distinct_on?: InputMaybe<Array<Lesson_Classify_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lesson_Classify_Order_By>>;
  where?: InputMaybe<Lesson_Classify_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Level_CourseArgs = {
  args: Search_Level_Course_Args;
  distinct_on?: InputMaybe<Array<Level_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Level_Course_Order_By>>;
  where?: InputMaybe<Level_Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Level_Course_AggregateArgs = {
  args: Search_Level_Course_Args;
  distinct_on?: InputMaybe<Array<Level_Course_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Level_Course_Order_By>>;
  where?: InputMaybe<Level_Course_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_NewsArgs = {
  args: Search_News_Args;
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_News_AggregateArgs = {
  args: Search_News_Args;
  distinct_on?: InputMaybe<Array<News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<News_Order_By>>;
  where?: InputMaybe<News_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_SeriesArgs = {
  args: Search_Series_Args;
  distinct_on?: InputMaybe<Array<Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Order_By>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSeederArgs = {
  distinct_on?: InputMaybe<Array<Seeder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seeder_Order_By>>;
  where?: InputMaybe<Seeder_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSeeder_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Seeder_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Seeder_Order_By>>;
  where?: InputMaybe<Seeder_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSeeder_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSeriesArgs = {
  distinct_on?: InputMaybe<Array<Series_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Order_By>>;
  where?: InputMaybe<Series_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSeries_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootSeries_Course_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Series_Course_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Course_Category_Order_By>>;
  where?: InputMaybe<Series_Course_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSeries_Course_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootSeries_KnowledgeArgs = {
  distinct_on?: InputMaybe<Array<Series_Knowledge_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Series_Knowledge_Order_By>>;
  where?: InputMaybe<Series_Knowledge_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSeries_Knowledge_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootVideo_WatchedArgs = {
  distinct_on?: InputMaybe<Array<Video_Watched_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Video_Watched_Order_By>>;
  where?: InputMaybe<Video_Watched_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVideo_Watched_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootWebinarsArgs = {
  distinct_on?: InputMaybe<Array<Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webinars_Order_By>>;
  where?: InputMaybe<Webinars_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootWebinars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Webinars_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Webinars_Order_By>>;
  where?: InputMaybe<Webinars_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootWebinars_By_PkArgs = {
  id: Scalars['Int'];
};

export type Testfunction5_Args = {
  hasura_session?: InputMaybe<Scalars['json']>;
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

export type UpdateUserOutput = {
  __typename?: 'updateUserOutput';
  data?: Maybe<Scalars['ObjectUpdateUser']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type VideoWatchedOutput = {
  __typename?: 'videoWatchedOutput';
  data?: Maybe<Scalars['Object']>;
  error?: Maybe<Scalars['String']>;
};

/** columns and relationships of "video_watched" */
export type Video_Watched = {
  __typename?: 'video_watched';
  browser_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An object relationship */
  lesson?: Maybe<Lesson>;
  lesson_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** input type for inserting array relation for remote table "video_watched" */
export type Video_Watched_Arr_Rel_Insert_Input = {
  data: Array<Video_Watched_Insert_Input>;
};

/** Boolean expression to filter rows from the table "video_watched". All fields are combined with a logical 'AND'. */
export type Video_Watched_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Video_Watched_Bool_Exp>>>;
  _not?: InputMaybe<Video_Watched_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Video_Watched_Bool_Exp>>>;
  browser_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lesson?: InputMaybe<Lesson_Bool_Exp>;
  lesson_id?: InputMaybe<Int_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "video_watched" */
export type Video_Watched_Insert_Input = {
  browser_id?: InputMaybe<Scalars['String']>;
  lesson_id?: InputMaybe<Scalars['Int']>;
};

/** response of any mutation on the table "video_watched" */
export type Video_Watched_Mutation_Response = {
  __typename?: 'video_watched_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Video_Watched>;
};

/** input type for inserting object relation for remote table "video_watched" */
export type Video_Watched_Obj_Rel_Insert_Input = {
  data: Video_Watched_Insert_Input;
};

/** ordering options when selecting data from "video_watched" */
export type Video_Watched_Order_By = {
  browser_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lesson?: InputMaybe<Lesson_Order_By>;
  lesson_id?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "video_watched" */
export type Video_Watched_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "video_watched" */
export enum Video_Watched_Select_Column {
  /** column name */
  BrowserId = 'browser_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LessonId = 'lesson_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Webinar = {
  __typename?: 'webinar';
  createdTime: Scalars['String'];
  fields?: Maybe<Scalars['RenderedObject']>;
  id: Scalars['String'];
};

/** columns and relationships of "webinars" */
export type Webinars = {
  __typename?: 'webinars';
  approve_id?: Maybe<Scalars['String']>;
  author_description?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  author_profile_image_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date: Scalars['timestamptz'];
  fake_num_register_participants?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isHot: Scalars['Boolean'];
  /** A computed field, executes function "webinars_video_convert2" */
  media_convert?: Maybe<Scalars['String']>;
  name_file?: Maybe<Scalars['String']>;
  num_register_participants?: Maybe<Scalars['Int']>;
  number_of_participants?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  /** An object relationship */
  profile?: Maybe<Profile>;
  /** An object relationship */
  profileByApproveId?: Maybe<Profile>;
  /** An array relationship */
  review_webinars: Array<Review_Webinar>;
  /** An aggregated array relationship */
  review_webinars_aggregate: Review_Webinar_Aggregate;
  start_date: Scalars['timestamptz'];
  status?: Maybe<Scalars['Int']>;
  thumbnail_url: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['Int']>;
  webinar_content: Scalars['String'];
  webinar_goals?: Maybe<Scalars['jsonb']>;
  webinar_title: Scalars['String'];
  zoom_url: Scalars['String'];
};


/** columns and relationships of "webinars" */
export type WebinarsReview_WebinarsArgs = {
  distinct_on?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Webinar_Order_By>>;
  where?: InputMaybe<Review_Webinar_Bool_Exp>;
};


/** columns and relationships of "webinars" */
export type WebinarsReview_Webinars_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Webinar_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Review_Webinar_Order_By>>;
  where?: InputMaybe<Review_Webinar_Bool_Exp>;
};


/** columns and relationships of "webinars" */
export type WebinarsWebinar_GoalsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "webinars" */
export type Webinars_Aggregate = {
  __typename?: 'webinars_aggregate';
  aggregate?: Maybe<Webinars_Aggregate_Fields>;
  nodes: Array<Webinars>;
};

/** aggregate fields of "webinars" */
export type Webinars_Aggregate_Fields = {
  __typename?: 'webinars_aggregate_fields';
  avg?: Maybe<Webinars_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Webinars_Max_Fields>;
  min?: Maybe<Webinars_Min_Fields>;
  stddev?: Maybe<Webinars_Stddev_Fields>;
  stddev_pop?: Maybe<Webinars_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Webinars_Stddev_Samp_Fields>;
  sum?: Maybe<Webinars_Sum_Fields>;
  var_pop?: Maybe<Webinars_Var_Pop_Fields>;
  var_samp?: Maybe<Webinars_Var_Samp_Fields>;
  variance?: Maybe<Webinars_Variance_Fields>;
};


/** aggregate fields of "webinars" */
export type Webinars_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Webinars_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "webinars" */
export type Webinars_Aggregate_Order_By = {
  avg?: InputMaybe<Webinars_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Webinars_Max_Order_By>;
  min?: InputMaybe<Webinars_Min_Order_By>;
  stddev?: InputMaybe<Webinars_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Webinars_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Webinars_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Webinars_Sum_Order_By>;
  var_pop?: InputMaybe<Webinars_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Webinars_Var_Samp_Order_By>;
  variance?: InputMaybe<Webinars_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Webinars_Avg_Fields = {
  __typename?: 'webinars_avg_fields';
  fake_num_register_participants?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  num_register_participants?: Maybe<Scalars['Float']>;
  number_of_participants?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  view?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "webinars" */
export type Webinars_Avg_Order_By = {
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "webinars". All fields are combined with a logical 'AND'. */
export type Webinars_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Webinars_Bool_Exp>>>;
  _not?: InputMaybe<Webinars_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Webinars_Bool_Exp>>>;
  approve_id?: InputMaybe<String_Comparison_Exp>;
  author_description?: InputMaybe<String_Comparison_Exp>;
  author_name?: InputMaybe<String_Comparison_Exp>;
  author_profile_image_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  fake_num_register_participants?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isHot?: InputMaybe<Boolean_Comparison_Exp>;
  name_file?: InputMaybe<String_Comparison_Exp>;
  num_register_participants?: InputMaybe<Int_Comparison_Exp>;
  number_of_participants?: InputMaybe<Int_Comparison_Exp>;
  point?: InputMaybe<Float8_Comparison_Exp>;
  profile?: InputMaybe<Profile_Bool_Exp>;
  profileByApproveId?: InputMaybe<Profile_Bool_Exp>;
  review_webinars?: InputMaybe<Review_Webinar_Bool_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<Int_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  video_url?: InputMaybe<String_Comparison_Exp>;
  view?: InputMaybe<Int_Comparison_Exp>;
  webinar_content?: InputMaybe<String_Comparison_Exp>;
  webinar_goals?: InputMaybe<Jsonb_Comparison_Exp>;
  webinar_title?: InputMaybe<String_Comparison_Exp>;
  zoom_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "webinars" */
export enum Webinars_Constraint {
  /** unique or primary key constraint */
  WebinarsPkey = 'webinars_pkey'
}

/** input type for incrementing integer column in table "webinars" */
export type Webinars_Inc_Input = {
  view?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Webinars_Max_Fields = {
  __typename?: 'webinars_max_fields';
  approve_id?: Maybe<Scalars['String']>;
  author_description?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  author_profile_image_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  fake_num_register_participants?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name_file?: Maybe<Scalars['String']>;
  num_register_participants?: Maybe<Scalars['Int']>;
  number_of_participants?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['Int']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['Int']>;
  webinar_content?: Maybe<Scalars['String']>;
  webinar_title?: Maybe<Scalars['String']>;
  zoom_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "webinars" */
export type Webinars_Max_Order_By = {
  approve_id?: InputMaybe<Order_By>;
  author_description?: InputMaybe<Order_By>;
  author_name?: InputMaybe<Order_By>;
  author_profile_image_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
  webinar_content?: InputMaybe<Order_By>;
  webinar_title?: InputMaybe<Order_By>;
  zoom_url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Webinars_Min_Fields = {
  __typename?: 'webinars_min_fields';
  approve_id?: Maybe<Scalars['String']>;
  author_description?: Maybe<Scalars['String']>;
  author_name?: Maybe<Scalars['String']>;
  author_profile_image_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_date?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  fake_num_register_participants?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name_file?: Maybe<Scalars['String']>;
  num_register_participants?: Maybe<Scalars['Int']>;
  number_of_participants?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['Int']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['Int']>;
  webinar_content?: Maybe<Scalars['String']>;
  webinar_title?: Maybe<Scalars['String']>;
  zoom_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "webinars" */
export type Webinars_Min_Order_By = {
  approve_id?: InputMaybe<Order_By>;
  author_description?: InputMaybe<Order_By>;
  author_name?: InputMaybe<Order_By>;
  author_profile_image_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
  webinar_content?: InputMaybe<Order_By>;
  webinar_title?: InputMaybe<Order_By>;
  zoom_url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "webinars" */
export type Webinars_Mutation_Response = {
  __typename?: 'webinars_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Webinars>;
};

/** on conflict condition type for table "webinars" */
export type Webinars_On_Conflict = {
  constraint: Webinars_Constraint;
  update_columns: Array<Webinars_Update_Column>;
  where?: InputMaybe<Webinars_Bool_Exp>;
};

/** ordering options when selecting data from "webinars" */
export type Webinars_Order_By = {
  approve_id?: InputMaybe<Order_By>;
  author_description?: InputMaybe<Order_By>;
  author_name?: InputMaybe<Order_By>;
  author_profile_image_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_date?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isHot?: InputMaybe<Order_By>;
  name_file?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  profile?: InputMaybe<Profile_Order_By>;
  profileByApproveId?: InputMaybe<Profile_Order_By>;
  review_webinars_aggregate?: InputMaybe<Review_Webinar_Aggregate_Order_By>;
  start_date?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  video_url?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
  webinar_content?: InputMaybe<Order_By>;
  webinar_goals?: InputMaybe<Order_By>;
  webinar_title?: InputMaybe<Order_By>;
  zoom_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "webinars" */
export type Webinars_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "webinars" */
export enum Webinars_Select_Column {
  /** column name */
  ApproveId = 'approve_id',
  /** column name */
  AuthorDescription = 'author_description',
  /** column name */
  AuthorName = 'author_name',
  /** column name */
  AuthorProfileImageUrl = 'author_profile_image_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FakeNumRegisterParticipants = 'fake_num_register_participants',
  /** column name */
  Id = 'id',
  /** column name */
  IsHot = 'isHot',
  /** column name */
  NameFile = 'name_file',
  /** column name */
  NumRegisterParticipants = 'num_register_participants',
  /** column name */
  NumberOfParticipants = 'number_of_participants',
  /** column name */
  Point = 'point',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VideoUrl = 'video_url',
  /** column name */
  View = 'view',
  /** column name */
  WebinarContent = 'webinar_content',
  /** column name */
  WebinarGoals = 'webinar_goals',
  /** column name */
  WebinarTitle = 'webinar_title',
  /** column name */
  ZoomUrl = 'zoom_url'
}

/** input type for updating data in table "webinars" */
export type Webinars_Set_Input = {
  view?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Webinars_Stddev_Fields = {
  __typename?: 'webinars_stddev_fields';
  fake_num_register_participants?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  num_register_participants?: Maybe<Scalars['Float']>;
  number_of_participants?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  view?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "webinars" */
export type Webinars_Stddev_Order_By = {
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Webinars_Stddev_Pop_Fields = {
  __typename?: 'webinars_stddev_pop_fields';
  fake_num_register_participants?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  num_register_participants?: Maybe<Scalars['Float']>;
  number_of_participants?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  view?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "webinars" */
export type Webinars_Stddev_Pop_Order_By = {
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Webinars_Stddev_Samp_Fields = {
  __typename?: 'webinars_stddev_samp_fields';
  fake_num_register_participants?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  num_register_participants?: Maybe<Scalars['Float']>;
  number_of_participants?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  view?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "webinars" */
export type Webinars_Stddev_Samp_Order_By = {
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Webinars_Sum_Fields = {
  __typename?: 'webinars_sum_fields';
  fake_num_register_participants?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  num_register_participants?: Maybe<Scalars['Int']>;
  number_of_participants?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['float8']>;
  status?: Maybe<Scalars['Int']>;
  view?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "webinars" */
export type Webinars_Sum_Order_By = {
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
};

/** update columns of table "webinars" */
export enum Webinars_Update_Column {
  /** column name */
  View = 'view'
}

/** aggregate var_pop on columns */
export type Webinars_Var_Pop_Fields = {
  __typename?: 'webinars_var_pop_fields';
  fake_num_register_participants?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  num_register_participants?: Maybe<Scalars['Float']>;
  number_of_participants?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  view?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "webinars" */
export type Webinars_Var_Pop_Order_By = {
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Webinars_Var_Samp_Fields = {
  __typename?: 'webinars_var_samp_fields';
  fake_num_register_participants?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  num_register_participants?: Maybe<Scalars['Float']>;
  number_of_participants?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  view?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "webinars" */
export type Webinars_Var_Samp_Order_By = {
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Webinars_Variance_Fields = {
  __typename?: 'webinars_variance_fields';
  fake_num_register_participants?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  num_register_participants?: Maybe<Scalars['Float']>;
  number_of_participants?: Maybe<Scalars['Float']>;
  point?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['Float']>;
  view?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "webinars" */
export type Webinars_Variance_Order_By = {
  fake_num_register_participants?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  num_register_participants?: InputMaybe<Order_By>;
  number_of_participants?: InputMaybe<Order_By>;
  point?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  view?: InputMaybe<Order_By>;
};

export type MutationBlogViewCountMutationVariables = Exact<{
  pkColumns: Blog_Pk_Columns_Input;
  set?: InputMaybe<Blog_Set_Input>;
}>;


export type MutationBlogViewCountMutation = { __typename?: 'mutation_root', update_blog_by_pk?: { __typename?: 'blog', views?: number | null } | null };

export type Insert_Contact_FormMutationVariables = Exact<{
  objects: Array<Contact_Form_Insert_Input> | Contact_Form_Insert_Input;
}>;


export type Insert_Contact_FormMutation = { __typename?: 'mutation_root', insert_contact_form?: { __typename?: 'contact_form_mutation_response', affected_rows: number } | null };

export type CommentExchangeMutationVariables = Exact<{
  objects: Array<Comment_Exchange_Insert_Input> | Comment_Exchange_Insert_Input;
}>;


export type CommentExchangeMutation = { __typename?: 'mutation_root', insert_comment_exchange?: { __typename?: 'comment_exchange_mutation_response', affected_rows: number } | null };

export type UpdateCommentExchangeMutationVariables = Exact<{
  where: Comment_Exchange_Bool_Exp;
  set?: InputMaybe<Comment_Exchange_Set_Input>;
}>;


export type UpdateCommentExchangeMutation = { __typename?: 'mutation_root', update_comment_exchange?: { __typename?: 'comment_exchange_mutation_response', affected_rows: number } | null };

export type PostCommentMagazineMutationVariables = Exact<{
  object: Magazine_Comment_Web_Insert_Input;
}>;


export type PostCommentMagazineMutation = { __typename?: 'mutation_root', insert_magazine_comment_web_one?: { __typename?: 'magazine_comment_web', content: string, created_at: any, user_email: string, user_name: string } | null };

export type MutationViewCountMutationVariables = Exact<{
  pkColumns: News_Pk_Columns_Input;
  set?: InputMaybe<News_Set_Input>;
}>;


export type MutationViewCountMutation = { __typename?: 'mutation_root', update_news_by_pk?: { __typename?: 'news', views?: number | null } | null };

export type PostCommentPlaylistMutationVariables = Exact<{
  objects: Array<Course_Rating_Insert_Input> | Course_Rating_Insert_Input;
}>;


export type PostCommentPlaylistMutation = { __typename?: 'mutation_root', insert_course_rating?: { __typename?: 'course_rating_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'course_rating', id: number }> } | null };

export type UpdateCourseRatingByPkMutationVariables = Exact<{
  pkColumns: Course_Rating_Pk_Columns_Input;
  set: Course_Rating_Set_Input;
}>;


export type UpdateCourseRatingByPkMutation = { __typename?: 'mutation_root', update_course_rating_by_pk?: { __typename?: 'course_rating', id: number } | null };

export type InsertKnowledgeActionMutationVariables = Exact<{
  objects: Array<Knowledge_Action_Insert_Input> | Knowledge_Action_Insert_Input;
}>;


export type InsertKnowledgeActionMutation = { __typename?: 'mutation_root', insert_knowledge_action?: { __typename?: 'knowledge_action_mutation_response', affected_rows: number } | null };

export type DeleteKnowledgeActionMutationVariables = Exact<{
  where: Knowledge_Action_Bool_Exp;
}>;


export type DeleteKnowledgeActionMutation = { __typename?: 'mutation_root', delete_knowledge_action?: { __typename?: 'knowledge_action_mutation_response', affected_rows: number } | null };

export type UpdateKnowledgeActionMutationVariables = Exact<{
  _set?: InputMaybe<Knowledge_Action_Set_Input>;
  where: Knowledge_Action_Bool_Exp;
}>;


export type UpdateKnowledgeActionMutation = { __typename?: 'mutation_root', update_knowledge_action?: { __typename?: 'knowledge_action_mutation_response', affected_rows: number } | null };

export type UpsertLikeCountMutationVariables = Exact<{
  insertLikeCountOneObject: Like_Count_Insert_Input;
}>;


export type UpsertLikeCountMutation = { __typename?: 'mutation_root', insert_like_count_one?: { __typename?: 'like_count', id: any, browser_id?: string | null, is_dislike: boolean, is_like: boolean, lesson_id: number, lesson: { __typename?: 'lesson', id: number, dislike_amount: number, like_amount: number } } | null };

export type AddCommentVideoMutationVariables = Exact<{
  commentVideoObject: Lesson_Comment_Web_Insert_Input;
}>;


export type AddCommentVideoMutation = { __typename?: 'mutation_root', insert_lesson_comment_web_one?: { __typename?: 'lesson_comment_web', id: number } | null };

export type VideoWatchedMutationVariables = Exact<{
  lessonId: Scalars['Int'];
  browserId: Scalars['String'];
}>;


export type VideoWatchedMutation = { __typename?: 'mutation_root', insert_video_watched_one?: { __typename?: 'video_watched', id: number } | null };

export type GetAdvertisementQueryVariables = Exact<{
  where?: InputMaybe<Advertisement_Bool_Exp>;
}>;


export type GetAdvertisementQuery = { __typename?: 'query_root', advertisement: Array<{ __typename?: 'advertisement', name_file?: string | null, thumbnail_url?: string | null, ads_url?: string | null, link_advertisement?: string | null, index?: boolean | null, ratio?: number | null, percent?: number | null, path?: string | null, title?: string | null }> };

export type GetBlogCategoriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Blog_Order_By> | Blog_Order_By>;
  where?: InputMaybe<Blog_Bool_Exp>;
}>;


export type GetBlogCategoriesQuery = { __typename?: 'query_root', blog_category: Array<{ __typename?: 'blog_category', name: string, id: number, slug: string, blogs: Array<{ __typename?: 'blog', title?: string | null, thumbnail?: string | null, meta_description?: string | null, slug?: string | null, create_at?: any | null, date: any, id: number, blog_category?: { __typename?: 'blog_category', name: string, id: number } | null }> }> };

export type GetBlogByIdQueryVariables = Exact<{
  blogByPkId: Scalars['Int'];
}>;


export type GetBlogByIdQuery = { __typename?: 'query_root', blog_by_pk?: { __typename?: 'blog', category_id?: number | null, content?: string | null, slug?: string | null, create_at?: any | null, date: any, isHot?: boolean | null, id: number, thumbnail?: string | null, title?: string | null, meta_description?: string | null, fake_views?: number | null, views?: number | null, canonical?: string | null, noindex?: boolean | null, is_public?: boolean | null, blog_category?: { __typename?: 'blog_category', name: string, slug: string, id: number } | null } | null };

export type GetAllBlogQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Blog_Order_By> | Blog_Order_By>;
  where?: InputMaybe<Blog_Bool_Exp>;
}>;


export type GetAllBlogQuery = { __typename?: 'query_root', blog: Array<{ __typename?: 'blog', id: number, thumbnail?: string | null, isHot?: boolean | null, title?: string | null, meta_description?: string | null, slug?: string | null, create_at?: any | null, date: any, blog_category?: { __typename?: 'blog_category', id: number, name: string } | null }>, blog_aggregate: { __typename?: 'blog_aggregate', aggregate?: { __typename?: 'blog_aggregate_fields', count?: number | null } | null } };

export type GetBlogByCategorySlugQueryVariables = Exact<{
  where?: InputMaybe<Blog_Category_Bool_Exp>;
  whereBlog?: InputMaybe<Blog_Bool_Exp>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Blog_Order_By> | Blog_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetBlogByCategorySlugQuery = { __typename?: 'query_root', blog_category: Array<{ __typename?: 'blog_category', name: string, id: number, slug: string, meta_description?: string | null, blogs: Array<{ __typename?: 'blog', slug?: string | null, create_at?: any | null, date: any, id: number, thumbnail?: string | null, title?: string | null, meta_description?: string | null, blog_category?: { __typename?: 'blog_category', id: number, name: string } | null }>, blogs_aggregate: { __typename?: 'blog_aggregate', aggregate?: { __typename?: 'blog_aggregate_fields', count?: number | null } | null } }> };

export type GetCourseCategoryQueryVariables = Exact<{
  where?: InputMaybe<Course_Category_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetCourseCategoryQuery = { __typename?: 'query_root', course_category: Array<{ __typename?: 'course_category', id: any, name: string }> };

export type GetExchangeQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Exchange_Bool_Exp>;
  orderBy?: InputMaybe<Array<Exchange_Order_By> | Exchange_Order_By>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetExchangeQuery = { __typename?: 'query_root', exchange: Array<{ __typename?: 'exchange', avatar?: string | null, description?: string | null, expert_score?: any | null, founded_year?: string | null, funding_option?: string | null, headquarters?: string | null, id: any, link?: string | null, max_leverage?: string | null, name: string, pin?: boolean | null, short_description?: string | null, trading_license?: string | null, trading_platform?: string | null, trading_products?: string | null, user_score?: any | null, slug: string, spread_eurusd_fee?: string | null, withdrawal_fee?: string | null }>, exchange_aggregate: { __typename?: 'exchange_aggregate', aggregate?: { __typename?: 'exchange_aggregate_fields', count?: number | null } | null } };

export type GetExchangeByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetExchangeByIdQuery = { __typename?: 'query_root', exchange_by_pk?: { __typename?: 'exchange', avatar?: string | null, description?: string | null, expert_score?: any | null, founded_year?: string | null, funding_option?: string | null, headquarters?: string | null, id: any, link?: string | null, max_leverage?: string | null, name: string, pin?: boolean | null, short_description?: string | null, trading_license?: string | null, trading_platform?: string | null, trading_products?: string | null, user_score?: any | null } | null };

export type GetExchangePaginationQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Exchange_Bool_Exp>;
  orderBy?: InputMaybe<Array<Exchange_Order_By> | Exchange_Order_By>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetExchangePaginationQuery = { __typename?: 'query_root', exchange: Array<{ __typename?: 'exchange', name: string, id: any, expert_score?: any | null, user_score?: any | null, avatar?: string | null, headquarters?: string | null, trading_license?: string | null, trading_platform?: string | null, slug: string, link?: string | null, spread_eurusd_fee?: string | null, withdrawal_fee?: string | null, trading_products?: string | null, max_leverage?: string | null }>, exchange_aggregate: { __typename?: 'exchange_aggregate', aggregate?: { __typename?: 'exchange_aggregate_fields', count?: number | null } | null } };

export type GetCommentExchangeQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Comment_Exchange_Order_By> | Comment_Exchange_Order_By>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
}>;


export type GetCommentExchangeQuery = { __typename?: 'query_root', comment_exchange: Array<{ __typename?: 'comment_exchange', comment_type: Comment_Type_Enum, content?: string | null, exchange_id: any, id: any, published_at?: any | null, created_at?: any | null, review_url?: string | null, scores: any, user_email_web?: string | null, user_name_web?: string | null, updated_at?: any | null, seeder?: { __typename?: 'seeder', avatar?: string | null, email?: string | null, name?: string | null } | null }>, comment_exchange_aggregate: { __typename?: 'comment_exchange_aggregate', aggregate?: { __typename?: 'comment_exchange_aggregate_fields', count?: number | null } | null } };

export type GetAllCommentExchangeQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Comment_Exchange_Order_By> | Comment_Exchange_Order_By>;
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
}>;


export type GetAllCommentExchangeQuery = { __typename?: 'query_root', comment_exchange: Array<{ __typename?: 'comment_exchange', comment_type: Comment_Type_Enum, content?: string | null, exchange_id: any, id: any, published_at?: any | null, created_at?: any | null, review_url?: string | null, scores: any, user_email_web?: string | null, user_name_web?: string | null, updated_at?: any | null, seeder?: { __typename?: 'seeder', avatar?: string | null, email?: string | null, name?: string | null } | null }>, comment_exchange_aggregate: { __typename?: 'comment_exchange_aggregate', aggregate?: { __typename?: 'comment_exchange_aggregate_fields', count?: number | null } | null } };

export type CheckEmailExistedCommentExchangeQueryVariables = Exact<{
  where?: InputMaybe<Comment_Exchange_Bool_Exp>;
}>;


export type CheckEmailExistedCommentExchangeQuery = { __typename?: 'query_root', comment_exchange_aggregate: { __typename?: 'comment_exchange_aggregate', aggregate?: { __typename?: 'comment_exchange_aggregate_fields', count?: number | null } | null } };

export type GetAllInsightsByMagazineIdQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<Insight_Order_By> | Insight_Order_By>;
  where?: InputMaybe<Insight_Bool_Exp>;
}>;


export type GetAllInsightsByMagazineIdQuery = { __typename?: 'query_root', insight: Array<{ __typename?: 'insight', title?: string | null, thumbnail?: string | null, slug: string, published_at: any, id: number, content?: any | null, magazine_id?: number | null, is_publish?: boolean | null, description: string, insight_order?: number | null, magazine?: { __typename?: 'magazine', title?: string | null, name_pdf?: string | null, table_of_contents?: any | null, insight_id?: number | null } | null }> };

export type GetDetailInsightByIdQueryVariables = Exact<{
  insightByPkId: Scalars['Int'];
}>;


export type GetDetailInsightByIdQuery = { __typename?: 'query_root', insight_by_pk?: { __typename?: 'insight', content?: any | null, description: string, id: number, is_publish?: boolean | null, magazine_id?: number | null, published_at: any, slug: string, thumbnail?: string | null, title?: string | null, magazine?: { __typename?: 'magazine', id: number, title?: string | null, slug?: string | null } | null } | null };

export type GetOtherInsightsQueryVariables = Exact<{
  where?: InputMaybe<Insight_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Insight_Order_By> | Insight_Order_By>;
}>;


export type GetOtherInsightsQuery = { __typename?: 'query_root', insight: Array<{ __typename?: 'insight', insight_order?: number | null, content?: any | null, description: string, id: number, is_publish?: boolean | null, magazine_id?: number | null, published_at: any, slug: string, thumbnail?: string | null, title?: string | null, magazine?: { __typename?: 'magazine', id: number, title?: string | null } | null }> };

export type KnowledgeHomepageQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<Course_Bool_Exp>;
  orderBy?: InputMaybe<Array<Course_Order_By> | Course_Order_By>;
}>;


export type KnowledgeHomepageQuery = { __typename?: 'query_root', course: Array<{ __typename?: 'course', id: number, thumbnail_url?: string | null, name: string, description: string, updated_at?: any | null, category?: { __typename?: 'course_category', name: string } | null, lessons_aggregate: { __typename?: 'lesson_aggregate', aggregate?: { __typename?: 'lesson_aggregate_fields', count?: number | null } | null } }> };

export type GetAllCategoryKnowledgeQueryVariables = Exact<{
  where?: InputMaybe<Course_Category_Bool_Exp>;
  orderBy?: InputMaybe<Array<Course_Category_Order_By> | Course_Category_Order_By>;
}>;


export type GetAllCategoryKnowledgeQuery = { __typename?: 'query_root', course_category: Array<{ __typename?: 'course_category', id: any, name: string, description?: string | null, series_order?: number | null, playlist_order?: number | null, series_course_categories: Array<{ __typename?: 'series_course_category', series_id: number, series: { __typename?: 'series', series_knowledges: Array<{ __typename?: 'series_knowledge', id: number }> } }>, course_course_categories: Array<{ __typename?: 'course_course_category', course: { __typename?: 'course', name: string, lessons: Array<{ __typename?: 'lesson', id: number }> } }>, courses_aggregate: { __typename?: 'course_aggregate', aggregate?: { __typename?: 'course_aggregate_fields', count?: number | null } | null }, courses: Array<{ __typename?: 'course', lessons_aggregate: { __typename?: 'lesson_aggregate', aggregate?: { __typename?: 'lesson_aggregate_fields', count?: number | null } | null } }> }> };

export type GetCourseByCategoryQueryVariables = Exact<{
  where?: InputMaybe<Course_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Course_Order_By> | Course_Order_By>;
}>;


export type GetCourseByCategoryQuery = { __typename?: 'query_root', course: Array<{ __typename?: 'course', id: number, thumbnail_url?: string | null, name: string, description: string, updated_at?: any | null, created_at?: any | null, is_feature?: boolean | null, order?: number | null, lessons_aggregate: { __typename?: 'lesson_aggregate', aggregate?: { __typename?: 'lesson_aggregate_fields', count?: number | null } | null }, lessons: Array<{ __typename?: 'lesson', id: number, name: string }>, course_course_categories: Array<{ __typename?: 'course_course_category', course_category: { __typename?: 'course_category', name: string, id: any } }> }> };

export type GetKnowledgeActionQueryVariables = Exact<{
  where?: InputMaybe<Knowledge_Action_Bool_Exp>;
}>;


export type GetKnowledgeActionQuery = { __typename?: 'query_root', knowledge_action: Array<{ __typename?: 'knowledge_action', id: number, action_type: any, browser_id: string, knowledge_id: number }> };

export type GetAllMagazineQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Magazine_Order_By> | Magazine_Order_By>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllMagazineQuery = { __typename?: 'query_root', magazine: Array<{ __typename?: 'magazine', title?: string | null, slug?: string | null, description?: string | null, thumbnail?: string | null, id: number, table_of_contents?: any | null, name_pdf?: string | null, created_at: any, public_at?: any | null, index?: number | null }>, magazine_aggregate: { __typename?: 'magazine_aggregate', aggregate?: { __typename?: 'magazine_aggregate_fields', count?: number | null } | null } };

export type GetAllMagazineLastestQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Magazine_Order_By> | Magazine_Order_By>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetAllMagazineLastestQuery = { __typename?: 'query_root', magazine: Array<{ __typename?: 'magazine', title?: string | null, slug?: string | null, thumbnail?: string | null, id: number, created_at: any, public_at?: any | null, index?: number | null }>, magazine_aggregate: { __typename?: 'magazine_aggregate', aggregate?: { __typename?: 'magazine_aggregate_fields', count?: number | null } | null } };

export type GetMagazineBySlugQueryVariables = Exact<{
  where?: InputMaybe<Magazine_Bool_Exp>;
}>;


export type GetMagazineBySlugQuery = { __typename?: 'query_root', magazine: Array<{ __typename?: 'magazine', thumbnail?: string | null, title?: string | null, slug?: string | null, table_of_contents?: any | null, name_pdf?: string | null, description?: string | null, created_at: any, id: number, public_at?: any | null }> };

export type GetMagazineCommentsByIdQueryVariables = Exact<{
  where?: InputMaybe<Magazine_Comment_Web_Bool_Exp>;
  orderBy?: InputMaybe<Array<Magazine_Comment_Web_Order_By> | Magazine_Comment_Web_Order_By>;
}>;


export type GetMagazineCommentsByIdQuery = { __typename?: 'query_root', magazine_comment_web: Array<{ __typename?: 'magazine_comment_web', user_email: string, user_name: string, content: string, created_at: any }> };

export type NewsHomepageQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<News_Order_By> | News_Order_By>;
  where?: InputMaybe<News_Bool_Exp>;
}>;


export type NewsHomepageQuery = { __typename?: 'query_root', news: Array<{ __typename?: 'news', title?: string | null, thumbnail?: string | null, slug?: string | null, meta_description?: string | null, content?: any | null, date: any, update_at?: any | null, id: number, category_id?: number | null, create_at?: any | null, news_category?: { __typename?: 'news_category', name: string, slug: string, id: number } | null }>, news_aggregate: { __typename?: 'news_aggregate', aggregate?: { __typename?: 'news_aggregate_fields', count?: number | null } | null } };

export type GetNewsCategoriesQueryVariables = Exact<{
  where?: InputMaybe<News_Category_Bool_Exp>;
  order_by?: InputMaybe<Array<News_Category_Order_By> | News_Category_Order_By>;
}>;


export type GetNewsCategoriesQuery = { __typename?: 'query_root', news_category: Array<{ __typename?: 'news_category', id: number, name: string, slug: string }> };

export type GetNewsCategoriesBySlugQueryVariables = Exact<{
  where?: InputMaybe<News_Category_Bool_Exp>;
}>;


export type GetNewsCategoriesBySlugQuery = { __typename?: 'query_root', news_category: Array<{ __typename?: 'news_category', name: string, slug: string, id: number, updated_at: any, updated_by?: string | null, meta_description?: string | null }> };

export type GetNewsByCategoriesIdQueryVariables = Exact<{
  newsCategoryByPkId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<News_Order_By> | News_Order_By>;
  offset?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<News_Bool_Exp>;
}>;


export type GetNewsByCategoriesIdQuery = { __typename?: 'query_root', news_category_by_pk?: { __typename?: 'news_category', name: string, id: number, news: Array<{ __typename?: 'news', thumbnail?: string | null, title?: string | null, slug?: string | null, meta_description?: string | null, update_at?: any | null, date: any, id: number, news_category?: { __typename?: 'news_category', name: string, slug: string, id: number } | null }> } | null };

export type GetNewsByIdQueryVariables = Exact<{
  newsByPkId: Scalars['Int'];
}>;


export type GetNewsByIdQuery = { __typename?: 'query_root', news_by_pk?: { __typename?: 'news', id: number, thumbnail?: string | null, slug?: string | null, title?: string | null, updated_by?: string | null, views?: number | null, date: any, create_at?: any | null, category_id?: number | null, content?: any | null, meta_description?: string | null, fake_views?: number | null, canonical?: string | null, noindex?: boolean | null, news_category?: { __typename?: 'news_category', name: string, id: number } | null } | null };

export type GetNewsByCategorySlugQueryVariables = Exact<{
  where?: InputMaybe<News_Bool_Exp>;
  orderBy?: InputMaybe<Array<News_Order_By> | News_Order_By>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetNewsByCategorySlugQuery = { __typename?: 'query_root', news: Array<{ __typename?: 'news', category_id?: number | null, id: number, slug?: string | null, title?: string | null, meta_description?: string | null, thumbnail?: string | null, create_at?: any | null, date: any, news_category?: { __typename?: 'news_category', name: string, id: number } | null }>, news_aggregate: { __typename?: 'news_aggregate', aggregate?: { __typename?: 'news_aggregate_fields', count?: number | null } | null } };

export type GetNewsCategoryHomePageQueryVariables = Exact<{
  limitNews?: InputMaybe<Scalars['Int']>;
  offsetNews?: InputMaybe<Scalars['Int']>;
  orderByNews?: InputMaybe<Array<News_Order_By> | News_Order_By>;
  whereNews?: InputMaybe<News_Bool_Exp>;
  orderByNewsCategory?: InputMaybe<Array<News_Category_Order_By> | News_Category_Order_By>;
}>;


export type GetNewsCategoryHomePageQuery = { __typename?: 'query_root', news_category: Array<{ __typename?: 'news_category', id: number, name: string, slug: string, news: Array<{ __typename?: 'news', id: number, title?: string | null, thumbnail?: string | null, link?: string | null, slug?: string | null }> }> };

export type DetailPlaylistQueryVariables = Exact<{
  courseByPkId: Scalars['Int'];
}>;


export type DetailPlaylistQuery = { __typename?: 'query_root', course_by_pk?: { __typename?: 'course', name: string, thumbnail_url?: string | null, id: number, description: string, updated_at?: any | null, avg_rating: any, content?: string | null, lessons_aggregate: { __typename?: 'lesson_aggregate', aggregate?: { __typename?: 'lesson_aggregate_fields', count?: number | null } | null }, category?: { __typename?: 'course_category', name: string } | null, lessons: Array<{ __typename?: 'lesson', name: string, id: number, thumbnail_url?: string | null, duration?: number | null, fake_views?: number | null, views?: number | null, index: number }> } | null };

export type GetLessonByCourseIdQueryVariables = Exact<{
  courseByPkId: Scalars['Int'];
}>;


export type GetLessonByCourseIdQuery = { __typename?: 'query_root', course_by_pk?: { __typename?: 'course', lessons: Array<{ __typename?: 'lesson', views?: number | null, fake_views?: number | null, id: number }> } | null };

export type CheckIsEmailRatingCourseQueryVariables = Exact<{
  email: Scalars['String'];
  courseId: Scalars['Int'];
}>;


export type CheckIsEmailRatingCourseQuery = { __typename?: 'query_root', course_rating_by_pk?: { __typename?: 'course_rating', id: number } | null };

export type GetCourseRatingsQueryVariables = Exact<{
  courseId: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetCourseRatingsQuery = { __typename?: 'query_root', course_rating: Array<{ __typename?: 'course_rating', user_name: string, user_email: string, created_at: any, rating_description?: string | null, rating_title?: string | null, score: any, updated_at: any }> };

export type GetTotalRatingOfCourseQueryVariables = Exact<{
  courseId: Scalars['Int'];
}>;


export type GetTotalRatingOfCourseQuery = { __typename?: 'query_root', course_rating_aggregate: { __typename?: 'course_rating_aggregate', aggregate?: { __typename?: 'course_rating_aggregate_fields', count?: number | null } | null } };

export type GetAllRedirectQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<Redirect_Order_By> | Redirect_Order_By>;
}>;


export type GetAllRedirectQuery = { __typename?: 'query_root', redirect: Array<{ __typename?: 'redirect', new_url?: string | null, old_url?: string | null }> };

export type GetSeriesHomeQueryVariables = Exact<{
  where?: InputMaybe<Series_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Series_Order_By> | Series_Order_By>;
}>;


export type GetSeriesHomeQuery = { __typename?: 'query_root', series: Array<{ __typename?: 'series', id: number, title?: string | null, slug: string, thumbnail?: string | null, created_at?: any | null, order?: number | null, series_knowledges: Array<{ __typename?: 'series_knowledge', knowledge: { __typename?: 'knowledge', id: number, content: any, description: string, slug: string, thumbnail: string, title: string } }>, series_course_categories: Array<{ __typename?: 'series_course_category', courseCategoryByCourseCategory: { __typename?: 'course_category', name: string } }> }> };

export type GetSeriesQueryVariables = Exact<{
  where?: InputMaybe<Series_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetSeriesQuery = { __typename?: 'query_root', series: Array<{ __typename?: 'series', id: number, title?: string | null, slug: string, thumbnail?: string | null, seo_description?: string | null, seo_title?: string | null, series_knowledges: Array<{ __typename?: 'series_knowledge', knowledge: { __typename?: 'knowledge', id: number, content: any, description: string, slug: string, thumbnail: string, title: string } }>, series_course_categories: Array<{ __typename?: 'series_course_category', courseCategoryByCourseCategory: { __typename?: 'course_category', name: string } }> }> };

export type GetKnowledgeQueryVariables = Exact<{
  where?: InputMaybe<Knowledge_Bool_Exp>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type GetKnowledgeQuery = { __typename?: 'query_root', knowledge: Array<{ __typename?: 'knowledge', id: number, title: string, content: any, description: string, slug: string, thumbnail: string, published_at?: any | null, series_knowledges: Array<{ __typename?: 'series_knowledge', id: number, series: { __typename?: 'series', slug: string } }> }> };

export type DetailVideoQueryVariables = Exact<{
  lessonByPkId: Scalars['Int'];
}>;


export type DetailVideoQuery = { __typename?: 'query_root', lesson_by_pk?: { __typename?: 'lesson', name: string, fake_views?: number | null, description?: string | null, thumbnail_url?: string | null, video_url?: string | null, duration?: number | null, id: number, views?: number | null, content?: string | null, course?: { __typename?: 'course', id: number, name: string, lessons: Array<{ __typename?: 'lesson', name: string, thumbnail_url?: string | null, id: number, duration?: number | null, index: number }>, lessons_aggregate: { __typename?: 'lesson_aggregate', aggregate?: { __typename?: 'lesson_aggregate_fields', count?: number | null } | null } } | null } | null };

export type GetCommentByIdQueryVariables = Exact<{
  lessonId: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetCommentByIdQuery = { __typename?: 'query_root', lesson_comment_web: Array<{ __typename?: 'lesson_comment_web', content: string, created_at: any, id: number, lesson_id: number, updated_at: any, user_name: string, user_email: string }> };

export type GetTotalCommentOfLessonQueryVariables = Exact<{
  lessonId: Scalars['Int'];
}>;


export type GetTotalCommentOfLessonQuery = { __typename?: 'query_root', lesson_comment_web_aggregate: { __typename?: 'lesson_comment_web_aggregate', aggregate?: { __typename?: 'lesson_comment_web_aggregate_fields', count?: number | null } | null } };

export type GetLessonInteractionQueryVariables = Exact<{
  lessonId: Scalars['Int'];
  browserId: Scalars['String'];
}>;


export type GetLessonInteractionQuery = { __typename?: 'query_root', lesson_by_pk?: { __typename?: 'lesson', id: number, dislike_amount: number, like_amount: number, like_counts: Array<{ __typename?: 'like_count', id: any, is_like: boolean, is_dislike: boolean }> } | null };

export type GetVideoWatchedByBrowserQueryVariables = Exact<{
  videoId: Scalars['Int'];
  browserId?: InputMaybe<Scalars['String']>;
}>;


export type GetVideoWatchedByBrowserQuery = { __typename?: 'query_root', video_watched: Array<{ __typename?: 'video_watched', id: number }> };


export const MutationBlogViewCountDocument = gql`
    mutation MutationBlogViewCount($pkColumns: blog_pk_columns_input!, $set: blog_set_input) {
  update_blog_by_pk(pk_columns: $pkColumns, _set: $set) {
    views
  }
}
    `;
export type MutationBlogViewCountMutationFn = Apollo.MutationFunction<MutationBlogViewCountMutation, MutationBlogViewCountMutationVariables>;

/**
 * __useMutationBlogViewCountMutation__
 *
 * To run a mutation, you first call `useMutationBlogViewCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationBlogViewCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationBlogViewCountMutation, { data, loading, error }] = useMutationBlogViewCountMutation({
 *   variables: {
 *      pkColumns: // value for 'pkColumns'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useMutationBlogViewCountMutation(baseOptions?: Apollo.MutationHookOptions<MutationBlogViewCountMutation, MutationBlogViewCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutationBlogViewCountMutation, MutationBlogViewCountMutationVariables>(MutationBlogViewCountDocument, options);
      }
export type MutationBlogViewCountMutationHookResult = ReturnType<typeof useMutationBlogViewCountMutation>;
export type MutationBlogViewCountMutationResult = Apollo.MutationResult<MutationBlogViewCountMutation>;
export type MutationBlogViewCountMutationOptions = Apollo.BaseMutationOptions<MutationBlogViewCountMutation, MutationBlogViewCountMutationVariables>;
export const Insert_Contact_FormDocument = gql`
    mutation Insert_contact_form($objects: [contact_form_insert_input!]!) {
  insert_contact_form(objects: $objects) {
    affected_rows
  }
}
    `;
export type Insert_Contact_FormMutationFn = Apollo.MutationFunction<Insert_Contact_FormMutation, Insert_Contact_FormMutationVariables>;

/**
 * __useInsert_Contact_FormMutation__
 *
 * To run a mutation, you first call `useInsert_Contact_FormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsert_Contact_FormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactFormMutation, { data, loading, error }] = useInsert_Contact_FormMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsert_Contact_FormMutation(baseOptions?: Apollo.MutationHookOptions<Insert_Contact_FormMutation, Insert_Contact_FormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Insert_Contact_FormMutation, Insert_Contact_FormMutationVariables>(Insert_Contact_FormDocument, options);
      }
export type Insert_Contact_FormMutationHookResult = ReturnType<typeof useInsert_Contact_FormMutation>;
export type Insert_Contact_FormMutationResult = Apollo.MutationResult<Insert_Contact_FormMutation>;
export type Insert_Contact_FormMutationOptions = Apollo.BaseMutationOptions<Insert_Contact_FormMutation, Insert_Contact_FormMutationVariables>;
export const CommentExchangeDocument = gql`
    mutation CommentExchange($objects: [comment_exchange_insert_input!]!) {
  insert_comment_exchange(objects: $objects) {
    affected_rows
  }
}
    `;
export type CommentExchangeMutationFn = Apollo.MutationFunction<CommentExchangeMutation, CommentExchangeMutationVariables>;

/**
 * __useCommentExchangeMutation__
 *
 * To run a mutation, you first call `useCommentExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentExchangeMutation, { data, loading, error }] = useCommentExchangeMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useCommentExchangeMutation(baseOptions?: Apollo.MutationHookOptions<CommentExchangeMutation, CommentExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommentExchangeMutation, CommentExchangeMutationVariables>(CommentExchangeDocument, options);
      }
export type CommentExchangeMutationHookResult = ReturnType<typeof useCommentExchangeMutation>;
export type CommentExchangeMutationResult = Apollo.MutationResult<CommentExchangeMutation>;
export type CommentExchangeMutationOptions = Apollo.BaseMutationOptions<CommentExchangeMutation, CommentExchangeMutationVariables>;
export const UpdateCommentExchangeDocument = gql`
    mutation UpdateCommentExchange($where: comment_exchange_bool_exp!, $set: comment_exchange_set_input) {
  update_comment_exchange(where: $where, _set: $set) {
    affected_rows
  }
}
    `;
export type UpdateCommentExchangeMutationFn = Apollo.MutationFunction<UpdateCommentExchangeMutation, UpdateCommentExchangeMutationVariables>;

/**
 * __useUpdateCommentExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateCommentExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentExchangeMutation, { data, loading, error }] = useUpdateCommentExchangeMutation({
 *   variables: {
 *      where: // value for 'where'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCommentExchangeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentExchangeMutation, UpdateCommentExchangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentExchangeMutation, UpdateCommentExchangeMutationVariables>(UpdateCommentExchangeDocument, options);
      }
export type UpdateCommentExchangeMutationHookResult = ReturnType<typeof useUpdateCommentExchangeMutation>;
export type UpdateCommentExchangeMutationResult = Apollo.MutationResult<UpdateCommentExchangeMutation>;
export type UpdateCommentExchangeMutationOptions = Apollo.BaseMutationOptions<UpdateCommentExchangeMutation, UpdateCommentExchangeMutationVariables>;
export const PostCommentMagazineDocument = gql`
    mutation postCommentMagazine($object: magazine_comment_web_insert_input!) {
  insert_magazine_comment_web_one(object: $object) {
    content
    created_at
    user_email
    user_name
  }
}
    `;
export type PostCommentMagazineMutationFn = Apollo.MutationFunction<PostCommentMagazineMutation, PostCommentMagazineMutationVariables>;

/**
 * __usePostCommentMagazineMutation__
 *
 * To run a mutation, you first call `usePostCommentMagazineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCommentMagazineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCommentMagazineMutation, { data, loading, error }] = usePostCommentMagazineMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function usePostCommentMagazineMutation(baseOptions?: Apollo.MutationHookOptions<PostCommentMagazineMutation, PostCommentMagazineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostCommentMagazineMutation, PostCommentMagazineMutationVariables>(PostCommentMagazineDocument, options);
      }
export type PostCommentMagazineMutationHookResult = ReturnType<typeof usePostCommentMagazineMutation>;
export type PostCommentMagazineMutationResult = Apollo.MutationResult<PostCommentMagazineMutation>;
export type PostCommentMagazineMutationOptions = Apollo.BaseMutationOptions<PostCommentMagazineMutation, PostCommentMagazineMutationVariables>;
export const MutationViewCountDocument = gql`
    mutation MutationViewCount($pkColumns: news_pk_columns_input!, $set: news_set_input) {
  update_news_by_pk(pk_columns: $pkColumns, _set: $set) {
    views
  }
}
    `;
export type MutationViewCountMutationFn = Apollo.MutationFunction<MutationViewCountMutation, MutationViewCountMutationVariables>;

/**
 * __useMutationViewCountMutation__
 *
 * To run a mutation, you first call `useMutationViewCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationViewCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationViewCountMutation, { data, loading, error }] = useMutationViewCountMutation({
 *   variables: {
 *      pkColumns: // value for 'pkColumns'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useMutationViewCountMutation(baseOptions?: Apollo.MutationHookOptions<MutationViewCountMutation, MutationViewCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutationViewCountMutation, MutationViewCountMutationVariables>(MutationViewCountDocument, options);
      }
export type MutationViewCountMutationHookResult = ReturnType<typeof useMutationViewCountMutation>;
export type MutationViewCountMutationResult = Apollo.MutationResult<MutationViewCountMutation>;
export type MutationViewCountMutationOptions = Apollo.BaseMutationOptions<MutationViewCountMutation, MutationViewCountMutationVariables>;
export const PostCommentPlaylistDocument = gql`
    mutation postCommentPlaylist($objects: [course_rating_insert_input!]!) {
  insert_course_rating(objects: $objects) {
    returning {
      id
    }
    affected_rows
  }
}
    `;
export type PostCommentPlaylistMutationFn = Apollo.MutationFunction<PostCommentPlaylistMutation, PostCommentPlaylistMutationVariables>;

/**
 * __usePostCommentPlaylistMutation__
 *
 * To run a mutation, you first call `usePostCommentPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCommentPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCommentPlaylistMutation, { data, loading, error }] = usePostCommentPlaylistMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function usePostCommentPlaylistMutation(baseOptions?: Apollo.MutationHookOptions<PostCommentPlaylistMutation, PostCommentPlaylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostCommentPlaylistMutation, PostCommentPlaylistMutationVariables>(PostCommentPlaylistDocument, options);
      }
export type PostCommentPlaylistMutationHookResult = ReturnType<typeof usePostCommentPlaylistMutation>;
export type PostCommentPlaylistMutationResult = Apollo.MutationResult<PostCommentPlaylistMutation>;
export type PostCommentPlaylistMutationOptions = Apollo.BaseMutationOptions<PostCommentPlaylistMutation, PostCommentPlaylistMutationVariables>;
export const UpdateCourseRatingByPkDocument = gql`
    mutation updateCourseRatingByPk($pkColumns: course_rating_pk_columns_input!, $set: course_rating_set_input!) {
  update_course_rating_by_pk(pk_columns: $pkColumns, _set: $set) {
    id
  }
}
    `;
export type UpdateCourseRatingByPkMutationFn = Apollo.MutationFunction<UpdateCourseRatingByPkMutation, UpdateCourseRatingByPkMutationVariables>;

/**
 * __useUpdateCourseRatingByPkMutation__
 *
 * To run a mutation, you first call `useUpdateCourseRatingByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseRatingByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseRatingByPkMutation, { data, loading, error }] = useUpdateCourseRatingByPkMutation({
 *   variables: {
 *      pkColumns: // value for 'pkColumns'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateCourseRatingByPkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseRatingByPkMutation, UpdateCourseRatingByPkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourseRatingByPkMutation, UpdateCourseRatingByPkMutationVariables>(UpdateCourseRatingByPkDocument, options);
      }
export type UpdateCourseRatingByPkMutationHookResult = ReturnType<typeof useUpdateCourseRatingByPkMutation>;
export type UpdateCourseRatingByPkMutationResult = Apollo.MutationResult<UpdateCourseRatingByPkMutation>;
export type UpdateCourseRatingByPkMutationOptions = Apollo.BaseMutationOptions<UpdateCourseRatingByPkMutation, UpdateCourseRatingByPkMutationVariables>;
export const InsertKnowledgeActionDocument = gql`
    mutation InsertKnowledgeAction($objects: [knowledge_action_insert_input!]!) {
  insert_knowledge_action(objects: $objects) {
    affected_rows
  }
}
    `;
export type InsertKnowledgeActionMutationFn = Apollo.MutationFunction<InsertKnowledgeActionMutation, InsertKnowledgeActionMutationVariables>;

/**
 * __useInsertKnowledgeActionMutation__
 *
 * To run a mutation, you first call `useInsertKnowledgeActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertKnowledgeActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertKnowledgeActionMutation, { data, loading, error }] = useInsertKnowledgeActionMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertKnowledgeActionMutation(baseOptions?: Apollo.MutationHookOptions<InsertKnowledgeActionMutation, InsertKnowledgeActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InsertKnowledgeActionMutation, InsertKnowledgeActionMutationVariables>(InsertKnowledgeActionDocument, options);
      }
export type InsertKnowledgeActionMutationHookResult = ReturnType<typeof useInsertKnowledgeActionMutation>;
export type InsertKnowledgeActionMutationResult = Apollo.MutationResult<InsertKnowledgeActionMutation>;
export type InsertKnowledgeActionMutationOptions = Apollo.BaseMutationOptions<InsertKnowledgeActionMutation, InsertKnowledgeActionMutationVariables>;
export const DeleteKnowledgeActionDocument = gql`
    mutation DeleteKnowledgeAction($where: knowledge_action_bool_exp!) {
  delete_knowledge_action(where: $where) {
    affected_rows
  }
}
    `;
export type DeleteKnowledgeActionMutationFn = Apollo.MutationFunction<DeleteKnowledgeActionMutation, DeleteKnowledgeActionMutationVariables>;

/**
 * __useDeleteKnowledgeActionMutation__
 *
 * To run a mutation, you first call `useDeleteKnowledgeActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteKnowledgeActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteKnowledgeActionMutation, { data, loading, error }] = useDeleteKnowledgeActionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteKnowledgeActionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteKnowledgeActionMutation, DeleteKnowledgeActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteKnowledgeActionMutation, DeleteKnowledgeActionMutationVariables>(DeleteKnowledgeActionDocument, options);
      }
export type DeleteKnowledgeActionMutationHookResult = ReturnType<typeof useDeleteKnowledgeActionMutation>;
export type DeleteKnowledgeActionMutationResult = Apollo.MutationResult<DeleteKnowledgeActionMutation>;
export type DeleteKnowledgeActionMutationOptions = Apollo.BaseMutationOptions<DeleteKnowledgeActionMutation, DeleteKnowledgeActionMutationVariables>;
export const UpdateKnowledgeActionDocument = gql`
    mutation UpdateKnowledgeAction($_set: knowledge_action_set_input, $where: knowledge_action_bool_exp!) {
  update_knowledge_action(where: $where, _set: $_set) {
    affected_rows
  }
}
    `;
export type UpdateKnowledgeActionMutationFn = Apollo.MutationFunction<UpdateKnowledgeActionMutation, UpdateKnowledgeActionMutationVariables>;

/**
 * __useUpdateKnowledgeActionMutation__
 *
 * To run a mutation, you first call `useUpdateKnowledgeActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateKnowledgeActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateKnowledgeActionMutation, { data, loading, error }] = useUpdateKnowledgeActionMutation({
 *   variables: {
 *      _set: // value for '_set'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateKnowledgeActionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateKnowledgeActionMutation, UpdateKnowledgeActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateKnowledgeActionMutation, UpdateKnowledgeActionMutationVariables>(UpdateKnowledgeActionDocument, options);
      }
export type UpdateKnowledgeActionMutationHookResult = ReturnType<typeof useUpdateKnowledgeActionMutation>;
export type UpdateKnowledgeActionMutationResult = Apollo.MutationResult<UpdateKnowledgeActionMutation>;
export type UpdateKnowledgeActionMutationOptions = Apollo.BaseMutationOptions<UpdateKnowledgeActionMutation, UpdateKnowledgeActionMutationVariables>;
export const UpsertLikeCountDocument = gql`
    mutation UpsertLikeCount($insertLikeCountOneObject: like_count_insert_input!) {
  insert_like_count_one(
    object: $insertLikeCountOneObject
    on_conflict: {constraint: like_count_profile_id_lesson_id_browser_id_key, update_columns: [is_like, is_dislike]}
  ) {
    id
    browser_id
    is_dislike
    is_like
    lesson_id
    lesson {
      id
      dislike_amount
      like_amount
    }
  }
}
    `;
export type UpsertLikeCountMutationFn = Apollo.MutationFunction<UpsertLikeCountMutation, UpsertLikeCountMutationVariables>;

/**
 * __useUpsertLikeCountMutation__
 *
 * To run a mutation, you first call `useUpsertLikeCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLikeCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLikeCountMutation, { data, loading, error }] = useUpsertLikeCountMutation({
 *   variables: {
 *      insertLikeCountOneObject: // value for 'insertLikeCountOneObject'
 *   },
 * });
 */
export function useUpsertLikeCountMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLikeCountMutation, UpsertLikeCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLikeCountMutation, UpsertLikeCountMutationVariables>(UpsertLikeCountDocument, options);
      }
export type UpsertLikeCountMutationHookResult = ReturnType<typeof useUpsertLikeCountMutation>;
export type UpsertLikeCountMutationResult = Apollo.MutationResult<UpsertLikeCountMutation>;
export type UpsertLikeCountMutationOptions = Apollo.BaseMutationOptions<UpsertLikeCountMutation, UpsertLikeCountMutationVariables>;
export const AddCommentVideoDocument = gql`
    mutation addCommentVideo($commentVideoObject: lesson_comment_web_insert_input!) {
  insert_lesson_comment_web_one(object: $commentVideoObject) {
    id
  }
}
    `;
export type AddCommentVideoMutationFn = Apollo.MutationFunction<AddCommentVideoMutation, AddCommentVideoMutationVariables>;

/**
 * __useAddCommentVideoMutation__
 *
 * To run a mutation, you first call `useAddCommentVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentVideoMutation, { data, loading, error }] = useAddCommentVideoMutation({
 *   variables: {
 *      commentVideoObject: // value for 'commentVideoObject'
 *   },
 * });
 */
export function useAddCommentVideoMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentVideoMutation, AddCommentVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentVideoMutation, AddCommentVideoMutationVariables>(AddCommentVideoDocument, options);
      }
export type AddCommentVideoMutationHookResult = ReturnType<typeof useAddCommentVideoMutation>;
export type AddCommentVideoMutationResult = Apollo.MutationResult<AddCommentVideoMutation>;
export type AddCommentVideoMutationOptions = Apollo.BaseMutationOptions<AddCommentVideoMutation, AddCommentVideoMutationVariables>;
export const VideoWatchedDocument = gql`
    mutation VideoWatched($lessonId: Int!, $browserId: String!) {
  insert_video_watched_one(object: {browser_id: $browserId, lesson_id: $lessonId}) {
    id
  }
}
    `;
export type VideoWatchedMutationFn = Apollo.MutationFunction<VideoWatchedMutation, VideoWatchedMutationVariables>;

/**
 * __useVideoWatchedMutation__
 *
 * To run a mutation, you first call `useVideoWatchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVideoWatchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [videoWatchedMutation, { data, loading, error }] = useVideoWatchedMutation({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      browserId: // value for 'browserId'
 *   },
 * });
 */
export function useVideoWatchedMutation(baseOptions?: Apollo.MutationHookOptions<VideoWatchedMutation, VideoWatchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VideoWatchedMutation, VideoWatchedMutationVariables>(VideoWatchedDocument, options);
      }
export type VideoWatchedMutationHookResult = ReturnType<typeof useVideoWatchedMutation>;
export type VideoWatchedMutationResult = Apollo.MutationResult<VideoWatchedMutation>;
export type VideoWatchedMutationOptions = Apollo.BaseMutationOptions<VideoWatchedMutation, VideoWatchedMutationVariables>;
export const GetAdvertisementDocument = gql`
    query getAdvertisement($where: advertisement_bool_exp) {
  advertisement(where: $where) {
    name_file
    thumbnail_url
    ads_url
    link_advertisement
    index
    ratio
    percent
    path
    title
  }
}
    `;

/**
 * __useGetAdvertisementQuery__
 *
 * To run a query within a React component, call `useGetAdvertisementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdvertisementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdvertisementQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAdvertisementQuery(baseOptions?: Apollo.QueryHookOptions<GetAdvertisementQuery, GetAdvertisementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdvertisementQuery, GetAdvertisementQueryVariables>(GetAdvertisementDocument, options);
      }
export function useGetAdvertisementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdvertisementQuery, GetAdvertisementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdvertisementQuery, GetAdvertisementQueryVariables>(GetAdvertisementDocument, options);
        }
export type GetAdvertisementQueryHookResult = ReturnType<typeof useGetAdvertisementQuery>;
export type GetAdvertisementLazyQueryHookResult = ReturnType<typeof useGetAdvertisementLazyQuery>;
export type GetAdvertisementQueryResult = Apollo.QueryResult<GetAdvertisementQuery, GetAdvertisementQueryVariables>;
export const GetBlogCategoriesDocument = gql`
    query getBlogCategories($limit: Int, $orderBy: [blog_order_by!], $where: blog_bool_exp) {
  blog_category {
    name
    id
    slug
    blogs(limit: $limit, order_by: $orderBy, where: $where) {
      title
      thumbnail
      meta_description
      slug
      create_at
      date
      id
      blog_category {
        name
        id
      }
    }
  }
}
    `;

/**
 * __useGetBlogCategoriesQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetBlogCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>(GetBlogCategoriesDocument, options);
      }
export function useGetBlogCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>(GetBlogCategoriesDocument, options);
        }
export type GetBlogCategoriesQueryHookResult = ReturnType<typeof useGetBlogCategoriesQuery>;
export type GetBlogCategoriesLazyQueryHookResult = ReturnType<typeof useGetBlogCategoriesLazyQuery>;
export type GetBlogCategoriesQueryResult = Apollo.QueryResult<GetBlogCategoriesQuery, GetBlogCategoriesQueryVariables>;
export const GetBlogByIdDocument = gql`
    query getBlogById($blogByPkId: Int!) {
  blog_by_pk(id: $blogByPkId) {
    category_id
    content
    slug
    create_at
    date
    isHot
    blog_category {
      name
      slug
      id
    }
    id
    thumbnail
    title
    meta_description
    fake_views
    views
    canonical
    noindex
    is_public
  }
}
    `;

/**
 * __useGetBlogByIdQuery__
 *
 * To run a query within a React component, call `useGetBlogByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogByIdQuery({
 *   variables: {
 *      blogByPkId: // value for 'blogByPkId'
 *   },
 * });
 */
export function useGetBlogByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBlogByIdQuery, GetBlogByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogByIdQuery, GetBlogByIdQueryVariables>(GetBlogByIdDocument, options);
      }
export function useGetBlogByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogByIdQuery, GetBlogByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogByIdQuery, GetBlogByIdQueryVariables>(GetBlogByIdDocument, options);
        }
export type GetBlogByIdQueryHookResult = ReturnType<typeof useGetBlogByIdQuery>;
export type GetBlogByIdLazyQueryHookResult = ReturnType<typeof useGetBlogByIdLazyQuery>;
export type GetBlogByIdQueryResult = Apollo.QueryResult<GetBlogByIdQuery, GetBlogByIdQueryVariables>;
export const GetAllBlogDocument = gql`
    query getAllBlog($limit: Int, $offset: Int, $orderBy: [blog_order_by!], $where: blog_bool_exp) {
  blog(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
    id
    thumbnail
    isHot
    title
    meta_description
    slug
    create_at
    date
    blog_category {
      id
      name
    }
  }
  blog_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllBlogQuery__
 *
 * To run a query within a React component, call `useGetAllBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBlogQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllBlogQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBlogQuery, GetAllBlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBlogQuery, GetAllBlogQueryVariables>(GetAllBlogDocument, options);
      }
export function useGetAllBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBlogQuery, GetAllBlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBlogQuery, GetAllBlogQueryVariables>(GetAllBlogDocument, options);
        }
export type GetAllBlogQueryHookResult = ReturnType<typeof useGetAllBlogQuery>;
export type GetAllBlogLazyQueryHookResult = ReturnType<typeof useGetAllBlogLazyQuery>;
export type GetAllBlogQueryResult = Apollo.QueryResult<GetAllBlogQuery, GetAllBlogQueryVariables>;
export const GetBlogByCategorySlugDocument = gql`
    query getBlogByCategorySlug($where: blog_category_bool_exp, $whereBlog: blog_bool_exp, $offset: Int, $orderBy: [blog_order_by!], $limit: Int) {
  blog_category(where: $where) {
    name
    id
    slug
    blogs(offset: $offset, order_by: $orderBy, limit: $limit, where: $whereBlog) {
      blog_category {
        id
        name
      }
      slug
      create_at
      date
      id
      thumbnail
      title
      meta_description
    }
    meta_description
    blogs_aggregate(where: $whereBlog) {
      aggregate {
        count
      }
    }
  }
}
    `;

/**
 * __useGetBlogByCategorySlugQuery__
 *
 * To run a query within a React component, call `useGetBlogByCategorySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogByCategorySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogByCategorySlugQuery({
 *   variables: {
 *      where: // value for 'where'
 *      whereBlog: // value for 'whereBlog'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBlogByCategorySlugQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogByCategorySlugQuery, GetBlogByCategorySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogByCategorySlugQuery, GetBlogByCategorySlugQueryVariables>(GetBlogByCategorySlugDocument, options);
      }
export function useGetBlogByCategorySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogByCategorySlugQuery, GetBlogByCategorySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogByCategorySlugQuery, GetBlogByCategorySlugQueryVariables>(GetBlogByCategorySlugDocument, options);
        }
export type GetBlogByCategorySlugQueryHookResult = ReturnType<typeof useGetBlogByCategorySlugQuery>;
export type GetBlogByCategorySlugLazyQueryHookResult = ReturnType<typeof useGetBlogByCategorySlugLazyQuery>;
export type GetBlogByCategorySlugQueryResult = Apollo.QueryResult<GetBlogByCategorySlugQuery, GetBlogByCategorySlugQueryVariables>;
export const GetCourseCategoryDocument = gql`
    query getCourseCategory($where: course_category_bool_exp, $limit: Int, $offset: Int) {
  course_category(where: $where, limit: $limit, offset: $offset) {
    id
    name
  }
}
    `;

/**
 * __useGetCourseCategoryQuery__
 *
 * To run a query within a React component, call `useGetCourseCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCourseCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>(GetCourseCategoryDocument, options);
      }
export function useGetCourseCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>(GetCourseCategoryDocument, options);
        }
export type GetCourseCategoryQueryHookResult = ReturnType<typeof useGetCourseCategoryQuery>;
export type GetCourseCategoryLazyQueryHookResult = ReturnType<typeof useGetCourseCategoryLazyQuery>;
export type GetCourseCategoryQueryResult = Apollo.QueryResult<GetCourseCategoryQuery, GetCourseCategoryQueryVariables>;
export const GetExchangeDocument = gql`
    query getExchange($limit: Int, $where: exchange_bool_exp, $orderBy: [exchange_order_by!], $offset: Int) {
  exchange(limit: $limit, where: $where, order_by: $orderBy, offset: $offset) {
    avatar
    description
    expert_score
    founded_year
    funding_option
    headquarters
    id
    link
    max_leverage
    name
    pin
    short_description
    trading_license
    trading_platform
    trading_products
    user_score
    slug
    spread_eurusd_fee
    withdrawal_fee
  }
  exchange_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetExchangeQuery__
 *
 * To run a query within a React component, call `useGetExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetExchangeQuery(baseOptions?: Apollo.QueryHookOptions<GetExchangeQuery, GetExchangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExchangeQuery, GetExchangeQueryVariables>(GetExchangeDocument, options);
      }
export function useGetExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeQuery, GetExchangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExchangeQuery, GetExchangeQueryVariables>(GetExchangeDocument, options);
        }
export type GetExchangeQueryHookResult = ReturnType<typeof useGetExchangeQuery>;
export type GetExchangeLazyQueryHookResult = ReturnType<typeof useGetExchangeLazyQuery>;
export type GetExchangeQueryResult = Apollo.QueryResult<GetExchangeQuery, GetExchangeQueryVariables>;
export const GetExchangeByIdDocument = gql`
    query getExchangeById($id: uuid!) {
  exchange_by_pk(id: $id) {
    avatar
    description
    expert_score
    founded_year
    funding_option
    headquarters
    id
    link
    max_leverage
    name
    pin
    short_description
    trading_license
    trading_platform
    trading_products
    user_score
  }
}
    `;

/**
 * __useGetExchangeByIdQuery__
 *
 * To run a query within a React component, call `useGetExchangeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetExchangeByIdQuery(baseOptions: Apollo.QueryHookOptions<GetExchangeByIdQuery, GetExchangeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExchangeByIdQuery, GetExchangeByIdQueryVariables>(GetExchangeByIdDocument, options);
      }
export function useGetExchangeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeByIdQuery, GetExchangeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExchangeByIdQuery, GetExchangeByIdQueryVariables>(GetExchangeByIdDocument, options);
        }
export type GetExchangeByIdQueryHookResult = ReturnType<typeof useGetExchangeByIdQuery>;
export type GetExchangeByIdLazyQueryHookResult = ReturnType<typeof useGetExchangeByIdLazyQuery>;
export type GetExchangeByIdQueryResult = Apollo.QueryResult<GetExchangeByIdQuery, GetExchangeByIdQueryVariables>;
export const GetExchangePaginationDocument = gql`
    query GetExchangePagination($limit: Int, $where: exchange_bool_exp, $orderBy: [exchange_order_by!], $offset: Int) {
  exchange(limit: $limit, where: $where, order_by: $orderBy, offset: $offset) {
    name
    id
    expert_score
    user_score
    avatar
    headquarters
    trading_license
    trading_platform
    slug
    link
    spread_eurusd_fee
    withdrawal_fee
    trading_products
    max_leverage
  }
  exchange_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetExchangePaginationQuery__
 *
 * To run a query within a React component, call `useGetExchangePaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangePaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangePaginationQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetExchangePaginationQuery(baseOptions?: Apollo.QueryHookOptions<GetExchangePaginationQuery, GetExchangePaginationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExchangePaginationQuery, GetExchangePaginationQueryVariables>(GetExchangePaginationDocument, options);
      }
export function useGetExchangePaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangePaginationQuery, GetExchangePaginationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExchangePaginationQuery, GetExchangePaginationQueryVariables>(GetExchangePaginationDocument, options);
        }
export type GetExchangePaginationQueryHookResult = ReturnType<typeof useGetExchangePaginationQuery>;
export type GetExchangePaginationLazyQueryHookResult = ReturnType<typeof useGetExchangePaginationLazyQuery>;
export type GetExchangePaginationQueryResult = Apollo.QueryResult<GetExchangePaginationQuery, GetExchangePaginationQueryVariables>;
export const GetCommentExchangeDocument = gql`
    query GetCommentExchange($limit: Int, $offset: Int, $orderBy: [comment_exchange_order_by!], $where: comment_exchange_bool_exp) {
  comment_exchange(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: $where
  ) {
    comment_type
    content
    exchange_id
    id
    published_at
    created_at
    review_url
    scores
    seeder {
      avatar
      email
      name
    }
    user_email_web
    user_name_web
    updated_at
  }
  comment_exchange_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetCommentExchangeQuery__
 *
 * To run a query within a React component, call `useGetCommentExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentExchangeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCommentExchangeQuery(baseOptions?: Apollo.QueryHookOptions<GetCommentExchangeQuery, GetCommentExchangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentExchangeQuery, GetCommentExchangeQueryVariables>(GetCommentExchangeDocument, options);
      }
export function useGetCommentExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentExchangeQuery, GetCommentExchangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentExchangeQuery, GetCommentExchangeQueryVariables>(GetCommentExchangeDocument, options);
        }
export type GetCommentExchangeQueryHookResult = ReturnType<typeof useGetCommentExchangeQuery>;
export type GetCommentExchangeLazyQueryHookResult = ReturnType<typeof useGetCommentExchangeLazyQuery>;
export type GetCommentExchangeQueryResult = Apollo.QueryResult<GetCommentExchangeQuery, GetCommentExchangeQueryVariables>;
export const GetAllCommentExchangeDocument = gql`
    query GetAllCommentExchange($offset: Int, $orderBy: [comment_exchange_order_by!], $where: comment_exchange_bool_exp) {
  comment_exchange(offset: $offset, order_by: $orderBy, where: $where) {
    comment_type
    content
    exchange_id
    id
    published_at
    created_at
    review_url
    scores
    seeder {
      avatar
      email
      name
    }
    user_email_web
    user_name_web
    updated_at
  }
  comment_exchange_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllCommentExchangeQuery__
 *
 * To run a query within a React component, call `useGetAllCommentExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCommentExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCommentExchangeQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllCommentExchangeQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCommentExchangeQuery, GetAllCommentExchangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCommentExchangeQuery, GetAllCommentExchangeQueryVariables>(GetAllCommentExchangeDocument, options);
      }
export function useGetAllCommentExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCommentExchangeQuery, GetAllCommentExchangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCommentExchangeQuery, GetAllCommentExchangeQueryVariables>(GetAllCommentExchangeDocument, options);
        }
export type GetAllCommentExchangeQueryHookResult = ReturnType<typeof useGetAllCommentExchangeQuery>;
export type GetAllCommentExchangeLazyQueryHookResult = ReturnType<typeof useGetAllCommentExchangeLazyQuery>;
export type GetAllCommentExchangeQueryResult = Apollo.QueryResult<GetAllCommentExchangeQuery, GetAllCommentExchangeQueryVariables>;
export const CheckEmailExistedCommentExchangeDocument = gql`
    query CheckEmailExistedCommentExchange($where: comment_exchange_bool_exp) {
  comment_exchange_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCheckEmailExistedCommentExchangeQuery__
 *
 * To run a query within a React component, call `useCheckEmailExistedCommentExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailExistedCommentExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailExistedCommentExchangeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCheckEmailExistedCommentExchangeQuery(baseOptions?: Apollo.QueryHookOptions<CheckEmailExistedCommentExchangeQuery, CheckEmailExistedCommentExchangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEmailExistedCommentExchangeQuery, CheckEmailExistedCommentExchangeQueryVariables>(CheckEmailExistedCommentExchangeDocument, options);
      }
export function useCheckEmailExistedCommentExchangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEmailExistedCommentExchangeQuery, CheckEmailExistedCommentExchangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEmailExistedCommentExchangeQuery, CheckEmailExistedCommentExchangeQueryVariables>(CheckEmailExistedCommentExchangeDocument, options);
        }
export type CheckEmailExistedCommentExchangeQueryHookResult = ReturnType<typeof useCheckEmailExistedCommentExchangeQuery>;
export type CheckEmailExistedCommentExchangeLazyQueryHookResult = ReturnType<typeof useCheckEmailExistedCommentExchangeLazyQuery>;
export type CheckEmailExistedCommentExchangeQueryResult = Apollo.QueryResult<CheckEmailExistedCommentExchangeQuery, CheckEmailExistedCommentExchangeQueryVariables>;
export const GetAllInsightsByMagazineIdDocument = gql`
    query getAllInsightsByMagazineId($orderBy: [insight_order_by!], $where: insight_bool_exp) {
  insight(order_by: $orderBy, where: $where) {
    title
    thumbnail
    slug
    published_at
    magazine {
      title
      name_pdf
      table_of_contents
      insight_id
    }
    id
    content
    magazine_id
    is_publish
    description
    insight_order
  }
}
    `;

/**
 * __useGetAllInsightsByMagazineIdQuery__
 *
 * To run a query within a React component, call `useGetAllInsightsByMagazineIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInsightsByMagazineIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInsightsByMagazineIdQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetAllInsightsByMagazineIdQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInsightsByMagazineIdQuery, GetAllInsightsByMagazineIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInsightsByMagazineIdQuery, GetAllInsightsByMagazineIdQueryVariables>(GetAllInsightsByMagazineIdDocument, options);
      }
export function useGetAllInsightsByMagazineIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInsightsByMagazineIdQuery, GetAllInsightsByMagazineIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInsightsByMagazineIdQuery, GetAllInsightsByMagazineIdQueryVariables>(GetAllInsightsByMagazineIdDocument, options);
        }
export type GetAllInsightsByMagazineIdQueryHookResult = ReturnType<typeof useGetAllInsightsByMagazineIdQuery>;
export type GetAllInsightsByMagazineIdLazyQueryHookResult = ReturnType<typeof useGetAllInsightsByMagazineIdLazyQuery>;
export type GetAllInsightsByMagazineIdQueryResult = Apollo.QueryResult<GetAllInsightsByMagazineIdQuery, GetAllInsightsByMagazineIdQueryVariables>;
export const GetDetailInsightByIdDocument = gql`
    query getDetailInsightById($insightByPkId: Int!) {
  insight_by_pk(id: $insightByPkId) {
    content
    description
    id
    is_publish
    magazine {
      id
      title
      slug
    }
    magazine_id
    published_at
    slug
    thumbnail
    title
  }
}
    `;

/**
 * __useGetDetailInsightByIdQuery__
 *
 * To run a query within a React component, call `useGetDetailInsightByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailInsightByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailInsightByIdQuery({
 *   variables: {
 *      insightByPkId: // value for 'insightByPkId'
 *   },
 * });
 */
export function useGetDetailInsightByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDetailInsightByIdQuery, GetDetailInsightByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetailInsightByIdQuery, GetDetailInsightByIdQueryVariables>(GetDetailInsightByIdDocument, options);
      }
export function useGetDetailInsightByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetailInsightByIdQuery, GetDetailInsightByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetailInsightByIdQuery, GetDetailInsightByIdQueryVariables>(GetDetailInsightByIdDocument, options);
        }
export type GetDetailInsightByIdQueryHookResult = ReturnType<typeof useGetDetailInsightByIdQuery>;
export type GetDetailInsightByIdLazyQueryHookResult = ReturnType<typeof useGetDetailInsightByIdLazyQuery>;
export type GetDetailInsightByIdQueryResult = Apollo.QueryResult<GetDetailInsightByIdQuery, GetDetailInsightByIdQueryVariables>;
export const GetOtherInsightsDocument = gql`
    query getOtherInsights($where: insight_bool_exp, $limit: Int, $orderBy: [insight_order_by!]) {
  insight(where: $where, limit: $limit, order_by: $orderBy) {
    insight_order
    content
    description
    id
    is_publish
    magazine_id
    published_at
    slug
    thumbnail
    title
    magazine {
      id
      title
    }
  }
}
    `;

/**
 * __useGetOtherInsightsQuery__
 *
 * To run a query within a React component, call `useGetOtherInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherInsightsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetOtherInsightsQuery(baseOptions?: Apollo.QueryHookOptions<GetOtherInsightsQuery, GetOtherInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOtherInsightsQuery, GetOtherInsightsQueryVariables>(GetOtherInsightsDocument, options);
      }
export function useGetOtherInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherInsightsQuery, GetOtherInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOtherInsightsQuery, GetOtherInsightsQueryVariables>(GetOtherInsightsDocument, options);
        }
export type GetOtherInsightsQueryHookResult = ReturnType<typeof useGetOtherInsightsQuery>;
export type GetOtherInsightsLazyQueryHookResult = ReturnType<typeof useGetOtherInsightsLazyQuery>;
export type GetOtherInsightsQueryResult = Apollo.QueryResult<GetOtherInsightsQuery, GetOtherInsightsQueryVariables>;
export const KnowledgeHomepageDocument = gql`
    query KnowledgeHomepage($limit: Int, $where: course_bool_exp, $orderBy: [course_order_by!]) {
  course(limit: $limit, where: $where, order_by: $orderBy) {
    category {
      name
    }
    id
    thumbnail_url
    name
    lessons_aggregate {
      aggregate {
        count
      }
    }
    description
    updated_at
  }
}
    `;

/**
 * __useKnowledgeHomepageQuery__
 *
 * To run a query within a React component, call `useKnowledgeHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useKnowledgeHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKnowledgeHomepageQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useKnowledgeHomepageQuery(baseOptions?: Apollo.QueryHookOptions<KnowledgeHomepageQuery, KnowledgeHomepageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnowledgeHomepageQuery, KnowledgeHomepageQueryVariables>(KnowledgeHomepageDocument, options);
      }
export function useKnowledgeHomepageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnowledgeHomepageQuery, KnowledgeHomepageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnowledgeHomepageQuery, KnowledgeHomepageQueryVariables>(KnowledgeHomepageDocument, options);
        }
export type KnowledgeHomepageQueryHookResult = ReturnType<typeof useKnowledgeHomepageQuery>;
export type KnowledgeHomepageLazyQueryHookResult = ReturnType<typeof useKnowledgeHomepageLazyQuery>;
export type KnowledgeHomepageQueryResult = Apollo.QueryResult<KnowledgeHomepageQuery, KnowledgeHomepageQueryVariables>;
export const GetAllCategoryKnowledgeDocument = gql`
    query getAllCategoryKnowledge($where: course_category_bool_exp, $orderBy: [course_category_order_by!]) {
  course_category(where: $where, order_by: $orderBy) {
    id
    name
    description
    series_course_categories {
      series_id
      series {
        series_knowledges {
          id
        }
      }
    }
    course_course_categories {
      course {
        name
        lessons {
          id
        }
      }
    }
    courses_aggregate {
      aggregate {
        count
      }
    }
    courses {
      lessons_aggregate {
        aggregate {
          count
        }
      }
    }
    series_order
    playlist_order
  }
}
    `;

/**
 * __useGetAllCategoryKnowledgeQuery__
 *
 * To run a query within a React component, call `useGetAllCategoryKnowledgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoryKnowledgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoryKnowledgeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllCategoryKnowledgeQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCategoryKnowledgeQuery, GetAllCategoryKnowledgeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCategoryKnowledgeQuery, GetAllCategoryKnowledgeQueryVariables>(GetAllCategoryKnowledgeDocument, options);
      }
export function useGetAllCategoryKnowledgeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCategoryKnowledgeQuery, GetAllCategoryKnowledgeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCategoryKnowledgeQuery, GetAllCategoryKnowledgeQueryVariables>(GetAllCategoryKnowledgeDocument, options);
        }
export type GetAllCategoryKnowledgeQueryHookResult = ReturnType<typeof useGetAllCategoryKnowledgeQuery>;
export type GetAllCategoryKnowledgeLazyQueryHookResult = ReturnType<typeof useGetAllCategoryKnowledgeLazyQuery>;
export type GetAllCategoryKnowledgeQueryResult = Apollo.QueryResult<GetAllCategoryKnowledgeQuery, GetAllCategoryKnowledgeQueryVariables>;
export const GetCourseByCategoryDocument = gql`
    query getCourseByCategory($where: course_bool_exp, $limit: Int, $offset: Int, $orderBy: [course_order_by!]) {
  course(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    thumbnail_url
    name
    lessons_aggregate {
      aggregate {
        count
      }
    }
    description
    updated_at
    created_at
    is_feature
    lessons {
      id
      name
    }
    course_course_categories {
      course_category {
        name
        id
      }
    }
    order
  }
}
    `;

/**
 * __useGetCourseByCategoryQuery__
 *
 * To run a query within a React component, call `useGetCourseByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseByCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCourseByCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetCourseByCategoryQuery, GetCourseByCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseByCategoryQuery, GetCourseByCategoryQueryVariables>(GetCourseByCategoryDocument, options);
      }
export function useGetCourseByCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseByCategoryQuery, GetCourseByCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseByCategoryQuery, GetCourseByCategoryQueryVariables>(GetCourseByCategoryDocument, options);
        }
export type GetCourseByCategoryQueryHookResult = ReturnType<typeof useGetCourseByCategoryQuery>;
export type GetCourseByCategoryLazyQueryHookResult = ReturnType<typeof useGetCourseByCategoryLazyQuery>;
export type GetCourseByCategoryQueryResult = Apollo.QueryResult<GetCourseByCategoryQuery, GetCourseByCategoryQueryVariables>;
export const GetKnowledgeActionDocument = gql`
    query getKnowledgeAction($where: knowledge_action_bool_exp) {
  knowledge_action(where: $where) {
    id
    action_type
    browser_id
    knowledge_id
  }
}
    `;

/**
 * __useGetKnowledgeActionQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeActionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetKnowledgeActionQuery(baseOptions?: Apollo.QueryHookOptions<GetKnowledgeActionQuery, GetKnowledgeActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKnowledgeActionQuery, GetKnowledgeActionQueryVariables>(GetKnowledgeActionDocument, options);
      }
export function useGetKnowledgeActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKnowledgeActionQuery, GetKnowledgeActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKnowledgeActionQuery, GetKnowledgeActionQueryVariables>(GetKnowledgeActionDocument, options);
        }
export type GetKnowledgeActionQueryHookResult = ReturnType<typeof useGetKnowledgeActionQuery>;
export type GetKnowledgeActionLazyQueryHookResult = ReturnType<typeof useGetKnowledgeActionLazyQuery>;
export type GetKnowledgeActionQueryResult = Apollo.QueryResult<GetKnowledgeActionQuery, GetKnowledgeActionQueryVariables>;
export const GetAllMagazineDocument = gql`
    query getAllMagazine($limit: Int, $orderBy: [magazine_order_by!], $offset: Int) {
  magazine(limit: $limit, order_by: $orderBy, offset: $offset) {
    title
    slug
    description
    thumbnail
    id
    table_of_contents
    name_pdf
    created_at
    public_at
    index
  }
  magazine_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllMagazineQuery__
 *
 * To run a query within a React component, call `useGetAllMagazineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMagazineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMagazineQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllMagazineQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMagazineQuery, GetAllMagazineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMagazineQuery, GetAllMagazineQueryVariables>(GetAllMagazineDocument, options);
      }
export function useGetAllMagazineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMagazineQuery, GetAllMagazineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMagazineQuery, GetAllMagazineQueryVariables>(GetAllMagazineDocument, options);
        }
export type GetAllMagazineQueryHookResult = ReturnType<typeof useGetAllMagazineQuery>;
export type GetAllMagazineLazyQueryHookResult = ReturnType<typeof useGetAllMagazineLazyQuery>;
export type GetAllMagazineQueryResult = Apollo.QueryResult<GetAllMagazineQuery, GetAllMagazineQueryVariables>;
export const GetAllMagazineLastestDocument = gql`
    query getAllMagazineLastest($limit: Int, $orderBy: [magazine_order_by!], $offset: Int) {
  magazine(limit: $limit, order_by: $orderBy, offset: $offset) {
    title
    slug
    thumbnail
    id
    created_at
    public_at
    index
  }
  magazine_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllMagazineLastestQuery__
 *
 * To run a query within a React component, call `useGetAllMagazineLastestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMagazineLastestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMagazineLastestQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAllMagazineLastestQuery(baseOptions?: Apollo.QueryHookOptions<GetAllMagazineLastestQuery, GetAllMagazineLastestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMagazineLastestQuery, GetAllMagazineLastestQueryVariables>(GetAllMagazineLastestDocument, options);
      }
export function useGetAllMagazineLastestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMagazineLastestQuery, GetAllMagazineLastestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMagazineLastestQuery, GetAllMagazineLastestQueryVariables>(GetAllMagazineLastestDocument, options);
        }
export type GetAllMagazineLastestQueryHookResult = ReturnType<typeof useGetAllMagazineLastestQuery>;
export type GetAllMagazineLastestLazyQueryHookResult = ReturnType<typeof useGetAllMagazineLastestLazyQuery>;
export type GetAllMagazineLastestQueryResult = Apollo.QueryResult<GetAllMagazineLastestQuery, GetAllMagazineLastestQueryVariables>;
export const GetMagazineBySlugDocument = gql`
    query getMagazineBySlug($where: magazine_bool_exp) {
  magazine(where: $where) {
    thumbnail
    title
    slug
    table_of_contents
    name_pdf
    description
    created_at
    id
    public_at
  }
}
    `;

/**
 * __useGetMagazineBySlugQuery__
 *
 * To run a query within a React component, call `useGetMagazineBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMagazineBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMagazineBySlugQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetMagazineBySlugQuery(baseOptions?: Apollo.QueryHookOptions<GetMagazineBySlugQuery, GetMagazineBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMagazineBySlugQuery, GetMagazineBySlugQueryVariables>(GetMagazineBySlugDocument, options);
      }
export function useGetMagazineBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMagazineBySlugQuery, GetMagazineBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMagazineBySlugQuery, GetMagazineBySlugQueryVariables>(GetMagazineBySlugDocument, options);
        }
export type GetMagazineBySlugQueryHookResult = ReturnType<typeof useGetMagazineBySlugQuery>;
export type GetMagazineBySlugLazyQueryHookResult = ReturnType<typeof useGetMagazineBySlugLazyQuery>;
export type GetMagazineBySlugQueryResult = Apollo.QueryResult<GetMagazineBySlugQuery, GetMagazineBySlugQueryVariables>;
export const GetMagazineCommentsByIdDocument = gql`
    query getMagazineCommentsById($where: magazine_comment_web_bool_exp, $orderBy: [magazine_comment_web_order_by!]) {
  magazine_comment_web(where: $where, order_by: $orderBy) {
    user_email
    user_name
    content
    created_at
  }
}
    `;

/**
 * __useGetMagazineCommentsByIdQuery__
 *
 * To run a query within a React component, call `useGetMagazineCommentsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMagazineCommentsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMagazineCommentsByIdQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetMagazineCommentsByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetMagazineCommentsByIdQuery, GetMagazineCommentsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMagazineCommentsByIdQuery, GetMagazineCommentsByIdQueryVariables>(GetMagazineCommentsByIdDocument, options);
      }
export function useGetMagazineCommentsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMagazineCommentsByIdQuery, GetMagazineCommentsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMagazineCommentsByIdQuery, GetMagazineCommentsByIdQueryVariables>(GetMagazineCommentsByIdDocument, options);
        }
export type GetMagazineCommentsByIdQueryHookResult = ReturnType<typeof useGetMagazineCommentsByIdQuery>;
export type GetMagazineCommentsByIdLazyQueryHookResult = ReturnType<typeof useGetMagazineCommentsByIdLazyQuery>;
export type GetMagazineCommentsByIdQueryResult = Apollo.QueryResult<GetMagazineCommentsByIdQuery, GetMagazineCommentsByIdQueryVariables>;
export const NewsHomepageDocument = gql`
    query NewsHomepage($limit: Int, $offset: Int, $orderBy: [news_order_by!], $where: news_bool_exp) {
  news(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
    title
    thumbnail
    slug
    news_category {
      name
      slug
      id
    }
    meta_description
    content
    date
    update_at
    id
    category_id
    create_at
  }
  news_aggregate(where: $where) {
    aggregate {
      count
    }
  }
  news_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useNewsHomepageQuery__
 *
 * To run a query within a React component, call `useNewsHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsHomepageQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNewsHomepageQuery(baseOptions?: Apollo.QueryHookOptions<NewsHomepageQuery, NewsHomepageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsHomepageQuery, NewsHomepageQueryVariables>(NewsHomepageDocument, options);
      }
export function useNewsHomepageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsHomepageQuery, NewsHomepageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsHomepageQuery, NewsHomepageQueryVariables>(NewsHomepageDocument, options);
        }
export type NewsHomepageQueryHookResult = ReturnType<typeof useNewsHomepageQuery>;
export type NewsHomepageLazyQueryHookResult = ReturnType<typeof useNewsHomepageLazyQuery>;
export type NewsHomepageQueryResult = Apollo.QueryResult<NewsHomepageQuery, NewsHomepageQueryVariables>;
export const GetNewsCategoriesDocument = gql`
    query getNewsCategories($where: news_category_bool_exp, $order_by: [news_category_order_by!]) {
  news_category(where: $where, order_by: $order_by) {
    id
    name
    slug
  }
}
    `;

/**
 * __useGetNewsCategoriesQuery__
 *
 * To run a query within a React component, call `useGetNewsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *   },
 * });
 */
export function useGetNewsCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsCategoriesQuery, GetNewsCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsCategoriesQuery, GetNewsCategoriesQueryVariables>(GetNewsCategoriesDocument, options);
      }
export function useGetNewsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsCategoriesQuery, GetNewsCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsCategoriesQuery, GetNewsCategoriesQueryVariables>(GetNewsCategoriesDocument, options);
        }
export type GetNewsCategoriesQueryHookResult = ReturnType<typeof useGetNewsCategoriesQuery>;
export type GetNewsCategoriesLazyQueryHookResult = ReturnType<typeof useGetNewsCategoriesLazyQuery>;
export type GetNewsCategoriesQueryResult = Apollo.QueryResult<GetNewsCategoriesQuery, GetNewsCategoriesQueryVariables>;
export const GetNewsCategoriesBySlugDocument = gql`
    query getNewsCategoriesBySlug($where: news_category_bool_exp) {
  news_category(where: $where) {
    name
    slug
    id
    updated_at
    updated_by
    meta_description
  }
}
    `;

/**
 * __useGetNewsCategoriesBySlugQuery__
 *
 * To run a query within a React component, call `useGetNewsCategoriesBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsCategoriesBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsCategoriesBySlugQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNewsCategoriesBySlugQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsCategoriesBySlugQuery, GetNewsCategoriesBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsCategoriesBySlugQuery, GetNewsCategoriesBySlugQueryVariables>(GetNewsCategoriesBySlugDocument, options);
      }
export function useGetNewsCategoriesBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsCategoriesBySlugQuery, GetNewsCategoriesBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsCategoriesBySlugQuery, GetNewsCategoriesBySlugQueryVariables>(GetNewsCategoriesBySlugDocument, options);
        }
export type GetNewsCategoriesBySlugQueryHookResult = ReturnType<typeof useGetNewsCategoriesBySlugQuery>;
export type GetNewsCategoriesBySlugLazyQueryHookResult = ReturnType<typeof useGetNewsCategoriesBySlugLazyQuery>;
export type GetNewsCategoriesBySlugQueryResult = Apollo.QueryResult<GetNewsCategoriesBySlugQuery, GetNewsCategoriesBySlugQueryVariables>;
export const GetNewsByCategoriesIdDocument = gql`
    query getNewsByCategoriesId($newsCategoryByPkId: Int!, $limit: Int, $orderBy: [news_order_by!], $offset: Int, $where: news_bool_exp) {
  news_category_by_pk(id: $newsCategoryByPkId) {
    news(limit: $limit, order_by: $orderBy, offset: $offset, where: $where) {
      thumbnail
      title
      slug
      meta_description
      news_category {
        name
        slug
        id
      }
      update_at
      date
      id
    }
    name
    id
  }
}
    `;

/**
 * __useGetNewsByCategoriesIdQuery__
 *
 * To run a query within a React component, call `useGetNewsByCategoriesIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsByCategoriesIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsByCategoriesIdQuery({
 *   variables: {
 *      newsCategoryByPkId: // value for 'newsCategoryByPkId'
 *      limit: // value for 'limit'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNewsByCategoriesIdQuery(baseOptions: Apollo.QueryHookOptions<GetNewsByCategoriesIdQuery, GetNewsByCategoriesIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsByCategoriesIdQuery, GetNewsByCategoriesIdQueryVariables>(GetNewsByCategoriesIdDocument, options);
      }
export function useGetNewsByCategoriesIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsByCategoriesIdQuery, GetNewsByCategoriesIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsByCategoriesIdQuery, GetNewsByCategoriesIdQueryVariables>(GetNewsByCategoriesIdDocument, options);
        }
export type GetNewsByCategoriesIdQueryHookResult = ReturnType<typeof useGetNewsByCategoriesIdQuery>;
export type GetNewsByCategoriesIdLazyQueryHookResult = ReturnType<typeof useGetNewsByCategoriesIdLazyQuery>;
export type GetNewsByCategoriesIdQueryResult = Apollo.QueryResult<GetNewsByCategoriesIdQuery, GetNewsByCategoriesIdQueryVariables>;
export const GetNewsByIdDocument = gql`
    query getNewsById($newsByPkId: Int!) {
  news_by_pk(id: $newsByPkId) {
    id
    thumbnail
    slug
    title
    updated_by
    views
    news_category {
      name
      id
    }
    date
    create_at
    category_id
    content
    meta_description
    fake_views
    canonical
    noindex
  }
}
    `;

/**
 * __useGetNewsByIdQuery__
 *
 * To run a query within a React component, call `useGetNewsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsByIdQuery({
 *   variables: {
 *      newsByPkId: // value for 'newsByPkId'
 *   },
 * });
 */
export function useGetNewsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetNewsByIdQuery, GetNewsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsByIdQuery, GetNewsByIdQueryVariables>(GetNewsByIdDocument, options);
      }
export function useGetNewsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsByIdQuery, GetNewsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsByIdQuery, GetNewsByIdQueryVariables>(GetNewsByIdDocument, options);
        }
export type GetNewsByIdQueryHookResult = ReturnType<typeof useGetNewsByIdQuery>;
export type GetNewsByIdLazyQueryHookResult = ReturnType<typeof useGetNewsByIdLazyQuery>;
export type GetNewsByIdQueryResult = Apollo.QueryResult<GetNewsByIdQuery, GetNewsByIdQueryVariables>;
export const GetNewsByCategorySlugDocument = gql`
    query getNewsByCategorySlug($where: news_bool_exp, $orderBy: [news_order_by!], $limit: Int, $offset: Int) {
  news(where: $where, order_by: $orderBy, limit: $limit, offset: $offset) {
    category_id
    id
    slug
    title
    meta_description
    thumbnail
    news_category {
      name
      id
    }
    create_at
    date
  }
  news_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetNewsByCategorySlugQuery__
 *
 * To run a query within a React component, call `useGetNewsByCategorySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsByCategorySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsByCategorySlugQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNewsByCategorySlugQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsByCategorySlugQuery, GetNewsByCategorySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsByCategorySlugQuery, GetNewsByCategorySlugQueryVariables>(GetNewsByCategorySlugDocument, options);
      }
export function useGetNewsByCategorySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsByCategorySlugQuery, GetNewsByCategorySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsByCategorySlugQuery, GetNewsByCategorySlugQueryVariables>(GetNewsByCategorySlugDocument, options);
        }
export type GetNewsByCategorySlugQueryHookResult = ReturnType<typeof useGetNewsByCategorySlugQuery>;
export type GetNewsByCategorySlugLazyQueryHookResult = ReturnType<typeof useGetNewsByCategorySlugLazyQuery>;
export type GetNewsByCategorySlugQueryResult = Apollo.QueryResult<GetNewsByCategorySlugQuery, GetNewsByCategorySlugQueryVariables>;
export const GetNewsCategoryHomePageDocument = gql`
    query getNewsCategoryHomePage($limitNews: Int, $offsetNews: Int, $orderByNews: [news_order_by!], $whereNews: news_bool_exp, $orderByNewsCategory: [news_category_order_by!]) {
  news_category(order_by: $orderByNewsCategory) {
    id
    name
    slug
    news(
      limit: $limitNews
      offset: $offsetNews
      order_by: $orderByNews
      where: $whereNews
    ) {
      id
      title
      thumbnail
      link
      slug
    }
  }
}
    `;

/**
 * __useGetNewsCategoryHomePageQuery__
 *
 * To run a query within a React component, call `useGetNewsCategoryHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsCategoryHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsCategoryHomePageQuery({
 *   variables: {
 *      limitNews: // value for 'limitNews'
 *      offsetNews: // value for 'offsetNews'
 *      orderByNews: // value for 'orderByNews'
 *      whereNews: // value for 'whereNews'
 *      orderByNewsCategory: // value for 'orderByNewsCategory'
 *   },
 * });
 */
export function useGetNewsCategoryHomePageQuery(baseOptions?: Apollo.QueryHookOptions<GetNewsCategoryHomePageQuery, GetNewsCategoryHomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsCategoryHomePageQuery, GetNewsCategoryHomePageQueryVariables>(GetNewsCategoryHomePageDocument, options);
      }
export function useGetNewsCategoryHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsCategoryHomePageQuery, GetNewsCategoryHomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsCategoryHomePageQuery, GetNewsCategoryHomePageQueryVariables>(GetNewsCategoryHomePageDocument, options);
        }
export type GetNewsCategoryHomePageQueryHookResult = ReturnType<typeof useGetNewsCategoryHomePageQuery>;
export type GetNewsCategoryHomePageLazyQueryHookResult = ReturnType<typeof useGetNewsCategoryHomePageLazyQuery>;
export type GetNewsCategoryHomePageQueryResult = Apollo.QueryResult<GetNewsCategoryHomePageQuery, GetNewsCategoryHomePageQueryVariables>;
export const DetailPlaylistDocument = gql`
    query DetailPlaylist($courseByPkId: Int!) {
  course_by_pk(id: $courseByPkId) {
    name
    lessons_aggregate {
      aggregate {
        count
      }
    }
    thumbnail_url
    id
    description
    category {
      name
    }
    updated_at
    avg_rating
    lessons(order_by: {index: asc_nulls_last}) {
      name
      id
      thumbnail_url
      duration
      fake_views
      views
      index
    }
    content
  }
}
    `;

/**
 * __useDetailPlaylistQuery__
 *
 * To run a query within a React component, call `useDetailPlaylistQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailPlaylistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailPlaylistQuery({
 *   variables: {
 *      courseByPkId: // value for 'courseByPkId'
 *   },
 * });
 */
export function useDetailPlaylistQuery(baseOptions: Apollo.QueryHookOptions<DetailPlaylistQuery, DetailPlaylistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailPlaylistQuery, DetailPlaylistQueryVariables>(DetailPlaylistDocument, options);
      }
export function useDetailPlaylistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailPlaylistQuery, DetailPlaylistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailPlaylistQuery, DetailPlaylistQueryVariables>(DetailPlaylistDocument, options);
        }
export type DetailPlaylistQueryHookResult = ReturnType<typeof useDetailPlaylistQuery>;
export type DetailPlaylistLazyQueryHookResult = ReturnType<typeof useDetailPlaylistLazyQuery>;
export type DetailPlaylistQueryResult = Apollo.QueryResult<DetailPlaylistQuery, DetailPlaylistQueryVariables>;
export const GetLessonByCourseIdDocument = gql`
    query getLessonByCourseId($courseByPkId: Int!) {
  course_by_pk(id: $courseByPkId) {
    lessons {
      views
      fake_views
      id
    }
  }
}
    `;

/**
 * __useGetLessonByCourseIdQuery__
 *
 * To run a query within a React component, call `useGetLessonByCourseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonByCourseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonByCourseIdQuery({
 *   variables: {
 *      courseByPkId: // value for 'courseByPkId'
 *   },
 * });
 */
export function useGetLessonByCourseIdQuery(baseOptions: Apollo.QueryHookOptions<GetLessonByCourseIdQuery, GetLessonByCourseIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonByCourseIdQuery, GetLessonByCourseIdQueryVariables>(GetLessonByCourseIdDocument, options);
      }
export function useGetLessonByCourseIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonByCourseIdQuery, GetLessonByCourseIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonByCourseIdQuery, GetLessonByCourseIdQueryVariables>(GetLessonByCourseIdDocument, options);
        }
export type GetLessonByCourseIdQueryHookResult = ReturnType<typeof useGetLessonByCourseIdQuery>;
export type GetLessonByCourseIdLazyQueryHookResult = ReturnType<typeof useGetLessonByCourseIdLazyQuery>;
export type GetLessonByCourseIdQueryResult = Apollo.QueryResult<GetLessonByCourseIdQuery, GetLessonByCourseIdQueryVariables>;
export const CheckIsEmailRatingCourseDocument = gql`
    query checkIsEmailRatingCourse($email: String!, $courseId: Int!) {
  course_rating_by_pk(course_id: $courseId, user_email: $email) {
    id
  }
}
    `;

/**
 * __useCheckIsEmailRatingCourseQuery__
 *
 * To run a query within a React component, call `useCheckIsEmailRatingCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsEmailRatingCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsEmailRatingCourseQuery({
 *   variables: {
 *      email: // value for 'email'
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCheckIsEmailRatingCourseQuery(baseOptions: Apollo.QueryHookOptions<CheckIsEmailRatingCourseQuery, CheckIsEmailRatingCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckIsEmailRatingCourseQuery, CheckIsEmailRatingCourseQueryVariables>(CheckIsEmailRatingCourseDocument, options);
      }
export function useCheckIsEmailRatingCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIsEmailRatingCourseQuery, CheckIsEmailRatingCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckIsEmailRatingCourseQuery, CheckIsEmailRatingCourseQueryVariables>(CheckIsEmailRatingCourseDocument, options);
        }
export type CheckIsEmailRatingCourseQueryHookResult = ReturnType<typeof useCheckIsEmailRatingCourseQuery>;
export type CheckIsEmailRatingCourseLazyQueryHookResult = ReturnType<typeof useCheckIsEmailRatingCourseLazyQuery>;
export type CheckIsEmailRatingCourseQueryResult = Apollo.QueryResult<CheckIsEmailRatingCourseQuery, CheckIsEmailRatingCourseQueryVariables>;
export const GetCourseRatingsDocument = gql`
    query getCourseRatings($courseId: Int!, $limit: Int!, $offset: Int!) {
  course_rating(
    where: {course_id: {_eq: $courseId}}
    order_by: {created_at: desc_nulls_last}
    limit: $limit
    offset: $offset
  ) {
    user_name
    user_email
    created_at
    rating_description
    rating_title
    score
    updated_at
  }
}
    `;

/**
 * __useGetCourseRatingsQuery__
 *
 * To run a query within a React component, call `useGetCourseRatingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseRatingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseRatingsQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCourseRatingsQuery(baseOptions: Apollo.QueryHookOptions<GetCourseRatingsQuery, GetCourseRatingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseRatingsQuery, GetCourseRatingsQueryVariables>(GetCourseRatingsDocument, options);
      }
export function useGetCourseRatingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseRatingsQuery, GetCourseRatingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseRatingsQuery, GetCourseRatingsQueryVariables>(GetCourseRatingsDocument, options);
        }
export type GetCourseRatingsQueryHookResult = ReturnType<typeof useGetCourseRatingsQuery>;
export type GetCourseRatingsLazyQueryHookResult = ReturnType<typeof useGetCourseRatingsLazyQuery>;
export type GetCourseRatingsQueryResult = Apollo.QueryResult<GetCourseRatingsQuery, GetCourseRatingsQueryVariables>;
export const GetTotalRatingOfCourseDocument = gql`
    query getTotalRatingOfCourse($courseId: Int!) {
  course_rating_aggregate(where: {course_id: {_eq: $courseId}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTotalRatingOfCourseQuery__
 *
 * To run a query within a React component, call `useGetTotalRatingOfCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalRatingOfCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalRatingOfCourseQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useGetTotalRatingOfCourseQuery(baseOptions: Apollo.QueryHookOptions<GetTotalRatingOfCourseQuery, GetTotalRatingOfCourseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalRatingOfCourseQuery, GetTotalRatingOfCourseQueryVariables>(GetTotalRatingOfCourseDocument, options);
      }
export function useGetTotalRatingOfCourseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalRatingOfCourseQuery, GetTotalRatingOfCourseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalRatingOfCourseQuery, GetTotalRatingOfCourseQueryVariables>(GetTotalRatingOfCourseDocument, options);
        }
export type GetTotalRatingOfCourseQueryHookResult = ReturnType<typeof useGetTotalRatingOfCourseQuery>;
export type GetTotalRatingOfCourseLazyQueryHookResult = ReturnType<typeof useGetTotalRatingOfCourseLazyQuery>;
export type GetTotalRatingOfCourseQueryResult = Apollo.QueryResult<GetTotalRatingOfCourseQuery, GetTotalRatingOfCourseQueryVariables>;
export const GetAllRedirectDocument = gql`
    query getAllRedirect($orderBy: [redirect_order_by!]) {
  redirect(order_by: $orderBy) {
    new_url
    old_url
  }
}
    `;

/**
 * __useGetAllRedirectQuery__
 *
 * To run a query within a React component, call `useGetAllRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRedirectQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAllRedirectQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRedirectQuery, GetAllRedirectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRedirectQuery, GetAllRedirectQueryVariables>(GetAllRedirectDocument, options);
      }
export function useGetAllRedirectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRedirectQuery, GetAllRedirectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRedirectQuery, GetAllRedirectQueryVariables>(GetAllRedirectDocument, options);
        }
export type GetAllRedirectQueryHookResult = ReturnType<typeof useGetAllRedirectQuery>;
export type GetAllRedirectLazyQueryHookResult = ReturnType<typeof useGetAllRedirectLazyQuery>;
export type GetAllRedirectQueryResult = Apollo.QueryResult<GetAllRedirectQuery, GetAllRedirectQueryVariables>;
export const GetSeriesHomeDocument = gql`
    query getSeriesHome($where: series_bool_exp, $limit: Int, $offset: Int, $orderBy: [series_order_by!]) {
  series(where: $where, limit: $limit, offset: $offset, order_by: $orderBy) {
    id
    title
    slug
    thumbnail
    series_knowledges(where: {knowledge: {is_publish: {_eq: true}}}) {
      knowledge {
        id
        content
        description
        slug
        thumbnail
        title
      }
    }
    series_course_categories {
      courseCategoryByCourseCategory {
        name
      }
    }
    created_at
    order
  }
}
    `;

/**
 * __useGetSeriesHomeQuery__
 *
 * To run a query within a React component, call `useGetSeriesHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesHomeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetSeriesHomeQuery(baseOptions?: Apollo.QueryHookOptions<GetSeriesHomeQuery, GetSeriesHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeriesHomeQuery, GetSeriesHomeQueryVariables>(GetSeriesHomeDocument, options);
      }
export function useGetSeriesHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeriesHomeQuery, GetSeriesHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeriesHomeQuery, GetSeriesHomeQueryVariables>(GetSeriesHomeDocument, options);
        }
export type GetSeriesHomeQueryHookResult = ReturnType<typeof useGetSeriesHomeQuery>;
export type GetSeriesHomeLazyQueryHookResult = ReturnType<typeof useGetSeriesHomeLazyQuery>;
export type GetSeriesHomeQueryResult = Apollo.QueryResult<GetSeriesHomeQuery, GetSeriesHomeQueryVariables>;
export const GetSeriesDocument = gql`
    query getSeries($where: series_bool_exp, $limit: Int, $offset: Int) {
  series(where: $where, limit: $limit, offset: $offset) {
    id
    title
    slug
    thumbnail
    series_knowledges(where: {knowledge: {is_publish: {_eq: true}}}) {
      knowledge {
        id
        content
        description
        slug
        thumbnail
        title
      }
    }
    series_course_categories {
      courseCategoryByCourseCategory {
        name
      }
    }
    seo_description
    seo_title
  }
}
    `;

/**
 * __useGetSeriesQuery__
 *
 * To run a query within a React component, call `useGetSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSeriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSeriesQuery, GetSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeriesQuery, GetSeriesQueryVariables>(GetSeriesDocument, options);
      }
export function useGetSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeriesQuery, GetSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeriesQuery, GetSeriesQueryVariables>(GetSeriesDocument, options);
        }
export type GetSeriesQueryHookResult = ReturnType<typeof useGetSeriesQuery>;
export type GetSeriesLazyQueryHookResult = ReturnType<typeof useGetSeriesLazyQuery>;
export type GetSeriesQueryResult = Apollo.QueryResult<GetSeriesQuery, GetSeriesQueryVariables>;
export const GetKnowledgeDocument = gql`
    query getKnowledge($where: knowledge_bool_exp, $limit: Int, $offset: Int) {
  knowledge(where: $where, limit: $limit, offset: $offset) {
    id
    title
    content
    description
    slug
    thumbnail
    published_at
    series_knowledges {
      id
      series {
        slug
      }
    }
  }
}
    `;

/**
 * __useGetKnowledgeQuery__
 *
 * To run a query within a React component, call `useGetKnowledgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetKnowledgeQuery(baseOptions?: Apollo.QueryHookOptions<GetKnowledgeQuery, GetKnowledgeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetKnowledgeQuery, GetKnowledgeQueryVariables>(GetKnowledgeDocument, options);
      }
export function useGetKnowledgeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetKnowledgeQuery, GetKnowledgeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetKnowledgeQuery, GetKnowledgeQueryVariables>(GetKnowledgeDocument, options);
        }
export type GetKnowledgeQueryHookResult = ReturnType<typeof useGetKnowledgeQuery>;
export type GetKnowledgeLazyQueryHookResult = ReturnType<typeof useGetKnowledgeLazyQuery>;
export type GetKnowledgeQueryResult = Apollo.QueryResult<GetKnowledgeQuery, GetKnowledgeQueryVariables>;
export const DetailVideoDocument = gql`
    query DetailVideo($lessonByPkId: Int!) {
  lesson_by_pk(id: $lessonByPkId) {
    name
    fake_views
    description
    thumbnail_url
    course {
      lessons(order_by: {index: asc_nulls_last}) {
        name
        thumbnail_url
        id
        duration
        index
      }
      lessons_aggregate {
        aggregate {
          count
        }
      }
      id
      name
    }
    video_url
    duration
    id
    views
    content
  }
}
    `;

/**
 * __useDetailVideoQuery__
 *
 * To run a query within a React component, call `useDetailVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailVideoQuery({
 *   variables: {
 *      lessonByPkId: // value for 'lessonByPkId'
 *   },
 * });
 */
export function useDetailVideoQuery(baseOptions: Apollo.QueryHookOptions<DetailVideoQuery, DetailVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailVideoQuery, DetailVideoQueryVariables>(DetailVideoDocument, options);
      }
export function useDetailVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailVideoQuery, DetailVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailVideoQuery, DetailVideoQueryVariables>(DetailVideoDocument, options);
        }
export type DetailVideoQueryHookResult = ReturnType<typeof useDetailVideoQuery>;
export type DetailVideoLazyQueryHookResult = ReturnType<typeof useDetailVideoLazyQuery>;
export type DetailVideoQueryResult = Apollo.QueryResult<DetailVideoQuery, DetailVideoQueryVariables>;
export const GetCommentByIdDocument = gql`
    query getCommentById($lessonId: Int!, $limit: Int!, $offset: Int!) {
  lesson_comment_web(
    where: {lesson_id: {_eq: $lessonId}}
    order_by: {created_at: desc_nulls_last}
    limit: $limit
    offset: $offset
  ) {
    content
    created_at
    id
    lesson_id
    updated_at
    user_name
    user_email
  }
}
    `;

/**
 * __useGetCommentByIdQuery__
 *
 * To run a query within a React component, call `useGetCommentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentByIdQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetCommentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCommentByIdQuery, GetCommentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentByIdQuery, GetCommentByIdQueryVariables>(GetCommentByIdDocument, options);
      }
export function useGetCommentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentByIdQuery, GetCommentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentByIdQuery, GetCommentByIdQueryVariables>(GetCommentByIdDocument, options);
        }
export type GetCommentByIdQueryHookResult = ReturnType<typeof useGetCommentByIdQuery>;
export type GetCommentByIdLazyQueryHookResult = ReturnType<typeof useGetCommentByIdLazyQuery>;
export type GetCommentByIdQueryResult = Apollo.QueryResult<GetCommentByIdQuery, GetCommentByIdQueryVariables>;
export const GetTotalCommentOfLessonDocument = gql`
    query getTotalCommentOfLesson($lessonId: Int!) {
  lesson_comment_web_aggregate(where: {lesson_id: {_eq: $lessonId}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetTotalCommentOfLessonQuery__
 *
 * To run a query within a React component, call `useGetTotalCommentOfLessonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalCommentOfLessonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalCommentOfLessonQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *   },
 * });
 */
export function useGetTotalCommentOfLessonQuery(baseOptions: Apollo.QueryHookOptions<GetTotalCommentOfLessonQuery, GetTotalCommentOfLessonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalCommentOfLessonQuery, GetTotalCommentOfLessonQueryVariables>(GetTotalCommentOfLessonDocument, options);
      }
export function useGetTotalCommentOfLessonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalCommentOfLessonQuery, GetTotalCommentOfLessonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalCommentOfLessonQuery, GetTotalCommentOfLessonQueryVariables>(GetTotalCommentOfLessonDocument, options);
        }
export type GetTotalCommentOfLessonQueryHookResult = ReturnType<typeof useGetTotalCommentOfLessonQuery>;
export type GetTotalCommentOfLessonLazyQueryHookResult = ReturnType<typeof useGetTotalCommentOfLessonLazyQuery>;
export type GetTotalCommentOfLessonQueryResult = Apollo.QueryResult<GetTotalCommentOfLessonQuery, GetTotalCommentOfLessonQueryVariables>;
export const GetLessonInteractionDocument = gql`
    query getLessonInteraction($lessonId: Int!, $browserId: String!) {
  lesson_by_pk(id: $lessonId) {
    id
    dislike_amount
    like_amount
    like_counts(where: {lesson_id: {_eq: $lessonId}, browser_id: {_eq: $browserId}}) {
      id
      is_like
      is_dislike
    }
  }
}
    `;

/**
 * __useGetLessonInteractionQuery__
 *
 * To run a query within a React component, call `useGetLessonInteractionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonInteractionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonInteractionQuery({
 *   variables: {
 *      lessonId: // value for 'lessonId'
 *      browserId: // value for 'browserId'
 *   },
 * });
 */
export function useGetLessonInteractionQuery(baseOptions: Apollo.QueryHookOptions<GetLessonInteractionQuery, GetLessonInteractionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonInteractionQuery, GetLessonInteractionQueryVariables>(GetLessonInteractionDocument, options);
      }
export function useGetLessonInteractionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonInteractionQuery, GetLessonInteractionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonInteractionQuery, GetLessonInteractionQueryVariables>(GetLessonInteractionDocument, options);
        }
export type GetLessonInteractionQueryHookResult = ReturnType<typeof useGetLessonInteractionQuery>;
export type GetLessonInteractionLazyQueryHookResult = ReturnType<typeof useGetLessonInteractionLazyQuery>;
export type GetLessonInteractionQueryResult = Apollo.QueryResult<GetLessonInteractionQuery, GetLessonInteractionQueryVariables>;
export const GetVideoWatchedByBrowserDocument = gql`
    query getVideoWatchedByBrowser($videoId: Int!, $browserId: String) {
  video_watched(
    where: {lesson_id: {_eq: $videoId}, _and: {browser_id: {_eq: $browserId}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetVideoWatchedByBrowserQuery__
 *
 * To run a query within a React component, call `useGetVideoWatchedByBrowserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoWatchedByBrowserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoWatchedByBrowserQuery({
 *   variables: {
 *      videoId: // value for 'videoId'
 *      browserId: // value for 'browserId'
 *   },
 * });
 */
export function useGetVideoWatchedByBrowserQuery(baseOptions: Apollo.QueryHookOptions<GetVideoWatchedByBrowserQuery, GetVideoWatchedByBrowserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoWatchedByBrowserQuery, GetVideoWatchedByBrowserQueryVariables>(GetVideoWatchedByBrowserDocument, options);
      }
export function useGetVideoWatchedByBrowserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoWatchedByBrowserQuery, GetVideoWatchedByBrowserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoWatchedByBrowserQuery, GetVideoWatchedByBrowserQueryVariables>(GetVideoWatchedByBrowserDocument, options);
        }
export type GetVideoWatchedByBrowserQueryHookResult = ReturnType<typeof useGetVideoWatchedByBrowserQuery>;
export type GetVideoWatchedByBrowserLazyQueryHookResult = ReturnType<typeof useGetVideoWatchedByBrowserLazyQuery>;
export type GetVideoWatchedByBrowserQueryResult = Apollo.QueryResult<GetVideoWatchedByBrowserQuery, GetVideoWatchedByBrowserQueryVariables>;